import React, { useState } from 'react';
import { Calendar } from 'lucide-react';

interface CustomDatePickerProps {
  selectedDate: Date | null;
  onChange: (date: Date) => void;
  allowPastDates?: boolean;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({ 
  selectedDate, 
  onChange,
  allowPastDates = false
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());

  const daysInMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0).getDate();
  const firstDayOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1).getDay();
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const isDateDisabled = (date: Date): boolean => {
    if (allowPastDates) return false;
    return date < today;
  };

  const handleDateClick = (day: number) => {
    const newDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day);
    if (!isDateDisabled(newDate)) {
      onChange(newDate);
      setIsOpen(false);
    }
  };

  const handlePrevMonth = () => {
    const newMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1);
    if (allowPastDates || newMonth >= today) {
      setCurrentMonth(newMonth);
    }
  };

  const handleNextMonth = () => {
    setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1));
  };

  const isCurrentDate = (date: Date): boolean => {
    return date.getDate() === today.getDate() &&
           date.getMonth() === today.getMonth() &&
           date.getFullYear() === today.getFullYear();
  };

  const isPrevMonthDisabled = (): boolean => {
    if (allowPastDates) return false;
    const prevMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1);
    return prevMonth < new Date(today.getFullYear(), today.getMonth(), 1);
  };

  return (
    <div className="relative">
      <button
        type="button"
        className="flex items-center px-4 py-2 border rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(!isOpen);
        }}
      >
        <Calendar className="mr-2 h-5 w-5 text-gray-400" />
        {selectedDate ? selectedDate.toLocaleDateString() : 'Select date'}
      </button>
      {isOpen && (
        <div className="absolute z-10 mt-1 w-64 bg-white shadow-lg rounded-md overflow-hidden">
          <div className="flex justify-between items-center p-2 bg-gray-100">
            <button 
              type="button" 
              onClick={handlePrevMonth}
              disabled={isPrevMonthDisabled()}
              className={`px-2 py-1 rounded ${isPrevMonthDisabled() ? 'text-gray-400 cursor-not-allowed' : 'hover:bg-gray-200'}`}
            >
              &lt;
            </button>
            <span>{currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}</span>
            <button 
              type="button" 
              onClick={handleNextMonth}
              className="px-2 py-1 rounded hover:bg-gray-200"
            >
              &gt;
            </button>
          </div>
          <div className="grid grid-cols-7 gap-1 p-2">
            {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map((day) => (
              <div key={day} className="text-center text-sm font-medium text-gray-500">
                {day}
              </div>
            ))}
            {Array.from({ length: firstDayOfMonth }).map((_, index) => (
              <div key={`empty-${index}`} />
            ))}
            {Array.from({ length: daysInMonth }).map((_, index) => {
              const day = index + 1;
              const date = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day);
              const isSelected = selectedDate?.getDate() === day && 
                               selectedDate?.getMonth() === currentMonth.getMonth() && 
                               selectedDate?.getFullYear() === currentMonth.getFullYear();
              const isCurrent = isCurrentDate(date);
              const isDisabled = isDateDisabled(date);

              return (
                <button
                  key={day}
                  onClick={() => handleDateClick(day)}
                  disabled={isDisabled}
                  className={`text-center p-1 rounded-full
                    ${isSelected ? 'bg-blue-500 text-white' : ''}
                    ${isCurrent ? 'bg-green-200' : ''}
                    ${isDisabled ? 'text-gray-300 cursor-not-allowed' : 'hover:bg-gray-200'}
                  `}
                >
                  {day}
                </button>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomDatePicker;