import React, { useEffect, useState, useCallback } from 'react';
import { Search } from 'lucide-react';
import { fetchAllEmployees, fetchAllIssues, fetchEmployeeByDepartment, getAllDepartments } from '../api/endpoints/APIEndpoints';
import ReportIssueForm from '../components/app/ReportIssueForm';
import AssignIssueForm from '../components/app/AssignIssueForm';
import { Tabs, Tab, Box, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import ResolveIssueForm from '../components/app/ResolveIssueForm';
import { shallowEqual, useSelector } from 'react-redux';
import { selectUserData } from '../store/store/authSlice';
import Modal from '@mui/material/Modal'; // Import Modal component
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Loader from '../components/common/Loader';
import { useNavigate } from 'react-router-dom'; // Import useHistory for navigation

const StyledTabs = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: '#1890ff',
  },
});

const StyledTab = styled(Tab)({
  textTransform: 'none',
  fontWeight: 600,
  fontSize: '16px',
  '&.Mui-selected': {
    color: '#1890ff',
  },
});

const Chip = styled('span')<{ color: string }>(({ color }) => ({
  display: 'inline-block',
  padding: '4px 8px',
  borderRadius: '16px',
  fontSize: '0.75rem',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  color: 'white',
  backgroundColor: color,
}));

const getStatusColor = (status: string) => {
  switch (status.toLowerCase()) {
    case 'open':
      return '#4caf50'; // Green
    case 'in progress':
      return '#2196f3'; // Blue
    case 'resolved':
      return '#9e9e9e'; // Grey
    default:
      return '#f44336'; // Red
  }
};

const getPriorityColor = (priority: string) => {
  switch (priority.toLowerCase()) {
    case 'low':
      return '#4caf50'; // Green
    case 'medium':
      return '#ff9800'; // Orange
    case 'high':
      return '#f44336'; // Red
    default:
      return '#9e9e9e'; // Grey
  }
};

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100], // Light gray background
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 600, // Semibold font
}));

const IssueManagementDashboard: React.FC = () => {
  const [issues, setIssues] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [fromDepartment, setFromDepartment] = useState<any>();
  const [toDepartment, setToDepartment] = useState<any>();
  const [allEmployees, setAllEmployees] = useState<any[]>([]);
  const [tabValue, setTabValue] = useState(0);
  const userDetails = useSelector(selectUserData);
  const [selectedIssue, setSelectedIssue] = useState<any | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userData = useSelector(selectUserData, shallowEqual)
  const [isLoading, setIsLoading] = useState(true);

  const history = useNavigate(); // Initialize useHistory

  const filteredIssues = issues.filter((issue) => {
    if (!issue) return false;
    const searchLower = searchTerm.toLowerCase();
    return (
      (issue.name?.toLowerCase().includes(searchLower) ?? false) ||
      (issue.type?.toLowerCase().includes(searchLower) ?? false) ||
      (issue.status?.toLowerCase().includes(searchLower) ?? false)
    );
  });

  const fetchIssues = useCallback(() => {
    return fetchAllIssues.post({ employee_id: userDetails?.employee_id })
      .then((res) => {
        setIssues(Array.isArray(res.issues) ? res.issues : []);
      })
      .catch((error) => {
        console.log("ERROR!!", error);
        setIssues([]);
      });
  }, [userDetails?.employee_id]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await Promise.all([
          fetchIssues(),
          getAllDepartments.get().then((res) => {
            let formattedDepartmentData = res?.map((department: {
              department_id: any;
              department_name: any;
            }) => {
              // For level 4, include all departments
              if (userDetails?.role?.level === 4) {
                return {
                  value: department?.department_id,
                  label: department?.department_name
                };
              }

              // For levels 2 and 3, include only departments the user has access to
              if (userDetails?.role?.level === 2 || userDetails?.role?.level === 3) {
                const hasAccess = userDetails.departments.some(
                  (userDept: any) => userDept.department_id === department.department_id
                );
                if (hasAccess) {
                  return {
                    value: department?.department_id,
                    label: department?.department_name
                  };
                }
              }

              // For other levels, don't include any departments
              return null;
            }).filter(Boolean) ?? []; // Filter out null values
        
            setFromDepartment(formattedDepartmentData);

            const toDepartmentData = res?.map((department: any) => ({
              value: department?.department_id,
              label: department?.department_name
            }))
            setToDepartment(toDepartmentData);

            fetchEmployeeByDepartment.post({ departmentId: userDetails?.departments[0].department_id })
              .then((res: React.SetStateAction<any[]>) => {
                setAllEmployees(Array.isArray(res) ? res : []);
              })
              .catch((error) => {
                console.log("ERROR!!", error);
                setAllEmployees([]);
              });
          }),
        ]);
      } catch (error) {
        console.log("ERROR!!", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [fetchIssues, userDetails?.role?.level, userDetails?.departments]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleIssueSubmit = (newIssue: any) => {
    fetchIssues();
    setTabValue(0);
  };

  const handleAssignSuccess = () => {
    fetchIssues();
    setTabValue(0);
  };

  const handleResolveSuccess = () => {
    fetchIssues();
    setTabValue(0);
    // Optionally, you can show a success message or perform other actions
  };

  const handleIssueClick = (issue: any) => {
    setSelectedIssue(issue);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedIssue(null);
  };

  return (
    <div className="container mx-auto p-6 h-[90vh] overflow-y-auto">
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <Typography variant="h4" component="h1" gutterBottom>
            Issue Management Dashboard
          </Typography>

          <StyledTabs value={tabValue} onChange={handleTabChange} aria-label="issue management dashboard tabs">
            <StyledTab label="Dashboard" />
            <StyledTab label="Report Issue" />
            <StyledTab label="Assign Issue" />
            <StyledTab label="Resolve Issue" />
          </StyledTabs>

          <Box sx={{ mt: 3 }}>
            {tabValue === 0 && (
              <>
                <div className="relative mb-4">
                  <input
                    type="text"
                    placeholder="Search issues..."
                    className="pl-10 pr-4 py-2 border rounded-lg w-full"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
                </div>
                <TableContainer component={Paper}>
                  <Table>
                    <StyledTableHead>
                      <TableRow>
                        <StyledTableCell>Issue ID</StyledTableCell>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell>Description</StyledTableCell>
                        <StyledTableCell>Department</StyledTableCell>
                        <StyledTableCell>Type</StyledTableCell>
                        <StyledTableCell>Raised Date</StyledTableCell>
                        <StyledTableCell>Resolved Date</StyledTableCell>
                        <StyledTableCell>Status</StyledTableCell>
                        <StyledTableCell>Priority</StyledTableCell>
                        <StyledTableCell>Location</StyledTableCell>
                      </TableRow>
                    </StyledTableHead>
                    <TableBody>
                      {filteredIssues.map((issue: any) => (
                        <TableRow key={issue.issue_id}>
                          <TableCell
                            onClick={() => handleIssueClick(issue)}
                            style={{ cursor: 'pointer', color: 'blue' }}
                          >
                            {issue.issue_id}
                          </TableCell>
                          <TableCell>{issue.name}</TableCell>
                          <TableCell>{issue.description}</TableCell>
                          <TableCell>{issue.Department.department_name}</TableCell>
                          <TableCell>{issue.type}</TableCell>
                          <TableCell>{issue.raised_date}</TableCell>
                          <TableCell>{issue.resolved_date || "--"}</TableCell>
                          <TableCell>
                            <Chip color={getStatusColor(issue.status)}>{issue.status}</Chip>
                          </TableCell>
                          <TableCell>
                            <Chip color={getPriorityColor(issue.priority)}>{issue.priority}</Chip>
                          </TableCell>
                          <TableCell>{issue.location}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
            {tabValue === 1 && (
              <ReportIssueForm
                departmentOptions={fromDepartment ?? []}
                onSubmit={handleIssueSubmit}
                toDepartmentOptions={toDepartment ?? []}
              />
            )}
            {tabValue === 2 && (
              <AssignIssueForm
                issues={issues}
                employees={allEmployees}
                onAssignSuccess={handleAssignSuccess}
              />
            )}
            {tabValue === 3 && (
              <ResolveIssueForm onSuccess={handleResolveSuccess} issues={issues.filter((issue)=> issue.status.toLowerCase() !== 'resolved')}/>
            )}
          </Box>

          {/* Modal for Issue Details */}

          <Modal
            open={isModalOpen}
            onClose={handleCloseModal}
            aria-labelledby="issue-details-modal"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              maxHeight: '80vh',
              overflowY: 'auto',
            }}>
              {selectedIssue && (
                <>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                      Issue Details
                    </Typography>
                    <IconButton
                      aria-label="close"
                      onClick={handleCloseModal}
                      sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <Typography id="modal-modal-description" component="div">
                    <p><strong>Issue ID:</strong> {selectedIssue.issue_id}</p>
                    <p><strong>Name:</strong> {selectedIssue.name}</p>
                    <p><strong>Type:</strong> {selectedIssue.type}</p>
                    <p><strong>Raised Date:</strong> {selectedIssue.raised_date}</p>
                    <p><strong>Raised By:</strong> {selectedIssue.raisedByDepartment.department_name}</p>
                    <p><strong>Status:</strong> {selectedIssue.status}</p>
                    <p><strong>Priority:</strong> {selectedIssue.priority}</p>
                    <p><strong>Location:</strong> {selectedIssue.location}</p>
                    <p><strong>Photo evidence:</strong> 
                      <img src={selectedIssue.photo_evidence} alt="Photo Evidence" />
                    </p>
                    {/* Add more details as needed */}
                  </Typography>
                </>
              )}
            </Box>
          </Modal>
        </>
      )}
    </div>
  );
};

export default IssueManagementDashboard;
