import React, { useState } from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer, Sector } from 'recharts';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

interface InspectionOutcomes {
  satisfactory: number;
  unsatisfactory: number;
  followUpRequested: number;
}

interface InspectionOutcomeDashboardProps {
  data: InspectionOutcomes;
}

const COLORS = ['#228822', '#B22222', '#FFE135'];
const LABELS = ['Satisfactory', 'Unsatisfactory', 'Follow-Up Required'];

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333" fontSize={12}>
        {`${payload.name}`}
      </text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999" fontSize={12}>
        {`${value} (${(percent * 100).toFixed(1)}%)`}
      </text>
    </g>
  );
};

const InspectionPieChart: React.FC<InspectionOutcomeDashboardProps> = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const totalInspections = data.satisfactory + data.unsatisfactory + data.followUpRequested;
  const satisfactoryPercentage = totalInspections > 0 
    ? ((data.satisfactory / totalInspections) * 100).toFixed(1)
    : '0.0';

  const chartData = [
    { name: 'Satisfactory', value: data.satisfactory },
    { name: 'Unsatisfactory', value: data.unsatisfactory },
    { name: 'Follow-Up', value: data.followUpRequested },
  ].filter(item => item.value > 0); // Only show segments with values

  const onPieEnter = (_: any, index: number) => {
    setActiveIndex(index);
  };

  // Render legend items
  const renderLegend = () => (
    <Box display="flex" justifyContent="center" gap={3} mt={2}>
      {LABELS.map((label, index) => (
        <Box key={label} display="flex" alignItems="center">
          <Box
            sx={{
              width: 12,
              height: 12,
              backgroundColor: COLORS[index],
              marginRight: 1,
              borderRadius: '50%'
            }}
          />
          <Typography variant="body2">{label}</Typography>
        </Box>
      ))}
    </Box>
  );

  return (
    <Card sx={{ maxWidth: 800, margin: 'auto', mt: 4, height: 420 }}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Inspection Outcomes</Typography>
          <Box>
            <Typography variant="h4" component="div" fontWeight="bold">
              {totalInspections}
            </Typography>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <CheckCircleOutlineIcon color="success" fontSize="small" sx={{ mr: 0.5 }} />
              <Typography variant="body2" color="success.main">
                {satisfactoryPercentage}% satisfactory
              </Typography>
            </Box>
          </Box>
        </Box>
        {renderLegend()}
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              data={chartData}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
              onMouseEnter={onPieEnter}
              paddingAngle={5}
            >
              {chartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default InspectionPieChart;