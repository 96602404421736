import React, { useState, useEffect, useMemo } from 'react';
import { Appliance } from '../../types/tasks';
import { Input } from '../common/Input';
import { Select } from '../common/Select';
import { addAppliance, addInspectionRoutines, generateTags } from '../../api/endpoints/APIEndpoints';
import Textarea from '../common/TextArea';
import Barcode from './Barcode';
import { FaPrint } from 'react-icons/fa';
import { getRandomUnboundedNaturalNumber, printBarcode } from '../../utils/common';
import { InspectionRoutine } from '../../types/common';
import Checkbox from '../common/Checkbox';
import { Autocomplete, TextField } from '@mui/material';
import CustomDatePicker from '../common/CustomDatePicker';
import { convertToBase64 } from '../../utils/common'; // Add this import
import imageCompression from 'browser-image-compression';
import Multiselect from 'multiselect-react-dropdown';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify'; // Make sure you have react-toastify installed
import { ThreeDots } from 'react-loader-spinner'; // Make sure you have react-loader-spinner installed
import EXIF from 'exif-js';
interface AddApplianceProps {
    departmentOptions: { value: string; label: string }[];
    projectOptions: { value: string; label: string }[];
    routineOptions: InspectionRoutine[]
    addParentQues: boolean;
    checklistData: { value: string; label: string; checklist_type: string, department_id: null }[]
    onSave: (appliance: Partial<Appliance>) => void;
    onCancel: () => void;
}

type TaskType = 'Inspection' | 'Maintenance';

interface DepartmentData {
    id: string;
    isInspection: boolean;
    isMaintenance: boolean;
    InspectionData: {
        checklist: string;
        routine: string;
        frequency: string;
        dueDateOffset: string;
    };
    MaintenanceData: {
        checklist: string;
        routine: string;
        frequency: string;
        dueDateOffset: string;
    };
}

const AddAppliance: React.FC<AddApplianceProps> = ({ departmentOptions, projectOptions, onCancel, addParentQues, checklistData, routineOptions }) => {
    const [applianceName, setApplianceName] = useState('');
    const [applianceDescription, setApplianceDescription] = useState('');
    const [applianceStatus, setApplianceStatus] = useState('');
    const [parentApplianceId, setParentApplianceId] = useState('');
    const [location, setLocation] = useState('');
    const [manufacturer, setManufacturer] = useState('');
    const [model, setModel] = useState('');
    const [serialNumber, setSerialNumber] = useState('');
    const [purchaseDate, setPurchaseDate] = useState<Date | null>(null);
    const [imageBase64, setImageBase64] = useState<string | null>(null);
    const [selectedDepartments, setSelectedDepartments] = useState<any[]>([]);
    const [departmentData, setDepartmentData] = useState<{
        [departmentName: string]: DepartmentData
    }>({});

    const [image, setImage] = useState<File | null>(null);
    const [barcodeValue, setBarcodeValue] = useState("");
    const [showBarcode, setShowBarcode] = useState(false);
    const [selectedProjects, setSelectedProjects] = useState<{ id: string; name: string }[]>([]);

    const { control, handleSubmit } = useForm();
    const [geolocationCoordinates,setGeolocationCoordinates] = useState<any>();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const routineOptionsFormatted = routineOptions?.map((item) => ({
        label: item.routine_name,
        value: item.routine_id.toString(),
    })) || []

    const frequencyOptions = [
        { value: "Weekly", label: "Weekly" },
        { value: "Fortnightly", label: "Fortnightly" },
        { value: "Monthly", label: "Monthly" },
        { value: "Quarterly", label: "Quarterly" },
        { value: "Half-Yearly", label: "Half-Yearly" },
        { value: "Yearly", label: "Yearly" }
    ];

    const dueDateOffsetOptions = [
        { value: "7", label: "7 days from scheduled inspection date" },
        { value: "15", label: "15 days from scheduled inspection date" }
    ];

    const multiSelectFormattedDepartmentOptions = departmentOptions.map((dept) => ({
        name: dept.label,
        id: dept.value
    }));

    const onSelect = (selectedList: any[], selectedItem: any) => {
        setSelectedDepartments(selectedList);
        setDepartmentData(prevData => ({
            ...prevData,
            [selectedItem.name]: {
                id: selectedItem.id,
                isInspection: false,
                isMaintenance: false,
                InspectionData: { checklist: '', routine: '', frequency: '', dueDateOffset: '' },
                MaintenanceData: { checklist: '', routine: '', frequency: '', dueDateOffset: '' }
            }
        }));
    };

    const onRemove = (selectedList: any[], removedItem: any) => {
        setSelectedDepartments(selectedList);
        setDepartmentData(prevData => {
            const { [removedItem.name]: _, ...rest } = prevData;
            return rest;
        });
    };

    const handleCheckboxChange = (deptName: string, type: 'isInspection' | 'isMaintenance', value: boolean) => {
        setDepartmentData(prevData => ({
            ...prevData,
            [deptName]: {
                ...prevData[deptName],
                [type]: value
            }
        }));
    };

    const handleDataChange = (deptName: string, type: TaskType, field: string, value: any) => {
        setDepartmentData(prevData => ({
            ...prevData,
            [deptName]: {
                ...prevData[deptName],
                [`${type}Data`]: {
                    ...prevData[deptName][`${type}Data`],
                    [field]: value
                }
            }
        }));

        if (field === 'routine') {
            const selectedRoutine = routineOptions.find(item => item.routine_id.toString() === value);
            if (selectedRoutine) {
                setDepartmentData(prevData => ({
                    ...prevData,
                    [deptName]: {
                        ...prevData[deptName],
                        [`${type}Data`]: {
                            ...prevData[deptName][`${type}Data`],
                            frequency: selectedRoutine.frequency,
                            dueDateOffset: selectedRoutine.due_date_offset.toString()
                        }
                    }
                }));
            }
        }
    };

    const compressImage = async (file: File): Promise<File> => {
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        };

        try {
            return await imageCompression(file, options);
        } catch (error) {
            console.error("Error compressing image:", error);
            return file;
        }
    };



    const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];

            const exifData: any = await new Promise(resolve => {
                EXIF.getData(file as any, () => {
                    resolve(EXIF.getAllTags(file));
                });
            });

            console.log("exif data", exifData);

            // Extract and convert GPS data
            const lat = exifData.GPSLatitude;
            const lon = exifData.GPSLongitude;
            const latRef = exifData.GPSLatitudeRef;
            const lonRef = exifData.GPSLongitudeRef;

            const latitude = lat ? dmsToDecimal(lat[0].numerator, lat[1].numerator, lat[2].numerator / lat[2].denominator, latRef) : null;
            const longitude = lon ? dmsToDecimal(lon[0].numerator, lon[1].numerator, lon[2].numerator / lon[2].denominator, lonRef) : null;

            console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
            // Store latitude and longitude in state or database as needed
            setGeolocationCoordinates([latitude, longitude])
            const compressedFile = await compressImage(file);
            setImage(compressedFile);
            const base64 = await convertToBase64(compressedFile);
            setImageBase64(base64);
        }
    };
    
    // Helper function for converting DMS to Decimal Degrees
    function dmsToDecimal(degrees: number, minutes: number, seconds: number, direction: string): number {
        let decimal = degrees + minutes / 60 + seconds / 3600;
        if (direction === "S" || direction === "W") {
            decimal *= -1; // Negative for South and West
        }
        return decimal;
    }
    
    console.log('geolo', geolocationCoordinates)

    const onSubmit = async (data: any) => {
        setIsSubmitting(true);
        try {
            const formattedDepartments = selectedDepartments.map(dept => ({
                department_id: parseInt(dept.id),
                inspection_routine_id: departmentData[dept.name].isInspection
                    ? parseInt(departmentData[dept.name].InspectionData.routine)
                    : null,
                maintenance_routine_id: departmentData[dept.name].isMaintenance
                    ? parseInt(departmentData[dept.name].MaintenanceData.routine)
                    : null,
                inspection_checklist_id: departmentData[dept.name].isInspection
                    ? departmentData[dept.name].InspectionData.checklist
                    : null,
                maintenance_checklist_id: departmentData[dept.name].isMaintenance
                    ? departmentData[dept.name].MaintenanceData.checklist
                    : null
            }));

            const payload = {
                appliance_name: applianceName,
                appliance_description: applianceDescription,
                appliance_status: applianceStatus,
                parent_appliance_id: parentApplianceId || null,
                departments: formattedDepartments,
                projects: selectedProjects.map(project => ({ project_id: project.id })),
                manufacturer,
                model,
                purchase_date: purchaseDate ? purchaseDate.toISOString().split('T')[0] : null, // Format as YYYY-MM-DD
                serial_number: serialNumber,
                image: imageBase64,
                geolocation: geolocationCoordinates
            };

            console.log('Appliance Data to be submitted:', payload);

            const response = await addAppliance.post(payload);
            console.log('Appliance added successfully:', response);

            toast.success("Appliance Added and Tag generated Successfully!");

            // Assuming the API response includes a tag_id
            if (response.appliance.tag_id) {
                setBarcodeValue(response?.appliance?.tag_id);
                setShowBarcode(true);
            } else {
                console.error('No tag_id received in the response');
                toast.error("Failed to generate tag. Please try again.");
            }

        } catch (error) {
            console.error('Error adding appliance:', error);
            toast.error("Failed to add appliance. Please try again.");
        } finally {
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        console.log('Current Form State:', {
            applianceName,
            applianceDescription,
            applianceStatus,
            parentApplianceId,
            location,
            manufacturer,
            model,
            serialNumber,
            purchaseDate,
            imageBase64,
            selectedDepartments,
            departmentData
        });
    }, [applianceName, applianceDescription, applianceStatus, parentApplianceId, location, manufacturer, model, serialNumber, purchaseDate, imageBase64, selectedDepartments, departmentData]);

    // Move the filtering logic outside of renderDetailsSection
    const getFilteredChecklistOptions = (type: TaskType, deptInfo: any) => {
        console.log("dept", deptInfo, checklistData)
        return checklistData.filter((checklist: any) => checklist.checklist_type === type && deptInfo.id === checklist.department_id);
    };

    const renderDetailsSection = (deptName: string, type: TaskType, deptInfo: any) => {
        const data = departmentData[deptName][`${type}Data`];
        const title = `${type} Details - ${deptName}`;

        const filteredChecklistOptions = getFilteredChecklistOptions(type, deptInfo);

        return (
            <div className="mt-4 p-4 border rounded">
                <h3 className="text-lg font-semibold mb-3">{title}</h3>
                <div className="space-y-4">
                    <Select
                        label="Checklist"
                        options={filteredChecklistOptions}
                        value={data.checklist}
                        onChange={(e) => handleDataChange(deptName, type, 'checklist', e.target.value)}
                        required
                    />
                    <Select
                        label="Routine name"
                        options={routineOptionsFormatted}
                        value={data.routine}
                        onChange={(e) => handleDataChange(deptName, type, 'routine', e.target.value)}
                        required
                    />
                    <Select
                        label="Frequency"
                        options={frequencyOptions}
                        value={data.frequency}
                        onChange={(e) => handleDataChange(deptName, type, 'frequency', e.target.value)}
                        required
                    />
                    <Select
                        label="Due Date Offset"
                        options={dueDateOffsetOptions}
                        value={data.dueDateOffset}
                        onChange={(e) => handleDataChange(deptName, type, 'dueDateOffset', e.target.value)}
                        required
                    />
                </div>
            </div>
        );
    };

    return (
        <>
            {
                showBarcode
                    ? <div className="flex flex-col items-center justify-center">
                        <div className='flex'>
                            <img src="./company_logo.png" alt="error" width="140" height="140" />
                            <div
                                id="printableBarcode"
                                className="flex items-center justify-center w-full h-full print:absolute print:inset-0"
                            >
                                <Barcode value={barcodeValue} />
                            </div>
                        </div>
                        <button
                            onClick={printBarcode}
                            className="p-2 rounded-md bg-green-500 flex mt-4 p-2 text-white"
                        >
                            <FaPrint className="w-6 h-6" /> &nbsp; Print

                        </button>
                    </div>
                    : <>
                        <h2 className="text-2xl font-bold mb-4">Add Appliance</h2>
                        <form onSubmit={handleSubmit(onSubmit)} className="gap-4">
                            <div className='grid grid-cols-2 gap-x-4 gap-y-4'>
                                <Input
                                    label="Appliance Name"
                                    value={applianceName}
                                    onChange={(e) => setApplianceName(e.target.value)}
                                    required
                                />
                                <Textarea
                                    label="Appliance Description"
                                    value={applianceDescription}
                                    onChange={(e) => setApplianceDescription(e.target.value)}
                                    required
                                />
                                <Select
                                    label="Appliance Status"
                                    options={[
                                        { value: "active", label: "Active" },
                                        { value: "inactive", label: "Inactive" }
                                    ]}
                                    value={applianceStatus}
                                    onChange={(e) => setApplianceStatus(e.target.value)}
                                    required
                                />
                                {addParentQues && (
                                    <Input
                                        label="Parent Appliance Id"
                                        value={parentApplianceId}
                                        onChange={(e) => setParentApplianceId(e.target.value)}
                                        required
                                    />
                                )}
                                <div className='mb-2'>
                                    <p className='text-left text-sm pb-2 text-gray-800'>Projects</p>
                                    <Controller
                                        name="projects"
                                        control={control}
                                        render={({ field }) => (
                                            <Multiselect
                                                options={projectOptions.map(option => ({ id: option.value, name: option.label }))}
                                                selectedValues={selectedProjects}
                                                onSelect={(selectedList) => {
                                                    setSelectedProjects(selectedList);
                                                    field.onChange(selectedList);
                                                }}
                                                onRemove={(selectedList) => {
                                                    setSelectedProjects(selectedList);
                                                    field.onChange(selectedList);
                                                }}
                                                displayValue="name"
                                            />
                                        )}
                                    />
                                </div>
                                <Input
                                    label="Location"
                                    value={location}
                                    onChange={(e) => setLocation(e.target.value)}
                                    required
                                />
                                <Input
                                    label="Manufacturer"
                                    value={manufacturer}
                                    onChange={(e) => setManufacturer(e.target.value)}
                                    required
                                />
                                <Input
                                    label="Model"
                                    value={model}
                                    onChange={(e) => setModel(e.target.value)}
                                    required
                                />
                                <Input
                                    label="Serial Number"
                                    value={serialNumber}
                                    onChange={(e) => setSerialNumber(e.target.value)}
                                    required
                                />
                                <div className="col-span-2">
                                    <p className='text-sm text-left pb-2'>Purchase Date:</p>
                                    <Controller
                                        name="purchaseDate"
                                        control={control}
                                        render={({ field }) => (
                                            <CustomDatePicker
                                                selectedDate={field.value}
                                                onChange={(date) => field.onChange(date)}
                                                allowPastDates={true}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col-span-2">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Appliance Image
                                    </label>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                        className="mt-1 block w-full"
                                    />
                                </div>

                                <div className="col-span-2 mt-4 p-4 border rounded">
                                    <p className='text-sm text-left pb-2'>Departments:</p>
                                    <Multiselect
                                        options={multiSelectFormattedDepartmentOptions}
                                        selectedValues={selectedDepartments}
                                        onSelect={onSelect}
                                        onRemove={onRemove}
                                        displayValue="name"
                                    />

                                    {Object.entries(departmentData).map(([deptName, deptInfo]) => (
                                        <div key={deptInfo.id} className="mt-4 p-4 border rounded">
                                            <h3 className="text-lg font-semibold mb-3">Department: {deptName}</h3>
                                            <div className="flex space-x-4 mb-4">
                                                <Checkbox
                                                    label="Inspection"
                                                    checked={deptInfo.isInspection}
                                                    onChange={(checked) => handleCheckboxChange(deptName, 'isInspection', checked)}
                                                />
                                                <Checkbox
                                                    label="Maintenance"
                                                    checked={deptInfo.isMaintenance}
                                                    onChange={(checked) => handleCheckboxChange(deptName, 'isMaintenance', checked)}
                                                />
                                            </div>
                                            {deptInfo.isInspection && renderDetailsSection(deptName, 'Inspection', deptInfo)}
                                            {deptInfo.isMaintenance && renderDetailsSection(deptName, 'Maintenance', deptInfo)}
                                        </div>
                                    ))}
                                </div>
                            </div>


                            <div className="flex justify-end mt-4">
                                <button type="button" onClick={onCancel} className="mr-2 bg-gray-300 text-gray-800 p-2 rounded">
                                    BACK TO DASHBOARD
                                </button>
                                <button 
                                    type="submit" 
                                    className="bg-blue-500 text-white p-2 rounded flex items-center justify-center min-w-[100px]"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? (
                                        <ThreeDots color="#ffffff" height={24} width={24} />
                                    ) : (
                                        'Save'
                                    )}
                                </button>
                            </div>
                        </form>
                    </>
            }
        </>
    );
};

export default AddAppliance;
