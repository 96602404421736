import React from 'react';
import { FluentAlertOn24Filled } from '../../icons/FluentAlertOn24Filled';

const AlertHeader: React.FC = () => {
  return (
    <div className="flex items-center space-x-2 mb-4">
      <FluentAlertOn24Filled className="h-6 w-6 text-red-600" />
      <h1 className="text-2xl font-bold text-gray-800">Alerts</h1>
    </div>
  );
};

export default AlertHeader;
