import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';

interface Issue {
  issue_id: string;
  status: string;
  raised_date: string;
  resolved_date: string | null;
}

interface IssueReportGraphProps {
  issues: Issue[];
}

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          padding: '10px',
          borderRadius: '4px',
        }}
      >
        <Typography variant="body2">{label}</Typography>
        {payload.map((pld: any) => (
          <Typography key={pld.dataKey} variant="body2" sx={{ color: pld.fill }}>
            {`${pld.name === 'reported' ? 'Reported' : 'Resolved'}: ${pld.value}`}
          </Typography>
        ))}
      </Box>
    );
  }
  return null;
};

const IssueReportGraph: React.FC<IssueReportGraphProps> = ({ issues }) => {
  const processIssueData = () => {
    const today = new Date();
    const chartData = [];
    
    // Create data for the last 7 days
    for (let i = 6; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(date.getDate() - i);
      const dateStr = date.toLocaleDateString('en-GB', { 
        day: '2-digit', 
        month: '2-digit'
      });

      // Get issues for this date
      const dateIssues = issues.filter(issue => {
        const issueDate = new Date(issue.raised_date).toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit'
        });
        return issueDate === dateStr;
      });

      // Get resolved issues for this date
      const resolvedIssues = issues.filter(issue => {
        if (!issue.resolved_date) return false;
        const resolvedDate = new Date(issue.resolved_date).toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit'
        });
        return resolvedDate === dateStr;
      });

      chartData.push({
        date: dateStr,
        reported: dateIssues.length,
        resolved: resolvedIssues.length
      });
    }

    return chartData;
  };

  const chartData = processIssueData();
  const totalReported = issues.length;
  const totalResolved = issues.filter(issue => issue.status === 'Resolved').length;

  return (
    <Card sx={{ maxWidth: 800, margin: 'auto', mt: 4, height: 420 }}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <p>Issues Overview</p>
          <Box>
            <Typography variant="h4" component="div" fontWeight="bold">
              {totalReported}
            </Typography>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <Typography variant="body2" color="success.main">
                {totalResolved} resolved
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={1}>
          <Box sx={{ width: 10, height: 10, borderRadius: '50%', backgroundColor: '#ED7117', mr: 1 }} />
          <Typography variant="body2" mr={2}>Issues Reported</Typography>
          <Box sx={{ width: 10, height: 10, borderRadius: '50%', backgroundColor: '#4CBB17', mr: 1 }} />
          <Typography variant="body2">Issues Resolved</Typography>
        </Box>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={chartData} margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis 
              dataKey="date" 
              axisLine={{ stroke: '#E0E0E0' }} 
              tickLine={false}
              tick={{ fill: '#9E9E9E', fontSize: 12 }}
            />
            <YAxis 
              axisLine={{ stroke: '#E0E0E0' }} 
              tickLine={false}
              tick={{ fill: '#9E9E9E', fontSize: 12 }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Line 
              type="monotone" 
              dataKey="reported" 
              stroke="#ED7117" 
              strokeWidth={3} 
              dot={false} 
              animationDuration={2000}
            />
            <Line 
              type="monotone" 
              dataKey="resolved" 
              stroke="#4CBB17" 
              strokeWidth={3} 
              dot={false} 
              animationDuration={2000}
            />
          </LineChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default IssueReportGraph;