import moment from 'moment';

export const getRandomUnboundedNaturalNumber = () => {
    // Generate a random number between 0 and 1
    const r = Math.random();

    // Use a logarithmic distribution to favor smaller numbers
    // but still allow for occasional very large numbers
    const x = -Math.log(1 - r);

    // Scale and round to get a natural number
    return Math.floor(x * 100) + 1;
}

export const printBarcode = (): void => {
    const barcodeElement = document.getElementById('printableBarcode');

    if (!barcodeElement) {
        console.error('No element found with ID "printableBarcode"');
        return;
    }

    const printWindow = window.open('', '_blank');
    if (!printWindow) {
        console.error('Unable to open print window');
        return;
    }

    const style = `
        @page {
            size: 60mm 35mm;
            margin: 0;
        }
        @media print {
            html, body {
                width: 60mm;
                height: 35mm;
                margin: 0;
                padding: 0;
            }
            body * {
                visibility: hidden;
            }
            #barcodeContainer, #barcodeContainer * {
                visibility: visible;
            }
        }
        body {
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60mm;
            height: 35mm;
        }
        #barcodeContainer {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        #barcodeContent {
            transform: scale(0.9); /* Adjust this value to fit the barcode properly */
        }
    `;

    printWindow.document.write(`
        <!DOCTYPE html>
        <html>
            <head>
                <title>Print Barcode</title>
                <style>${style}</style>
            </head>
            <body>
                <div id="barcodeContainer">
                    <div id="barcodeContent">${barcodeElement.innerHTML}</div>
                </div>
                <script>
                    window.onload = function() {
                        window.print();
                        window.onafterprint = function() {
                            window.close();
                        };
                    };
                </script>
            </body>
        </html>
    `);

    printWindow.document.close();
};

function isCompletionDateToday(completionDateString: string) {
    const completionDate = new Date(completionDateString);
    const today = new Date();

    return completionDate.toDateString() === today.toDateString();
}


export const filterAndFormatWeeklyData = (data: any) => {
    const today = moment();
    const startOfWeek = today.clone().startOf('week');
    const endOfWeek = today.clone().endOf('week');

    const chartDataMap: { [key: string]: any } = {};

    data?.forEach((task: any) => {
        const createdAt = moment(task.created_at);
        if (createdAt.isBetween(startOfWeek, endOfWeek, null, '[]')) {
            const dateKey = createdAt.format('DD-MM');

            if (!chartDataMap[dateKey]) {
                chartDataMap[dateKey] = {
                    day: dateKey,
                    Scheduled: 0,
                    Completed: 0,
                    CompletedLate: 0
                };
            }

            chartDataMap[dateKey].Scheduled++;

            if (task.status === 'completed' && task.completion_date) {
                const completionDate = moment(task.completion_date);
                const dueDate = moment(task.due_date);

                if (completionDate.isSameOrBefore(dueDate)) {
                    chartDataMap[dateKey].Completed++;
                } else {
                    chartDataMap[dateKey].CompletedLate++;
                }
            }
        }
    });

    let formattedData = Object.values(chartDataMap).sort((a, b) =>
        moment(a.day, 'DD-MM').diff(moment(b.day, 'DD-MM'))
    );

    // Pad with empty days if less than 7 days
    while (formattedData.length < 7) {
        const lastDay = formattedData.length > 0
            ? moment(formattedData[formattedData.length - 1].day, 'DD-MM').add(1, 'day')
            : startOfWeek;
        formattedData.push({
            day: lastDay.format('DD-MM'),
            Scheduled: 0,
            Completed: 0,
            CompletedLate: 0
        });
    }

    return formattedData;
};


export const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });
};

export const convertPercentageToFiveScale = (percentage: string | number): number => {
    // Remove % sign if it's a string and convert to number
    const numericPercentage = typeof percentage === 'string' 
      ? parseFloat(percentage.replace('%', '')) 
      : percentage;
    
    // Convert from 0-100 scale to 1-5 scale
    // Formula: 1 + (percentage / 100 * 4)
    // This ensures:
    // 0% = 1 star
    // 100% = 5 stars
    const rating = 1 + (numericPercentage / 100 * 4);
    
    // Round to 1 decimal place
    return Math.round(rating * 10) / 10;
  };
  

  export const formatTableDataForPDF = (
    data: any[],
    columns: string[],
    customFormatters?: { [key: string]: (value: any) => string }
  ) => {
    return data.map(item => {
      const row: { [key: string]: any } = {};
      columns.forEach(col => {
        if (customFormatters && customFormatters[col]) {
          row[col] = customFormatters[col](item);
        } else {
          row[col] = item[col] || '--';
        }
      });
      return row;
    });
  };