import React, { useState } from 'react';
import { ChevronRight, ChevronLeft } from 'lucide-react';

interface FlippingCardProps {
  data: any;
  type: string;
}

const FlippingCards: React.FC<FlippingCardProps> = ({ data, type }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFlipping, setIsFlipping] = useState(false);

  const nextCard = () => {
    if (currentIndex < data?.length - 1) {
      setIsFlipping(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => prevIndex + 1);
        setIsFlipping(false);
      }, 300);
    }
  };

  const prevCard = () => {
    if (currentIndex > 0) {
      setIsFlipping(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => prevIndex - 1);
        setIsFlipping(false);
      }, 300);
    }
  };

  console.log("data", data, type)

  const getPriorityColor = (priority: string) => {
    switch (priority) {
      case 'low':
        return 'bg-blue-100 text-blue-800';
      case 'medium':
        return 'bg-yellow-100 text-yellow-800';
      case 'high':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getCardStyle = (index: number) => {
    const offset = index - currentIndex;
    return {
      transform: `translateX(${offset * 8}%) scale(${1 - Math.abs(offset) * 0.05})`,
      opacity: 1 - Math.abs(offset) * 0.2,
      zIndex: data?.length - Math.abs(offset),
      display: Math.abs(offset) <= 1 ? 'block' : 'none', // Show only 2 cards
    };
  };

  return (
    <div className="p-6 rounded-lg shadow-lg relative mr-8" style={{ background: "rgba(185, 231, 243, 0.3)" }}>
      <h2 className="text-xl font-semibold mb-4">{type === "alert" ? "Alert" : "Issues"}</h2>
      <div className="relative h-40  overflow-visible">

        {
          type === "alert" && data?.map((item: any, index: number) => (
            <div
              key={item.alert_id}
              className={`absolute top-0 left-0 w-full transition-all duration-300 ease-in-out ${isFlipping ? 'animate-flip-out' : ''
                }`}
              style={getCardStyle(index)}
            >
              <div className="bg-white rounded-lg p-4 shadow-md">
                <div className='bg-white'>
                  <div className="flex items-center bg-white justify-between mb-2">
                    <div className="flex items-center">
                      <img src="./alert-2.png" alt="error" width={50} />
                      <span className="ml-2 font-semibold text-gray-700">{item?.alert_id}</span>
                    </div>
                    <span className={`px-2 py-1 rounded-full text-xs font-medium ${getPriorityColor(item?.priority)}`}>
                      {item.priority.charAt(0).toUpperCase() + item.priority.slice(1)}
                    </span>
                  </div>
                  <p className="text-gray-600 mb-2">{item.purpose}</p>
                  <p className="text-sm text-gray-500">Raised on: {item.raised_date}</p>
                </div>

              </div>
            </div>
          ))
        }
        {
          type === "issues" && data?.map((item: any, index: number) => (
            <div
              key={item.issue_id}
              className={`absolute top-0 left-0 w-full transition-all duration-300 ease-in-out ${isFlipping ? 'animate-flip-out' : ''
                }`}
              style={getCardStyle(index)}
            >
              <div className="bg-white rounded-lg p-4 shadow-md">
                <div className='bg-white'>
                  <div className="flex items-center bg-white justify-between mb-2">
                    <div className="flex items-center">
                      <img src="./issues-4.jpg" alt="error" width={50} />
                      <span className="ml-2 font-semibold text-gray-700">{item?.issue_id}</span>
                    </div>
                    <span className={`px-2 py-1 rounded-full text-xs font-medium ${getPriorityColor(item?.Appliance?.appliance_name)}`}>
                      {item.priority.charAt(0).toUpperCase() + item.priority.slice(1)}
                    </span>
                  </div>
                  <p className="text-gray-600 mb-2">{item.type}</p>
                  <p className="text-sm text-gray-500">Raised on: {item.raised_date}</p>
                </div>

              </div>
            </div>
          ))
        }

      </div>
      <button
        onClick={prevCard}
        className={`absolute top-1/2 left-0 transform -translate-y-1/2 translate-x-[-10%] bg-white rounded-full p-1 shadow-md z-10 ${currentIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        disabled={currentIndex === 0}
      >
        <ChevronLeft className="w-6 h-6 text-gray-600" />
      </button>
      <button
        onClick={nextCard}
        className={`absolute top-1/2 right-0 transform -translate-y-1/2 translate-x-[-1%] bg-white rounded-full p-1 shadow-md z-10 ${currentIndex === data?.length - 1 ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        disabled={currentIndex === data?.length - 1}
      >
        <ChevronRight className="w-6 h-6 text-gray-600" />
      </button>
    </div>
  );
};

export default FlippingCards;