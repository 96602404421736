import React, { useState } from 'react';
import { Autocomplete, TextField, Button, Box } from '@mui/material';
import { assignIssue } from '../../api/endpoints/APIEndpoints'; // You'll need to create this endpoint
import { shallowEqual, useSelector } from 'react-redux';
import { selectUserData } from '../../store/store/authSlice';

interface AssignIssueFormProps {
    issues: any[];
    employees: any[];
    onAssignSuccess: () => void;
}

const AssignIssueForm: React.FC<AssignIssueFormProps> = ({ issues, employees, onAssignSuccess }) => {
    const [selectedIssue, setSelectedIssue] = useState<any | null>(null);
    const [selectedEmployee, setSelectedEmployee] = useState<any | null>(null);
    const userDetails = useSelector(selectUserData, shallowEqual);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!selectedIssue || !selectedEmployee) {
            alert('Please select both an issue and an employee');
            return;
        }

        try {
          await assignIssue.post({
            issue_id: selectedIssue.issue_id,
            assigned_to: selectedEmployee.employeeId,
            assigning_employee_id: userDetails?.employee_id
          });
          onAssignSuccess();
          setSelectedIssue(null);
          setSelectedEmployee(null);
        } catch (error) {
          console.error('Error assigning issue:', error);
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ '& > :not(style)': { m: 1, width: '100%' } }}>
            <Autocomplete
                options={issues}
                getOptionLabel={(option) => `${option.name} (${option.issue_id} - ${option.type})`}
                renderInput={(params) => <TextField {...params} label="Select Issue" />}
                value={selectedIssue}
                onChange={(_, newValue) => setSelectedIssue(newValue)}
            />
            <Autocomplete
                options={employees}
                getOptionLabel={(option) => `${option.firstName + " " + option.lastName} (${option.employeeId})`}
                renderInput={(params) => <TextField {...params} label="Select Employee" />}
                value={selectedEmployee}
                onChange={(_, newValue) => setSelectedEmployee(newValue)}
            />
            <Button type="submit" variant="contained" color="primary">
                Assign Issue
            </Button>
        </Box>
    );
};

export default AssignIssueForm;
