import React, { useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Autocomplete, TextField, Button } from '@mui/material';
import { selectAllEmployees, selectAllRoles, setAllEmployees, setAllRoles } from '../../store/store/mainSlice';
import { grantPermission, fetchAllEmployees, fetchSelectedEmployeeDetails, getAllRoles } from '../../api/endpoints/APIEndpoints';
import { selectUserData } from '../../store/store/authSlice';

interface Employee {
  employee_id: string;
  first_name: string;
  last_name: string;
  role: {
    role_name: string;
    level: number;
  };
}

const PermissionView: React.FC = () => {
  const allEmployees = useSelector(selectAllEmployees);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(null);
  const [selectedRole, setSelectedRole] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [employeeDetails, setEmployeeDetails] = useState<any>();
  const userDetails:any = useSelector(selectUserData, shallowEqual);
  const allRoles = useSelector(selectAllRoles, shallowEqual);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (allEmployees.length === 0) {
      fetchAllEmployees.get()
      .then((res)=> dispatch(setAllEmployees(res)))
      .catch(error => console.error("Failed to fetch employees:", error));
    }
    if (allRoles.length === 0) {
      getAllRoles.get()
      .then((res) => {
        dispatch(setAllRoles(res));
      })
      .catch(error => console.error("Failed to fetch roles:", error));
    }
  }, [allEmployees, allRoles]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedEmployee || !selectedRole) return;

    setIsSubmitting(true);
    try {
      await grantPermission.post({ 
        employeeId: selectedEmployee.employee_id, 
        newRoleId: +(selectedRole),
        requestorId: userDetails.employee_id
      });
      // The global toast setup will handle the success message
    } catch (error) {
      // The global toast setup will handle the error message
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEmployeeSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    // setSelectedEmployee(e.target.value);
    fetchSelectedEmployeeDetails.post({ employeeId: e.target.value })
      .then((res) => setEmployeeDetails(res))
      .catch((error) => console.log("ERROR!!", error));
  };    

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">Permission View</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <Autocomplete
            disablePortal
            options={allEmployees.filter((employee: any) => {
              return employee.departments.some((empDept: any) =>{
                return userDetails.departments.some((department: any) =>{
                    if (department.department_id === empDept.department_id && employee.role.level !== 4){
                      return employee;
                    }
                })
              })
            })}
            getOptionLabel={(option: Employee) => `${option.first_name} ${option.last_name} (${option.employee_id})`}
            renderInput={(params) => <TextField {...params} label="Select Employee" />}
            onChange={(event, newValue) => {
              setSelectedEmployee(newValue);
              setSelectedRole('');
              if (newValue) {
                handleEmployeeSelection({
                  target: { value: newValue.employee_id }
                } as React.ChangeEvent<HTMLSelectElement>);
              }
            }}
          />
        </div>
        {selectedEmployee && (
          <>
            <div className="mb-4">
              <p><strong>Name:</strong> {employeeDetails?.first_name} {employeeDetails?.last_name}</p>
              <p><strong>Current Level:</strong> {employeeDetails?.role?.role_name} (Level {employeeDetails?.role?.level})</p>
            </div>
            <div className="mb-4">
              <TextField
                select
                label="Select New Role"
                value={selectedRole}
                onChange={(e) => setSelectedRole(e.target.value)}
                fullWidth
                SelectProps={{
                  native: true,
                }}
              >
                <option value="">Select a role</option>
                {allRoles.map((role: any) => (
                  <option key={role.level} value={role.role_id}>
                    {role.role_name} (Level {role.level})
                  </option>
                ))}
              </TextField>
            </div>
          </>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSubmitting || !selectedEmployee || !selectedRole}
        >
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </Button>
      </form>
    </div>
  );
};

export default PermissionView;
