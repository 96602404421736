import React from 'react';

interface TextareaProps {
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  required?: boolean;
  className?: string;
  name?: string;
}

const Textarea: React.FC<TextareaProps> = ({ label, value, onChange, placeholder, required, name, className }) => {
  return (
    <div className={`mb-4 ${className}`}>
      <label className="block text-gray-700 text-sm text-left mb-2">
        {label}
        {required && <span className="text-red-500">*</span>}
      </label>
      <textarea
        className="shadow appearance-none border bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" style={{height: "45px"}}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        name={name}
      />
    </div>
  );
};

export default Textarea;
