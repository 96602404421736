import React, { useState } from 'react';
import ApplianceStats from './ApplianceStats';

type TabType = 'appliance' | 'weekly' | 'monthly' | 'yearly';

const Statistics = () => {
  const [activeTab, setActiveTab] = useState<TabType>('appliance');

  const tabs: { id: TabType; label: string }[] = [
    { id: 'appliance', label: 'Appliance Stats' },
    { id: 'weekly', label: 'Weekly Stats' },
    { id: 'monthly', label: 'Monthly Stats' },
    { id: 'yearly', label: 'Yearly Stats' },
  ];

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-6">Statistics Dashboard</h1>
      
      {/* Tab Navigation */}
      <div className="flex space-x-1 border-b border-gray-200">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={`px-4 py-2 font-medium rounded-t-lg transition-colors
              ${activeTab === tab.id 
                ? 'bg-gray-700 text-white' 
                : 'text-gray-500 hover:text-gray-700 hover:bg-gray-100'
              }`}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.label}
          </button>
        ))}
      </div>

      {/* Tab Content */}
      <div className="mt-4">
        {activeTab === 'appliance' && <ApplianceStats />}
        {activeTab === 'weekly' && <div>Weekly Statistics Content</div>}
        {activeTab === 'monthly' && <div>Monthly Statistics Content</div>}
        {activeTab === 'yearly' && <div>Yearly Statistics Content</div>}
      </div>
    </div>
  );
};

export default Statistics;