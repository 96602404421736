import React, { useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { extendAlert } from '../../api/endpoints/APIEndpoints';
import { toast } from 'react-toastify';

interface ExtendAlertProps {
  alertData: any[];
  departmentOptions: any[];
}

const ExtendAlert: React.FC<ExtendAlertProps> = ({ alertData, departmentOptions }) => {
  const [selectedAlert, setSelectedAlert] = useState<any | null>(null);
  const [extendedDateTime, setExtendedDateTime] = useState<Dayjs | null>(null);

  const handleAlertChange = (event: any, newValue: any | null) => {
    setSelectedAlert(newValue);
  };

  const handleDateTimeChange = (newValue: Dayjs | null) => {
    setExtendedDateTime(newValue);
  };

  const handleSubmit = () => {
    // Implement the logic to extend the alert
    console.log('Extending alert:', selectedAlert, 'to:', extendedDateTime);
    extendAlert.post({alert_id: selectedAlert.alert_id, end_date_time: extendedDateTime})
    .then(() => {
      toast.success("Alert extended successfully")
    })
    .catch((err) => {
      toast.error("Error extending alert")
    })
  };

  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-2xl font-bold mb-4">Extend Alert</h2>
      <div className="flex flex-col space-y-4">
        <Autocomplete
          options={alertData}
          getOptionLabel={(option) => `${option.title || option.purpose} - ${option.alert_id}`}
          renderInput={(params) => <TextField {...params} label="Select Alert" />}
          onChange={handleAlertChange}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label="Extend Until"
            value={extendedDateTime}
            onChange={handleDateTimeChange}
            minDateTime={dayjs()} // This disables past dates and times
            disablePast={true}  
          />
        </LocalizationProvider>
        <button 
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          onClick={handleSubmit}
        >
          Extend Alert
        </button>
      </div>
    </div>
  );
};

export default ExtendAlert;
