import React, { useState, useEffect } from 'react';
import { fetchAllAppliances, scheduleInspection } from '../../api/endpoints/APIEndpoints';
import { Select } from '../common/Select';
import { shallowEqual, useSelector } from 'react-redux';
import { selectUserData } from '../../store/store/authSlice';
import CustomDatePicker from '../common/CustomDatePicker';
import Checkbox from '../common/Checkbox';
import { Autocomplete, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from '../common/Modal'; // Assuming you have a Modal component
import zIndex from '@mui/material/styles/zIndex';

interface TeamMember {
  employee_id: string;
  first_name: string;
  last_name: string;
  email: string;
  role: string;
}

interface Project {
  project_id: number;
  project_name: string;
}

interface Team {
  team_id: number;
  team_name: string;
  projects: Project[];
  members: TeamMember[];
}

interface ScheduleInspectionViewProps {
  teamMembers: any
  allEmployees?: any
}

type ApplianceOption = {
  label: string;
  value: string;
  projectId: number | null;
  projectName: string | null;
  departments: any[];
};

interface Department {
  department_id: number;
  department_name: string;
}

// Update the interface to handle multiple employees
interface ModalDetails {
  appliance_name: string;
  employee_name?: string;
  assigned_employees?: Array<{
    departmentId: string;
    employeeId: string;
    firstName: string;
    lastName: string;
  }>;
  scheduled_date: Date;
  due_date: Date;
  type: string;
  isbreakdownMaintenance?: boolean;
  isMultiDepartment?: boolean;
}

const ScheduleInspectionView: React.FC<ScheduleInspectionViewProps> = ({ teamMembers, allEmployees }) => {
  const [applianceData, setApplianceData] = useState<ApplianceOption[]>([]);
  const [selectedAppliance, setSelectedAppliance] = useState<ApplianceOption | null>(null);
  const [filteredTeamMembers, setFilteredTeamMembers] = useState<any>(teamMembers);
  const [selectedEmployee, setSelectedEmployee] = useState<string>('');
  const [scheduledDate, setScheduledDate] = useState<Date | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const userData = useSelector(selectUserData, shallowEqual);
  const [dueDate, setDueDate] = useState<Date | null>(null);
  const [minSelectableDate, setMinSelectableDate] = useState<Date>(new Date());
  const [taskType, setTaskType] = useState('');
  const [isBreakdownMaintenance, setIsBreakdownMaintenance] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [modalDetails, setModalDetails] = useState<any>(null);
  const [selectedEmployees, setSelectedEmployees] = useState<{ [key: number]: string }>({});
  const [departments, setDepartments] = useState<Department[]>([]);
  const [isMultiDepartment, setIsMultiDepartment] = useState(false);
  const [taskTypeOptions, setTaskTypeOptions] = useState<{ value: string; label: string }[]>([]);
  const [isSingleMaintenance, setIsSingleMaintenance] = useState(false);
  const [isSingleInspection, setIsSingleInspection] = useState(false);

  useEffect(() => {
    fetchAllAppliances.post({ employeeId: userData?.employee_id })
      .then((res) => {
        const formattedAppliances = res.map((appliance: any) => {
          const departments = appliance.departments || [];
          const departmentNames = departments.map((department: any) => department.project_name).join(', ');

          return {
            label: departmentNames.length > 0
              ? `${appliance.appliance_name} - [${departmentNames}]`
              : `${appliance.appliance_name} - No Project Assigned`,
            value: appliance.appliance_id,
            projectId: departments.length > 0 ? departments[0].project_id : null,
            projectName: departments.length > 0 ? departmentNames : null,
            departments: departments
          };
        }).filter((appliance: { projectId: null; }) => appliance.projectId !== null);
        setApplianceData(formattedAppliances);
      })
      .catch((error) => console.error("Error fetching appliances:", error));
  }, []);

  console.log(teamMembers)

  const handleApplianceChange = (_: any, newValue: ApplianceOption | null) => {
    setSelectedAppliance(newValue);
    if (newValue && newValue.departments && newValue.departments.length > 1) {
      setIsMultiDepartment(true);
      setDepartments(newValue.departments);
      setSelectedEmployees({});
    } else {
      setIsMultiDepartment(false);
      setDepartments([]);
      setSelectedEmployee('');
    }

    console.log("new value", newValue);

    // Determine available task types based on checklist IDs
    const inspectionChecklistId = newValue?.departments[0]?.inspection_checklist_id;
    const maintenanceChecklistId = newValue?.departments[0]?.maintenance_checklist_id;

    const options = [];
    if (inspectionChecklistId) {
      options.push({ value: "inspection_task", label: "Inspection Task" });
    }
    if (maintenanceChecklistId) {
      options.push({ value: "maintenance_task", label: "Maintenance Task" });
    }

    setTaskTypeOptions(options);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedAppliance || (!selectedEmployee && Object.keys(selectedEmployees).length === 0) || !scheduledDate || !dueDate) {
      toast.error("Please fill in all fields");
      return;
    }

    try {
      const assignedTo = isMultiDepartment ? Object.values(selectedEmployees) : selectedEmployee;
      const targetDepartmentIds = isMultiDepartment 
        ? departments.map(dept => dept.department_id) 
        : [selectedAppliance.departments[0].department_id];

      await scheduleInspection.post({
        appliance_id: selectedAppliance.value,
        assigned_to: assignedTo,
        monitored_by: userData?.employee_id,
        scheduled_date: scheduledDate,
        due_date: dueDate,
        type: taskType,
        isBreakdownMaintenance: isBreakdownMaintenance,
        raised_by_department_id: userData?.departments[0]?.department_id,
        target_department_ids: targetDepartmentIds,
        isSingleInspection: isSingleInspection,
        isSingleMaintenance: isSingleMaintenance
      });
    
      const modalDetails = {
        appliance_name: selectedAppliance?.label,
        isMultiDepartment: isMultiDepartment,
        assigned_employees: isMultiDepartment 
          ? Object.entries(selectedEmployees).map(([deptId, empId]) => {
              const employee = allEmployees.find((member: any) => member.employee_id === empId);
              const department = departments.find(dept => dept.department_id === parseInt(deptId));
              
              return {
                departmentId: deptId,
                departmentName: department?.department_name || '',
                employeeId: employee?.employee_id,
                firstName: employee?.first_name,
                lastName: employee?.last_name
              };
            })
          : undefined,
        employee_name: !isMultiDepartment 
          ? (() => {
              const employee = allEmployees.find((member: any) => member.employee_id === selectedEmployee);
              return employee ? `${employee.first_name} ${employee.last_name}` : '';
            })()
          : undefined,
        scheduled_date: scheduledDate,
        due_date: dueDate,
        type: taskType,
        isbreakdownMaintenance: isBreakdownMaintenance
      };

      setModalDetails(modalDetails);
      setShowSuccessModal(true);

      // Reset form
      setSelectedAppliance(null);
      setSelectedEmployee('');
      setScheduledDate(null);
      setDueDate(null);
      setTaskType('');
      setIsBreakdownMaintenance(false);

    } catch (err) {
      console.error("Error scheduling inspection:", err);
      toast.error("Failed to schedule inspection. Please try again.");
    }
  };

  if (loading) return <div className="text-center">Loading...</div>;

  const userLevel = userData?.role?.level || 2; // Get the logged-in user's role level

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Schedule Inspection</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <Autocomplete
            options={applianceData}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
              <li {...props} style={{ fontSize: '14px' }}>
                {option.label}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Appliance"
                value={selectedAppliance ? selectedAppliance.label : ''}
              />
            )}
            value={selectedAppliance}
            onChange={handleApplianceChange}
            isOptionEqualToValue={(option, value) => option.value === value?.value}
          />
        </div>
        {isMultiDepartment ? (
          departments.map((dept) => (
            <Select
              key={dept.department_id}
              label={`Team Member - ${dept.department_name}`}
              options={allEmployees
                .filter((member: any) => 
                  member.departments.some((d: any) => d.department_id === dept.department_id) && 
                  member.role.level <= userLevel
                )
                .map((member: any) => ({
                  label: `${member.first_name} ${member.last_name}`,
                  value: member.employee_id
                }))}
              value={selectedEmployees[dept.department_id] || ''}
              onChange={(e) => setSelectedEmployees({...selectedEmployees, [dept.department_id]: e.target.value})}
              required
            />
          ))
        ) : (
          <Select
            label="Team Member"
            options={allEmployees
              .filter((member: any) => 
                selectedAppliance && 
                member.departments.some((d: any) => d.department_id === selectedAppliance.departments[0].department_id) &&
                member.role.level <= userLevel
              )
              .map((member: any) => ({
                label: `${member.first_name} ${member.last_name}`,
                value: member.employee_id
              }))}
            value={selectedEmployee}
            onChange={(e) => setSelectedEmployee(e.target.value)}
            required
          />
        )}
        <Select
          label="Inspection type"
          options={taskTypeOptions}
          value={taskType}
          onChange={(e) => setTaskType(e.target.value)}
          required
        />
        {taskType === "maintenance_task" && (
          <div className='flex gap-2'>
            <Checkbox
              label="Breakdown Maintenance"
              checked={isBreakdownMaintenance}
              onChange={setIsBreakdownMaintenance}
            />
            <Checkbox
              label="Single Maintenance"
              checked={isSingleMaintenance}
              onChange={setIsSingleMaintenance}
            />
          </div>
        )}
        {taskType === "inspection_task" && (
          <div className='flex'>
            <Checkbox
              label="Single Inspection"
              checked={isSingleInspection}
              onChange={setIsSingleInspection}
            />
          </div>
        )}
        <div>
          <p className='text-sm text-left pb-2'>Schedule Date:</p>
          <CustomDatePicker
            selectedDate={scheduledDate}
            onChange={(date) => setScheduledDate(date)}
          />
        </div>
        <div>
          <p className='text-sm text-left pb-2'>Due Date:</p>
          <CustomDatePicker
            selectedDate={dueDate}
            onChange={(date) => setDueDate(date)}
          />
        </div>
        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
          Schedule Inspection
        </button>
      </form>
      <Modal show={showSuccessModal} onClose={() => setShowSuccessModal(false)}>
        <div className="p-8 text-center bg-white rounded-lg">
          <img
            src="/success.png"
            alt="Success"
            className="mx-auto mb-6 w-24 h-24"
          />
          <h3 className="mb-4 text-2xl font-semibold text-green-600">
            Inspection Scheduled Successfully!
          </h3>
          <div className="mb-6 text-left bg-gray-50 p-4 rounded-md">
            <p className="mb-2">
              <span className="font-semibold">Appliance:</span> {modalDetails?.appliance_name}
            </p>
            
            {/* Conditional rendering for single vs multi department */}
            {modalDetails?.isMultiDepartment ? (
              <div className="mb-2">
                <span className="font-semibold">Assigned To:</span>
                <ul className="ml-4 mt-1">
                  {modalDetails.assigned_employees?.map((employee: { employeeId: React.Key | null | undefined; firstName: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; lastName: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; departmentName: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; }) => (
                    <li key={employee.employeeId} className="text-sm">
                      • {employee.firstName} {employee.lastName} ({employee.departmentName})
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <p className="mb-2">
                <span className="font-semibold">Assigned To:</span> {modalDetails?.employee_name}
              </p>
            )}
            
            <p className="mb-2">
              <span className="font-semibold">Scheduled Date:</span>{' '}
              {modalDetails?.scheduled_date?.toLocaleDateString()}
            </p>
            <p className="mb-2">
              <span className="font-semibold">Due Date:</span>{' '}
              {modalDetails?.due_date?.toLocaleDateString()}
            </p>
            <p className="mb-2">
              <span className="font-semibold">Task Type:</span>{' '}
              {modalDetails?.type === 'inspection_task' ? 'Inspection' : 'Maintenance'}
              {modalDetails?.isbreakdownMaintenance && ' (Breakdown)'}
            </p>
          </div>
          <button
            type="button"
            className="text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center transition duration-300 ease-in-out transform hover:scale-105"
            onClick={() => setShowSuccessModal(false)}
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ScheduleInspectionView;
