import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, CircularProgress, Autocomplete, TextField } from '@mui/material';
import { Input } from '../common/Input';
import { Select } from '../common/Select';
import { reAssignInspection } from '../../api/endpoints/APIEndpoints';

type TaskId = string;
type TeamMember = { id: string; name: string };

type FormData = {
    taskId: TaskId;
    applianceName: string;
    tagId: string;
    teamMember: string;
};

interface ReassignTaskFormProps {
    tasksData: any;
    teams: any;
}

const ReassignTaskForm: React.FC<ReassignTaskFormProps> = ({ tasksData, teams }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { control, handleSubmit, formState: { errors }, setValue, watch } = useForm<FormData>();

    const taskIds = tasksData.map((task: any) => ({ value: task.schedule_id, id: task.schedule_id }));

    const watchTaskId = watch("taskId");

    React.useEffect(() => {
        if (watchTaskId) {
            const selectedTask = tasksData.find((task: any) => task.schedule_id === watchTaskId);
            if (selectedTask) {
                setValue("applianceName", selectedTask.appliance.appliance_name || "");
                setValue("tagId", selectedTask.appliance.tag_id || "");
            }
        }
    }, [watchTaskId, tasksData, setValue]);

    const onSubmit = async (data: FormData) => {
        setIsLoading(true);
        reAssignInspection.post({
            employee_id: data.teamMember,
            schedule_id: data.taskId
        }).then((res) => {
            console.log("reassigned task successfully!!", res)
            setIsLoading(false)
        })
            .catch((error) => console.log("ERROR!!", error))
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 p-4 bg-white shadow rounded-lg">
            <Controller
                name="taskId"
                control={control}
                rules={{ required: 'Task ID is required' }}
                render={({ field }) => (
                    <Autocomplete
                        disablePortal
                        options={taskIds}
                        value={taskIds.find((option: { id: string; }) => option.id === field.value) || null}
                        onChange={(event, newValue) => {
                            field.onChange(newValue ? newValue.id : null);
                        }}
                        getOptionLabel={(option) => `${option.id}`}
                        renderInput={(params) => <TextField {...params} label="Task ID" />}
                    />
                )}
            />

            <Controller
                name="applianceName"
                control={control}
                rules={{ required: 'Appliance name is required' }}
                render={({ field }) => (
                    <Input
                        label="Appliance Name"
                        {...field}
                        disabled
                    />
                )}
            />

            <Controller
                name="tagId"
                control={control}
                rules={{ required: 'Tag ID is required' }}
                render={({ field }) => (
                    <Input
                        label="Tag ID"
                        {...field}
                        disabled
                    />
                )}
            />

            <Controller
                name="teamMember"
                control={control}
                rules={{ required: 'Team member is required' }}
                render={({ field }) => (
                    <Select
                        label="Team Member"
                        options={teams?.map((member: { employeeId: any; firstName: any; lastName: any }) => ({ value: member.employeeId, label: member.firstName + " " + member.lastName }))}
                        {...field}
                    />
                )}
            />

            <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={isLoading}
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
            >
                {isLoading ? (
                    <CircularProgress size={24} color="inherit" />
                ) : (
                    'Reassign Task'
                )}
            </Button>
        </form>
    );
};

export default ReassignTaskForm;