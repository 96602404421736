import React from 'react';

const Loader: React.FC = () => (
  <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-white bg-opacity-75 z-50">
    <img src="/loader.gif" alt="Loading..." className="w-14 h-14" />
  </div>
);

export default Loader;

