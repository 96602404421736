import React, { useState, useMemo, useEffect, useRef } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { ApprovalProps } from '../../types/common';
import { handleApprovalRequest } from '../../api/endpoints/APIEndpoints';
import { MdApproval } from "react-icons/md";

const ApprovalComponent: React.FC<ApprovalProps> = ({ approvalRequests, onApprove, onReject }) => {
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const sortedRequests = useMemo(() => {
        return [...approvalRequests].sort((a, b) => {
            // Pending requests should always come first
            if (a.status === 'pending' && b.status !== 'pending') return -1;
            if (a.status !== 'pending' && b.status === 'pending') return 1;
            
            // If both are not pending, sort by status (approved, then denied)
            if (a.status !== 'pending' && b.status !== 'pending') {
                if (a.status === 'approved' && b.status === 'denied') return -1;
                if (a.status === 'denied' && b.status === 'approved') return 1;
            }
            
            // If statuses are the same, maintain original order
            return 0;
        });
    }, [approvalRequests]);

    const pendingRequests = sortedRequests.filter(req => req.status === 'pending');
    console.log("Pending Requests", pendingRequests)
    const handleApprove = (id: number) => {
        onApprove(id);
        setIsOpen(false);
        handleApprovalRequest.post({
            request_id: id,
            action: "approve"
        }).then((res) => console.log("Request approved", res))
          .catch((error) => console.log("ERROR!!", error));
    };

    const handleReject = (id: number) => {
        onReject(id);
        setIsOpen(false);
        handleApprovalRequest.post({
            request_id: id,
            action: "deny"
        }).then((res) => console.log("Request rejected", res))
          .catch((error) => console.log("ERROR!!", error));
    };

    const renderRequestDetails = (request: any) => {
        const requestDetails = JSON.parse(request.details);
        switch (request.type) {
            case 'user_registration':
                return `Registration of "${requestDetails.firstName} ${requestDetails.lastName}" from ${requestDetails.email}`;
            case 'checklist_addition':
                return `Checklist Addition for Appliance No- "${requestDetails.name}"`;
            case 'team_member_addition':
                return `Add "${requestDetails.first_name} ${requestDetails.last_name}" to ${requestDetails.team_name}`;
            case 'role_change':
                return request.requester.name + "(" + request.requester.id + ")" + " has requested for a role change for " + requestDetails.employee_name + "( Level-" + requestDetails.current_role_id +" "+ requestDetails.current_role_name +" )" + " to " + requestDetails.new_role_name +" " +"Level-" + requestDetails.new_role_id+ "."
            default:
                return 'Unknown request type';
        }
    };

    return (
        <div className="relative">
            <button
                className="relative p-2 text-gray-600 hover:bg-gray-100 rounded-full focus:outline-none"
                onClick={() => setIsOpen(!isOpen)}
            >
                <MdApproval size={20} />
                {pendingRequests.length > 0 && (
                    <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
                        {pendingRequests.length}
                    </span>
                )}
            </button>

            {isOpen && (
                <div ref={dropdownRef} className="absolute right-0 mt-2 w-80 bg-white rounded-md shadow-lg overflow-hidden z-20">
                    <div className="py-2">
                        <div className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100">
                            Approval Requests
                        </div>
                        <div className="max-h-60 overflow-y-auto">
                            {sortedRequests.map((request) => (
                                <div
                                    key={request.id}
                                    className={`px-4 py-2 ${request.status === 'pending' ? 'bg-blue-50' : ''}`}
                                >
                                    <div className="flex justify-between items-center">
                                        <div className="flex-grow">
                                            <p className="text-xs text-gray-500 text-left">
                                                {renderRequestDetails(request)}
                                            </p>
                                            <p className="text-xs text-gray-400 mt-1">
                                                Status: {request.status.charAt(0).toUpperCase() + request.status.slice(1)}
                                            </p>
                                        </div>
                                        {request.status === 'pending' && (
                                            <div className="flex space-x-2 ml-2">
                                                <button
                                                    onClick={() => handleApprove(request.id)}
                                                    className="p-1 text-green-600 hover:bg-green-100 rounded"
                                                    title="Approve"
                                                >
                                                    <FaCheck size={16} />
                                                </button>
                                                <button
                                                    onClick={() => handleReject(request.id)}
                                                    className="p-1 text-red-600 hover:bg-red-100 rounded"
                                                    title="Reject"
                                                >
                                                    <FaTimes size={16} />
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ApprovalComponent;
