import React, { useState } from 'react';
import { FaEdit, FaEnvelope, FaIdCard, FaUserTie, FaUsers, FaProjectDiagram, FaLock } from 'react-icons/fa';
import { shallowEqual, useSelector } from 'react-redux';
import { selectUserData } from '../store/store/authSlice';
import { useNavigate } from 'react-router-dom';

const ProfilePage: React.FC = () => {
  const userData = useSelector(selectUserData, shallowEqual);
  const navigate = useNavigate();

  return (
    <div className="bg-gradient-to-br from-blue-100 to-purple-100 min-h-screen flex items-center justify-center p-4">
      <div className="bg-white shadow-2xl rounded-3xl overflow-hidden max-w-3xl w-full">
        <div className="p-8">
          <div className="flex justify-between items-start mb-6">
            <div className="flex items-center">
              <img
                className="h-24 w-24 rounded-full object-cover mr-6"
                src={"https://via.placeholder.com/150"}
                alt="Profile"
              />
              <h1 className="text-3xl font-bold text-gray-900">{userData?.first_name + " " + userData?.last_name}</h1>
            </div>
            {/* <button
              onClick={handleOpenModal}
              className="bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition duration-300 ease-in-out transform hover:scale-105"
            >
              <FaEdit className="inline mr-2" />
              Edit Profile
            </button> */}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <ProfileField icon={<FaEnvelope />} label="Email" value={userData?.email} />
            <ProfileField icon={<FaIdCard />} label="Employee ID" value={userData?.employee_id} />
            <ProfileField icon={<FaUserTie />} label="Role" value={userData?.role.role_name} />
            <ProfileField icon={<FaUsers />} label="Department" value={userData?.departments.map(dept => dept.department_name).join(',') } />
            <ProfileField icon={<FaProjectDiagram />} label="Project" value={userData?.projects.map(project => project.project_name).join(',') } />
            <ProfileField icon={<FaUsers />} label="Team" value={userData?.team?.team_name || "Not Assigned"} />
          </div>
          <div className="mt-8 flex justify-center">
            <button
              onClick={() => navigate("/change-password")}
              className="bg-purple-500 text-white px-6 py-3 rounded-full hover:bg-purple-600 transition duration-300 ease-in-out transform hover:scale-105 flex items-center"
            >
              <FaLock className="mr-2" />
              Change Password
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

interface ProfileFieldProps {
  icon: React.ReactNode;
  label: string;
  value: string | undefined;
}

const ProfileField: React.FC<ProfileFieldProps> = ({ icon, label, value }) => (
  <div className="flex items-center space-x-3">
    <div className="text-blue-500">{icon}</div>
    <div>
      <p className="text-sm font-medium text-gray-500">{label}</p>
      <p className="mt-1 text-lg font-semibold text-gray-900">{value || "N/A"}</p>
    </div>
  </div>
);

export default ProfilePage;
