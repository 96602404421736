import React, { useState } from 'react';
import {
    IconButton,
    Modal,
    Box,
    Typography,
    Tooltip,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Divider,
    Chip
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';
import AddDocument from './AddDocument';
import jsPDF from 'jspdf';
import dayjs from 'dayjs';
import { 
    Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineDot 
} from '@mui/lab';
import { deleteDocument } from '../../api/endpoints/APIEndpoints';
import { toast } from 'react-toastify';

interface DocumentDashboardProps {
    documentData: any[];
}

const DocumentDashboard: React.FC<DocumentDashboardProps> = ({ documentData }) => {
    const [selectedDocument, setSelectedDocument] = useState<any | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    const getPriorityStyle = (priority: string) => {
        switch (priority.toLowerCase()) {
            case "high":
                return "text-red-800 bg-red-100";
            case "medium":
                return "text-yellow-800 bg-yellow-100";
            case "low":
                return "text-green-800 bg-green-100";
            default:
                return "text-gray-800 bg-gray-100";
        }
    };

    const handleDocumentClick = (document: any) => {
        setSelectedDocument(document);
        setIsModalOpen(true);
    };

    const handleEditClick = (document: any, e: React.MouseEvent) => {
        e.stopPropagation();
        setSelectedDocument(document);
        setIsEditModalOpen(true);
    };

    const handleDeleteClick = (document: any, e: React.MouseEvent) => {
        e.stopPropagation();
        setSelectedDocument(document);
        setIsDeleteDialogOpen(true);
    };

    const handlePrintDocument = (document: any, e: React.MouseEvent) => {
        e.stopPropagation();
        const doc = new jsPDF();

        doc.setFontSize(16);
        doc.text(`Document Details: ${document.document_number}`, 14, 15);

        (doc as any).autoTable({
            head: [['Field', 'Value']],
            body: [
                ['Document Number', document.document_number],
                ['Document Name', document.document_name],
                ['Description', document.document_description],
                ['Initial Issue Date', dayjs(document.initial_issue_date).format('YYYY-MM-DD')],
                ['Renewal Frequency', document.renewal_frequency],
                ['Process Department', document.process_department_for_renewal],
                ['Priority', document.priority],
                ['Additional Requirements', document.additional_requirements],
                ['Comment', document.comment]
            ],
            startY: 25,
            styles: { fontSize: 8 },
            headStyles: { fillColor: [66, 66, 66] },
        });

        doc.save(`document_${document.document_number}.pdf`);
    };

    const handleDelete = () => {
        // Implement delete functionality
        console.log('Deleting document:', selectedDocument);
        setIsDeleteDialogOpen(false);
        setSelectedDocument(null);
        deleteDocument.post({document_id: selectedDocument.document_id})
        .then((res: any) => {
            toast.success("Renewal deleted successfully");
        })
        .catch((err) => {
            console.log(err);
        })
    };

    return (
        <div className="w-full">
            <div className="overflow-x-auto">
                <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-100">
                        <tr>
                            <th scope="col" className="px-6 py-3">Document Id</th>
                            <th scope="col" className="px-6 py-3">Document Number</th>
                            <th scope="col" className="px-6 py-3">Document Name</th>
                            <th scope="col" className="px-6 py-3">Issue Date</th>
                            <th scope="col" className="px-6 py-3">Renewal Frequency</th>
                            <th scope="col" className="px-6 py-3">Priority</th>
                            <th scope="col" className="px-6 py-3">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {documentData?.map((document: any) => (
                            <tr
                                key={document.id}
                                className="bg-white border-b hover:bg-gray-50 cursor-pointer"
                                onClick={() => handleDocumentClick(document)}
                            >
                                <td className="px-6 py-4 font-medium text-gray-900">
                                    {document.document_id}
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900">
                                    {document.document_number}
                                </td>
                                <td className="px-6 py-4">{document.document_name}</td>
                                <td className="px-6 py-4">
                                    {dayjs(document.initial_issue_date).format('YYYY-MM-DD')}
                                </td>
                                <td className="px-6 py-4">{document.renewal_frequency}</td>
                                <td className="px-6 py-4">
                                    <span className={`px-2 py-1 text-xs font-semibold rounded-full ${getPriorityStyle(document.priority)}`}>
                                        {document.priority.toUpperCase()}
                                    </span>
                                </td>
                                <td className="px-6 py-4">
                                    <div className="flex gap-2">
                                        <Tooltip title="Print">
                                            <IconButton onClick={(e) => handlePrintDocument(document, e)} size="small">
                                                <PrintIcon className="w-5 h-5" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Edit">
                                            <IconButton onClick={(e) => handleEditClick(document, e)} size="small">
                                                <EditIcon className="w-5 h-5" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                            <IconButton onClick={(e) => handleDeleteClick(document, e)} size="small">
                                                <DeleteIcon className="w-5 h-5" />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Document Details Modal */}
            <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                aria-labelledby="document-details-modal"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    maxWidth: '800px',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    maxHeight: '90vh',
                    overflowY: 'auto',
                }}>
                    <Box className="flex justify-between items-center mb-4">
                        <Typography variant="h6">Document Details</Typography>
                        <IconButton onClick={() => setIsModalOpen(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Typography component="div" className="space-y-2 mb-2">
                        <p><strong>Document Number:</strong> {selectedDocument?.document_number}</p>
                        <p><strong>Document Name:</strong> {selectedDocument?.document_name}</p>
                        <p><strong>Description:</strong> {selectedDocument?.document_description}</p>
                        <p><strong>Issue Date:</strong> {dayjs(selectedDocument?.initial_issue_date).format('DD/MM/YYYY')}</p>
                        <p><strong>Renewal Frequency:</strong> {selectedDocument?.renewal_frequency}</p>
                        <p><strong>Priority:</strong> {selectedDocument?.priority}</p>
                        <p><strong>Additional Requirements:</strong> {selectedDocument?.additional_requirements}</p>
                        <p><strong>Comment:</strong> {selectedDocument?.comment}</p>
                    </Typography>

                    <Divider className="my-4" />
                    
                    {/* Current Renewal Section */}
                    {selectedDocument?.renewal_history?.current_renewal && (
                        <div className="mb-4">
                            <Typography variant="h6" className="mb-3 font-semibold">Current Renewal</Typography>
                            <div className="bg-gray-50 p-4 rounded-lg">
                                <div className="flex justify-between items-center mb-2">
                                    <span className="font-semibold">Renewal ID: {selectedDocument.renewal_history.current_renewal.renewal_id}</span>
                                    <Chip 
                                        label={selectedDocument.renewal_history.current_renewal.status}
                                        color={selectedDocument.renewal_history.current_renewal.status === 'pending' ? 'warning' : 'success'}
                                        size="small"
                                    />
                                </div>
                                <p><strong>Due Date:</strong> {dayjs(selectedDocument.renewal_history.current_renewal.due_date).format('DD/MM/YYYY')}</p>
                                <p><strong>Renewal Date:</strong> {dayjs(selectedDocument.renewal_history.current_renewal.renewal_date).format('DD/MM/YYYY')}</p>
                                <p><strong>Reminder Frequency:</strong> {selectedDocument.renewal_history.current_renewal.reminder_frequencies}</p>
                                {selectedDocument.renewal_history.current_renewal.comment && (
                                    <p><strong>Comment:</strong> {selectedDocument.renewal_history.current_renewal.comment}</p>
                                )}
                            </div>
                        </div>
                    )}

                    {/* Renewal History Section */}
                    {selectedDocument?.renewal_history?.renewals && selectedDocument.renewal_history.renewals.length > 0 && (
                        <div>
                            <Typography variant="h6" className="mb-3 font-semibold">Renewal History</Typography>
                            <Timeline>
                                {selectedDocument.renewal_history.renewals.map((renewal: any, index: number) => (
                                    <TimelineItem key={renewal.renewal_id}>
                                        <TimelineSeparator>
                                            <TimelineDot 
                                                color={renewal.status === 'pending' ? 'warning' : 'success'}
                                            />
                                            {index < selectedDocument.renewal_history.renewals.length - 1 && <TimelineConnector />}
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <div className="bg-gray-50 p-3 rounded-lg">
                                                <div className="flex justify-between items-center mb-2">
                                                    <span className="font-semibold">{renewal.renewal_id}</span>
                                                    <Chip 
                                                        label={renewal.status}
                                                        color={renewal.status === 'pending' ? 'warning' : 'success'}
                                                        size="small"
                                                    />
                                                </div>
                                                <div className="text-sm">
                                                    <p><strong>Due Date:</strong> {dayjs(renewal.due_date).format('DD/MM/YYYY')}</p>
                                                    <p><strong>Renewal Date:</strong> {dayjs(renewal.renewal_date).format('DD/MM/YYYY')}</p>
                                                    {renewal.completed_date && (
                                                        <p><strong>Completed Date:</strong> {dayjs(renewal.completed_date).format('DD/MM/YYYY')}</p>
                                                    )}
                                                    {renewal.completed_by && (
                                                        <p><strong>Completed By:</strong> {renewal.completed_by}</p>
                                                    )}
                                                    <p><strong>Reminder Frequency:</strong> {renewal.reminder_frequencies}</p>
                                                    {renewal.comment && (
                                                        <p><strong>Comment:</strong> {renewal.comment}</p>
                                                    )}
                                                </div>
                                            </div>
                                        </TimelineContent>
                                    </TimelineItem>
                                ))}
                            </Timeline>
                        </div>
                    )}
                </Box>
            </Modal>

            {/* Edit Modal */}
            <Modal
                open={isEditModalOpen}
                onClose={() => setIsEditModalOpen(false)}
                aria-labelledby="edit-document-modal"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    maxWidth: '800px',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    maxHeight: '90vh',
                    overflowY: 'auto',
                }}>
                    <AddDocument
                        onClose={() => setIsEditModalOpen(false)}
                        isEditMode={true}
                        editData={selectedDocument}
                    />
                </Box>
            </Modal>

            {/* Delete Confirmation Dialog */}
            <Dialog
                open={isDeleteDialogOpen}
                onClose={() => setIsDeleteDialogOpen(false)}
            >
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete this document?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handleDelete} color="error">Delete</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DocumentDashboard; 