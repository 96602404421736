import React, { useState, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  styled,
  SelectChangeEvent,
} from '@mui/material';

// Interfaces and Types
interface Column {
  id: string;
  label: string;
}

interface Data {
  [key: string]: any;
}

interface FrequencyOption {
  value: string;
  label: string;
}

interface ReusableTableProps {
  columns: Column[];
  data: Data[];
  frequencyOptions?: FrequencyOption[];
  onFrequencyChange?: (frequency: string) => void;
  onFilterChange?: (filteredData: Data[]) => void;
}

// Styled components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-head': {
    backgroundColor: "white",
    color: "black",
    fontWeight: 'bold',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const TableWrapper = styled('div')({
  width: '100%',
  maxWidth: '1000px',
  margin: '0 auto',
  padding: '20px',
});

const FilterWrapper = styled('div')({
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
  marginBottom: '16px',
  width: '100%'
});

const FilterGroup = styled('div')({
  display: 'flex',
  gap: '16px',
  flex: 2
});

const ReusableTable: React.FC<ReusableTableProps> = ({ 
  columns, 
  data, 
  frequencyOptions,
  onFrequencyChange,
  onFilterChange 
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filterColumn, setFilterColumn] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [selectedFrequency, setSelectedFrequency] = useState('');

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFrequencyChange = (event: SelectChangeEvent) => {
    const newFrequency = event.target.value;
    setSelectedFrequency(newFrequency);
    if (onFrequencyChange) {
      onFrequencyChange(newFrequency);
    }
  };

  const filteredData = useMemo(() => {
    let result = data;
    if (filterColumn && filterValue) {
      result = data.filter((row) =>
        String(row[filterColumn]).toLowerCase().includes(filterValue.toLowerCase())
      );
    }
    if (onFilterChange) {
      onFilterChange(result);
    }
    return result;
  }, [data, filterColumn, filterValue, onFilterChange]);

  const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <TableWrapper>
      <FilterWrapper>
        <FilterGroup>
          <FormControl sx={{ flex: 1, minWidth: 200 }}>
            <InputLabel id="filter-column-label">Filter Column</InputLabel>
            <Select
              labelId="filter-column-label"
              value={filterColumn}
              onChange={(e) => setFilterColumn(e.target.value)}
              label="Filter Column"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {columns.map((column) => (
                <MenuItem key={column.id} value={column.id}>
                  {column.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Filter Value"
            variant="outlined"
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
            disabled={!filterColumn}
            sx={{ flex: 1, minWidth: 200 }}
          />
        </FilterGroup>

        {frequencyOptions && (
          <FormControl sx={{ flex: 1, minWidth: 200 }}>
            <InputLabel id="frequency-label">Report Frequency</InputLabel>
            <Select
              labelId="frequency-label"
              value={selectedFrequency}
              onChange={handleFrequencyChange}
              label="Report Frequency"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {frequencyOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </FilterWrapper>
      <TableContainer component={Paper} sx={{ marginBottom: 2, minHeight: 300 }}>
        <Table stickyHeader aria-label="reusable table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell key={column.id}>
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.length > 0 ? (
              paginatedData.map((row, rowIndex) => (
                <StyledTableRow key={rowIndex}>
                  {columns.map((column) => (
                    <TableCell key={column.id}>{row[column.id] || 'N/A'}</TableCell>
                  ))}
                </StyledTableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} align="center" sx={{ py: 3 }}>
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableWrapper>
  );
};

export default ReusableTable;