import React, { useState } from 'react';
import {
  IconButton,
  Modal,
  Box,
  Typography,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';
import AddAsset from './AddAsset';
import jsPDF from 'jspdf';
import dayjs from 'dayjs';

interface AssetDashboardProps {
  assetData: any[];
}

const AssetDashboard: React.FC<AssetDashboardProps> = ({ assetData }) => {
  const [selectedAsset, setSelectedAsset] = useState<any | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const getStatusStyle = (status: string) => {
    switch (status.toLowerCase()) {
      case "active":
        return "text-green-800 bg-green-100";
      case "inactive":
        return "text-red-800 bg-red-100";
      case "maintenance":
        return "text-yellow-800 bg-yellow-100";
      default:
        return "text-gray-800 bg-gray-100";
    }
  };

  const handleAssetClick = (asset: any) => {
    setSelectedAsset(asset);
    setIsModalOpen(true);
  };

  const handleEditClick = (asset: any, e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedAsset(asset);
    setIsEditModalOpen(true);
  };

  const handleDeleteClick = (asset: any, e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedAsset(asset);
    setIsDeleteDialogOpen(true);
  };

  const handlePrintAsset = (asset: any, e: React.MouseEvent) => {
    e.stopPropagation();
    const doc = new jsPDF();

    doc.setFontSize(16);
    doc.text(`Asset Details: ${asset.asset_name}`, 14, 15);

    (doc as any).autoTable({
      head: [['Field', 'Value']],
      body: [
        ['Asset Name', asset.asset_name],
        ['Status', asset.status],
        ['Location', asset.location],
        ['Manufacturer', asset.manufacturer],
        ['Serial Number', asset.serial_number],
        ['Model', asset.model],
        ['Purchase Date', dayjs(asset.purchase_date).format('YYYY-MM-DD')],
        ['Purchase Cost', asset.purchase_cost],
        ['Depreciation Rate', `${asset.depreciation_rate}%`],
        ['Department', asset.department],
        ['Reminder Frequency', `${asset.reminder_frequency} days`]
      ],
      startY: 25,
      styles: { fontSize: 8 },
      headStyles: { fillColor: [66, 66, 66] },
    });

    doc.save(`asset_${asset.asset_name}.pdf`);
  };

  const handleDelete = () => {
    // Implement delete functionality
    console.log('Deleting asset:', selectedAsset);
    setIsDeleteDialogOpen(false);
    setSelectedAsset(null);
  };

  const calculateDepreciationValue = (asset: any) => {
    const purchaseDate = dayjs(asset.purchase_date);
    const today = dayjs();
    const yearsDiff = today.diff(purchaseDate, 'year', true);
    const originalValue = parseFloat(asset.purchase_cost);
    const depreciationRate = parseFloat(asset.depreciation_rate) / 100;
    
    const currentValue = originalValue * Math.pow(1 - depreciationRate, yearsDiff);
    return currentValue.toFixed(2);
  };

  return (
    <div className="w-full">
      <div className="overflow-x-auto">
      <div className='mb-2'><strong>Total Assets: {assetData?.length}</strong></div>
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-100">
            <tr>
              <th scope="col" className="px-6 py-3">Asset Name</th>
              <th scope="col" className="px-6 py-3">Appliance (Yes/No)</th>
              <th scope="col" className="px-6 py-3">Status</th>
              <th scope="col" className="px-6 py-3">Location</th>
              <th scope="col" className="px-6 py-3">Purchase Date</th>
              <th scope="col" className="px-6 py-3">Current Value</th>
              <th scope="col" className="px-6 py-3">Actions</th>
            </tr>
          </thead>
          <tbody>
            
            {assetData?.map((asset: any) => (
              <tr 
                key={asset.asset_id} 
                className="bg-white border-b hover:bg-gray-50 cursor-pointer"
                onClick={() => handleAssetClick(asset)}
              >
                <td className="px-6 py-4 font-medium text-gray-900">
                  {asset.asset_name}
                </td>
                <td className="px-6 py-4">{asset.is_appliance ? "Yes" : "No"}</td>
                <td className="px-6 py-4">
                  <span className={`px-2 py-1 text-xs font-semibold rounded-full ${getStatusStyle(asset.status)}`}>
                    {asset.status.toUpperCase()}
                  </span>
                </td>
                <td className="px-6 py-4">{asset.location}</td>
                <td className="px-6 py-4">
                  {dayjs(asset.purchase_date).format('YYYY-MM-DD')}
                </td>
                <td className="px-6 py-4">
                    ₹{calculateDepreciationValue(asset)}
                </td>
                <td className="px-6 py-4">
                  <div className="flex gap-2">
                    <Tooltip title="Print">
                      <IconButton onClick={(e) => handlePrintAsset(asset, e)} size="small">
                        <PrintIcon className="w-5 h-5" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit">
                      <IconButton onClick={(e) => handleEditClick(asset, e)} size="small">
                        <EditIcon className="w-5 h-5" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton onClick={(e) => handleDeleteClick(asset, e)} size="small">
                        <DeleteIcon className="w-5 h-5" />
                      </IconButton>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Asset Details Modal */}
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="asset-details-modal"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          maxHeight: '80vh',
          overflowY: 'auto',
        }}>
          {selectedAsset && (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6" component="h2">
                  Asset Details
                </Typography>
                <IconButton onClick={() => setIsModalOpen(false)}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Typography component="div" className="space-y-2">
                <p><strong>Asset Name:</strong> {selectedAsset.asset_name}</p>
                <p><strong>Status:</strong> {selectedAsset.status}</p>
                <p><strong>Location:</strong> {selectedAsset.location}</p>
                <p><strong>Manufacturer:</strong> {selectedAsset.manufacturer}</p>
                <p><strong>Serial Number:</strong> {selectedAsset.serial_number}</p>
                <p><strong>Model:</strong> {selectedAsset.model}</p>
                <p><strong>Purchase Date:</strong> {dayjs(selectedAsset.purchase_date).format('YYYY-MM-DD')}</p>
                <p><strong>Purchase Cost:</strong> ₹{selectedAsset.purchase_cost}</p>
                <p><strong>Current Value:</strong> ₹{calculateDepreciationValue(selectedAsset)}</p>
                <p><strong>Depreciation Rate:</strong> {selectedAsset.depreciation_rate}%</p>
                <p><strong>Department:</strong> {selectedAsset.AssetDepartments?.map((dept: any) => dept?.AssociatedDepartment.department_name).join(', ')}</p>
                <p><strong>Reminder Frequency:</strong> {selectedAsset.reminder_frequency} days</p>
              </Typography>
            </>
          )}
        </Box>
      </Modal>

      {/* Edit Modal */}
      <Modal
        open={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        aria-labelledby="edit-asset-modal"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxWidth: '800px',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          maxHeight: '90vh',
          overflowY: 'auto',
        }}>
          <AddAsset
            onClose={() => setIsEditModalOpen(false)}
            isEditMode={true}
            editData={selectedAsset}
          />
        </Box>
      </Modal>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this asset?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDelete} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AssetDashboard; 