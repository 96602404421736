import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { ViewStates } from '../../types/teamTypes';

interface NavigationProps {
  onViewChange: (newView: Partial<ViewStates>) => void;
}

const TeamPageNavigation: React.FC<NavigationProps> = ({ onViewChange }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        onViewChange({});
        break;
      case 1:
        onViewChange({ addMember: true });
        break;
      case 2:
        onViewChange({ performanceView: true });
        break;
      case 3:
        onViewChange({ rating: true });
        break;
      case 4:
        onViewChange({ permissionView: true });
        break;
      case 5:
        onViewChange({ manageResponsibility: true });
        break;
      default:
        onViewChange({});
    }
  };

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper',borderBottom: 1, borderColor: 'divider'}}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="team page navigation tabs"
      >
        <Tab label="Dashboard" />
        {/* <Tab label="Schedule Inspection" /> */}
        <Tab label="Add Member" />
        <Tab label="Performance" />
        <Tab label="Rating" />
        <Tab label="Grant Permission" />
        <Tab label="Manage Responsibility" />
      </Tabs>
    </Box>
  );
};

export default TeamPageNavigation;