import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, shallowEqual } from 'react-redux';
import { Input } from '../common/Input';
import { Select } from '../common/Select';
import Textarea from '../common/TextArea';
import Multiselect from 'multiselect-react-dropdown'; // Ensure this is installed

import { selectUserData } from '../../store/store/authSlice';
import { reportIssue } from '../../api/endpoints/APIEndpoints';
import { convertToBase64 } from '../../utils/common'; // Ensure this utility function is available
import imageCompression from 'browser-image-compression';

interface ReportIssueFormProps {
    onSubmit: (data: any) => void;
    departmentOptions: any,
    toDepartmentOptions: any
}

const ReportIssueForm: React.FC<ReportIssueFormProps> = ({ onSubmit, departmentOptions, toDepartmentOptions }) => {
    const userData = useSelector(selectUserData, shallowEqual);
    const [imageBase64, setImageBase64] = useState<string | null>(null);
    const { control, handleSubmit, setValue, watch } = useForm({
        defaultValues: {
            name: '',
            tagNumber: '',
            issueType: '',
            description: '',
            priority: 'Medium',
            location: '',
            photographicEvidence: null as File | null,
            fromDepartment: '',
            targetDepartments: []
        }
    });

    const compressImage = async (file: File): Promise<File> => {
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        };

        try {
            return await imageCompression(file, options);
        } catch (error) {
            console.error("Error compressing image:", error);
            return file;
        }
    };

    const onFormSubmit = handleSubmit(async (data) => {
        
        const payload = {
            tag_number: data.tagNumber,
            name: data.name,
            description: data.description,
            type: data.issueType,
            raisedDate: new Date().toISOString().split('T')[0],
            priority: data.priority,
            location: data.location,
            reported_by: userData?.employee_id,
            raised_by_department_id: Number(data.fromDepartment),
            target_department_ids: data.targetDepartments.map((department: any) => department.id),
            photoEvidence: imageBase64 // Include the base64 image
        };

        try {
            const res = await reportIssue.post(payload);
            console.log("issue reported", res);
            onSubmit(res); // Assuming onSubmit is the correct function to call
        } catch (error) {
            console.error("Error reporting issue:", error);
        }
    });

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const compressedFile = await compressImage(file);
            setValue('photographicEvidence', compressedFile);
            const base64 = await convertToBase64(compressedFile);
            setImageBase64(base64);
        }
    };

    const multiSelectFormattedDepartmentOptions = toDepartmentOptions?.map((item: any) => ({
        name: item.label,
        id: item.value
    }))

    return (
        <div className="bg-white p-6 rounded-lg">
            <h2 className="text-2xl font-bold mb-4">Report New Issue</h2>
            <form onSubmit={onFormSubmit} className='grid grid-cols-2 gap-4'>
                <Controller
                    name="name"
                    control={control}
                    rules={{ required: 'Issue Name is required' }}
                    render={({ field }) => <Input label="Issue Name" {...field} />}
                />

                <Controller
                    name="tagNumber"
                    control={control}
                    rules={{ required: 'Tag Number is required' }}
                    render={({ field }) => <Input label="Tag Number" {...field} />}
                />

                <Controller
                    name="issueType"
                    control={control}
                    rules={{ required: 'Issue Type is required' }}
                    render={({ field }) => (
                        <Select
                            label="Issue Type"
                            options={[
                                { value: "Missing", label: "Missing" },
                                { value: "Damage", label: "Damage" },
                                { value: "Faulty", label: "Faulty" },
                                { value: "Miscellaneous", label: "Miscellaneous" },
                            ]}
                            {...field}
                        />
                    )}
                />

                <div className="mb-4">
                    <label className="text-left block text-sm text-gray-700 cursor-pointer">
                        Photographic Evidence
                    </label>
                    <input
                        type="file"
                        onChange={handleFileChange}
                        accept="image/*"
                        className="mt-1 block w-full text-sm text-gray-500
                        file:mr-4 file:py-2 file:px-4
                        file:rounded-full file:border-0
                        file:text-sm file:font-semibold
                        file:bg-blue-50 file:text-blue-700
                        hover:file:bg-blue-100"
                    />
                </div>

                <Controller
                    name="description"
                    control={control}
                    render={({ field }) => <Textarea label="Issue Description" {...field} />}
                />

                <Controller
                    name="priority"
                    control={control}
                    rules={{ required: 'Priority is required' }}
                    render={({ field }) => (
                        <Select
                            label="Priority"
                            options={[
                                { value: "Low", label: "Low" },
                                { value: "Medium", label: "Medium" },
                                { value: "High", label: "High" },
                            ]}
                            {...field}
                        />
                    )}
                />

                <Controller
                    name="fromDepartment"
                    control={control}
                    rules={{ required: 'From Department is required' }}
                    render={({ field }) => (
                        <Select
                            label="From Department"
                            options={departmentOptions} // Ensure this is defined
                            {...field}
                        />
                    )}
                />

                <div className='mb-2'>
                    <p className='text-left text-sm pb-2 text-gray-800'>Target Department(s)</p>
                    <Controller
                        name="targetDepartments"
                        control={control}
                        render={({ field }) => (
                            <Multiselect
                                options={multiSelectFormattedDepartmentOptions} // Ensure this is defined
                                selectedValues={field.value}
                                onSelect={(selectedList) => field.onChange(selectedList)}
                                onRemove={(selectedList) => field.onChange(selectedList)}
                                displayValue="name"
                            />
                        )}
                    />
                </div>

                <Controller
                    name="location"
                    control={control}
                    rules={{ required: 'Location is required' }}
                    render={({ field }) => <Input label="Location" {...field} />}
                />
                <div></div>
                <div></div>
                <div className="col-span-2 flex justify-end mt-4">
                    <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded">Submit</button>
                </div>
            </form>
        </div>
    );
};

export default ReportIssueForm;