import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

interface ApplianceBarChartProps {
  data: any;
}

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          padding: '10px',
          borderRadius: '4px',
        }}
      >
        <Typography variant="body2">{label}</Typography>
        {payload.map((pld: any) => (
          <Typography key={pld.dataKey} variant="body2" sx={{ color: pld.fill }}>
            {`${pld.name}: ${pld.value}`}
          </Typography>
        ))}
      </Box>
    );
  }
  return null;
};

const ApplianceBarGraph: React.FC<ApplianceBarChartProps> = ({ data }) => {
  // Process appliance data to get daily counts
  const processApplianceData = () => {
    const today = new Date();
    const chartData = [];
    
    // Create data for the last 7 days
    for (let i = 6; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(date.getDate() - i);
      const dateStr = date.toLocaleDateString('en-GB', { 
        day: '2-digit', 
        month: '2-digit'
      });

      // Count appliances for this date
      // Note: You might need to adjust this based on your actual data structure
      console.log("data", data)
      const registered = data.appliances.filter((app: { appliance_status: string; }) => 
        app.appliance_status === 'Active'
      ).length;

      const inactive = data.appliances.filter((app: { appliance_status: string; }) => 
        app.appliance_status !== 'Active'
      ).length;

      chartData.push({
        date: dateStr,
        Registered: registered,
        Inactive: inactive
      });
    }

    return chartData;
  };

  const chartData = processApplianceData();
  const totalAppliances = data.appliances.length;
  const activeAppliances = data.appliances.filter((app: { appliance_status: string; }) => app.appliance_status === 'Active').length;
  const percentageActive = ((activeAppliances / totalAppliances) * 100).toFixed(1);

  return (
    <Card sx={{ maxWidth: 800, margin: 'auto', mt: 4, height: 420 }}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <p>Total Appliances</p>
          <Box>
            <Typography variant="h4" component="div" fontWeight="bold">
              {totalAppliances}
            </Typography>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <ArrowUpwardIcon color="success" fontSize="small" />
              <Typography variant="body2" color="success.main">
                {percentageActive}% active
              </Typography>
            </Box>
          </Box>
        </Box>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={chartData}>
            <XAxis
              dataKey="date"
              axisLine={{ stroke: '#E0E0E0' }}
              tickLine={{ stroke: '#E0E0E0' }}
              tick={{ fill: '#9E9E9E', fontSize: 12 }}
            />
            <YAxis
              axisLine={{ stroke: '#E0E0E0' }}
              tickLine={{ stroke: '#E0E0E0' }}
              tick={{ fill: '#9E9E9E', fontSize: 12 }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Bar
              dataKey="Registered"
              fill="#33B864"
              animationBegin={0}
              animationDuration={2000}
            />
            <Bar
              dataKey="Inactive"
              fill="#CE2029"
              animationBegin={500}
              animationDuration={2000}
            />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default ApplianceBarGraph;