import React, { useState } from 'react';
import { InspectionRoutine } from '../../types/common';
import { Input } from '../common/Input';
import { Select } from '../common/Select';
import { addInspectionRoutines } from '../../api/endpoints/APIEndpoints';
import { toast } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner';

interface AddRoutineFormProps {
    projectOptions: { value: string; label: string }[];
    applianceOptions: { value: string; label: string }[];
}

const AddRoutineForm: React.FC<AddRoutineFormProps> = ({ projectOptions, applianceOptions }) => {
    const [frequency, setFrequency] = useState('');
    const [dueDateOffset, setDueDateOffset] = useState('');
    const [routineName, setRoutineName] = useState("");
    const [routineDescription, setRoutineDescription] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isCustomFrequency, setIsCustomFrequency] = useState(false); // New state for custom frequency

    const frequencyOptions = [
        { value: "Weekly", label: "Weekly" },
        { value: "Fortnightly", label: "Fortnightly" },
        { value: "Monthly", label: "Monthly" },
        { value: "Quarterly", label: "Quarterly" },
        { value: "Half-Yearly", label: "Half-Yearly" },
        { value: "Yearly", label: "Yearly" },
        { value: "Custom", label: "Custom" }
    ];

    const dueDateOffsetOptions = [
        { value: "7", label: "7 days from scheduled inspection date" },
        { value: "15", label: "15 days from scheduled inspection date" }
    ];

    const clearForm = () => {
        setFrequency('');
        setDueDateOffset('');
        setRoutineName('');
        setRoutineDescription('');
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);
        if (!frequency || !dueDateOffset || !routineName || !routineDescription) {
            toast.error("Please fill out all fields.");
            setIsSubmitting(false);
            return;
        }

        const newRoutine: any = {
            frequency,
            due_date_offset: parseInt(dueDateOffset),
            routine_name: routineName,
            routine_description: routineDescription
        };

        try {
            const res = await addInspectionRoutines.post(newRoutine);
            console.log("Inspection routine added successfully", res);
            toast.success("Inspection Routine added successfully!");
            clearForm(); 
        } catch (error) {
            console.log("ERROR!!", error);
            toast.error("Failed to add Inspection Routine. Please try again.");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <div className='text-2xl text-left pb-4 font-semibold'>
                Add Inspection Routine
            </div>
            <form onSubmit={handleSubmit}>
                <div className='grid grid-cols-2 gap-x-4 gap-y-4'>
                    <div>
                        <Input
                            label="Routine Name"
                            value={routineName}
                            onChange={(e) => setRoutineName(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <Input
                            label="Routine Description"
                            value={routineDescription}
                            onChange={(e) => setRoutineDescription(e.target.value)}
                            required
                        />
                    </div>

                    <div>
                        <Select
                            label="Frequency"
                            options={frequencyOptions}
                            value={frequency}
                            onChange={(e) => {
                                setFrequency(e.target.value)
                                setIsCustomFrequency(e.target.value === "Custom");
                            }}
                            required
                        />
                    </div>
                    {isCustomFrequency ? ( // Conditional rendering for custom input
                        <div>
                            <Input
                                label="Due Date Offset"
                                value={dueDateOffset}
                                onChange={(e) => setDueDateOffset(e.target.value)}
                                required
                            />
                        </div>
                    ) : (
                        <div>
                            <Select
                                label="Due Date Offset"
                                options={dueDateOffsetOptions}
                                value={dueDateOffset}
                                onChange={(e) => setDueDateOffset(e.target.value)}
                                required
                            />
                        </div>
                    )}
                </div>

                <div className="flex justify-end mt-4">
                    <button 
                        type="submit" 
                        className="bg-blue-500 text-white p-2 rounded flex items-center justify-center min-w-[120px]"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? (
                            <ThreeDots color="#ffffff" height={24} width={24} />
                        ) : (
                            'Add Routine'
                        )}
                    </button>
                </div>
            </form>
        </>
    );
};

export default AddRoutineForm;