import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box, Typography, Paper, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import BackButton from '../components/common/BackButton';
import Button from '../components/common/Button';
import CheckListForm from '../components/app/CheckListForm';
import { Appliance } from '../types/tasks';
import { deleteChecklist, fetchAllChecklist, getAllDepartments, getAllProjects } from '../api/endpoints/APIEndpoints';
import { Checklists, Department, Project } from '../types/common';
import Modal from '../components/common/Modal';
import Banner from '../components/common/Banner';
import Accordion from '../components/common/Accordion';
import ConfirmationPopup from '../components/app/ConfirmationPopup';
import { shallowEqual, useSelector } from 'react-redux';
import { selectUserData } from '../store/store/authSlice';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

interface Checklist {
    checklist_id: number;
    name: string;
    description: string;
    checklist_type: 'inspection' | 'maintenance';
}

// Define the props for the ChecklistAccordions component
interface ChecklistAccordionsProps {
    allChecklistData: Checklist[];
    handleChecklistEdit: (checklist: Checklist) => void;
    handleChecklistDelete: (checklist: Checklist) => void;
}

// Define the structure for grouped checklists
interface GroupedChecklists {
    inspection?: Checklist[];
    maintenance?: Checklist[];
}

const StyledTabs = styled(Tabs)({
    borderBottom: '1px solid #e8e8e8',
    '& .MuiTabs-indicator': {
        backgroundColor: '#1890ff',
    },
});

const StyledTab = styled(Tab)({
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '16px',
    '&.Mui-selected': {
        color: '#1890ff',
    },
});

const Checklist: React.FC = () => {
    const [projects, setProjects] = useState<Project[]>()
    const [allDepartment, setAllDepartment] = useState<any>()
    const [allChecklistData, setAllChecklistData] = useState<any>([])
    const [addNewChecklist, setAddNewChecklist] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedChecklist, setSelectedChecklist] = useState<Checklists | null>(null);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [checklistToDelete, setChecklistToDelete] = useState<any>(null);
    const [tabValue, setTabValue] = useState(0);
    const userData = useSelector(selectUserData, shallowEqual);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
        if (newValue === 0) {
            fetchAllChecklist.post({})
                .then(res => setAllChecklistData(res))
                .catch((err) => console.log("ERROR!!", err));
        }
    };

    useEffect(() => {
        fetchAllChecklist.post({})
            .then(res => setAllChecklistData(res))
            .catch((err) => console.log("ERROR!!", err))

        getAllDepartments.get()
            .then((res) => {
                let formattedDepartmentData = res?.map((department: Department) => ({
                    value: department?.department_id,
                    label: department?.department_name
                }))
                setAllDepartment(formattedDepartmentData)

            }).catch(err => console.log("ERROR!!", err))

        getAllProjects.post({ employee_id: userData?.employee_id })
            .then((res) => {
                setProjects(res)
            }).catch(err => console.log("ERROR!!", err))
    }, [])

    const handleChecklistEdit = (checklist: Checklists) => {
        setSelectedChecklist(checklist);
        setShowEditModal(true);
    }

    const handleChecklistDelete = (checklist: Checklists) => {
        deleteChecklist.delete({ checklistId: Number(checklist.checklist_id) })
            .then(res => {
                console.log("checklist deleted", res)
                let updatedChecklistData = allChecklistData?.filter((data: any) => data.checklist_id !== checklist.checklist_id)
                setAllChecklistData(updatedChecklistData)
            })
            .catch((error) => console.log("ERROR!!", error))
    }

    const groupedChecklists: GroupedChecklists = allChecklistData.reduce((acc: GroupedChecklists, checklist: any) => {
        const type = checklist?.checklist_type.toLowerCase() as 'inspection' | 'maintenance';
        if (!acc[type]) {
            acc[type] = [];
        }
        acc[type]!.push(checklist);
        return acc;
    }, {});

    const openConfirmation = (checklist: Checklist) => {
        setChecklistToDelete(checklist);
        setIsConfirmationOpen(true);
    };

    const closeConfirmation = () => {
        setIsConfirmationOpen(false);
        setChecklistToDelete(null);
    };

    const confirmDelete = () => {
        if (checklistToDelete) {
            handleChecklistDelete(checklistToDelete);
        }
        closeConfirmation();
    };

    const handleChecklistClick = (checklist: Checklists) => {
        setSelectedChecklist(checklist);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedChecklist(null);
    };

    const renderChecklistTable = (checklists: any[]) => (
        <table className="min-w-full bg-white text-sm">
            <thead>
                <tr className="border-b text-left font-bold">
                    <th className="p-2">Checklist Id</th>
                    <th className="p-2">Checklist Name</th>
                    <th className="p-2">Department</th>
                    <th className="p-2">Description</th>
                    <th className="p-2">Action</th>
                </tr>
            </thead>
            <tbody>
                {checklists.map((checklist) => (
                    <tr key={checklist.checklist_id} className="border-b text-left">
                        <td
                            className="p-2 cursor-pointer text-blue-600 hover:text-blue-800"
                            onClick={() => handleChecklistClick(checklist)}
                        >
                            {checklist.checklist_id}
                        </td>
                        <td className="p-2">{checklist.name}</td>
                        <td className="p-2">{checklist.department.department_name} </td>
                        <td className="p-2">{checklist.description}</td>
                        <td className="p-2 flex gap-2">
                            <Tooltip title="Edit">
                                <IconButton onClick={() => handleChecklistEdit(checklist)} size="small">
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <IconButton onClick={() => openConfirmation(checklist)} size="small">
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );


    const handleCancel = () => {
        setShowEditModal(false)
    }

    return (
        <div className="w-full p-5 h-[90vh] overflow-y-auto">
            <Typography variant="h4" component="h1" gutterBottom>
                Checklist Management
            </Typography>

            <StyledTabs value={tabValue} onChange={handleTabChange} aria-label="checklist tabs">
                <StyledTab label="Dashboard" />
                <StyledTab label="Add New Checklist" />
            </StyledTabs>

            <Box sx={{ mt: 3 }}>
                {tabValue === 0 && (
                    <>
                        <div className='w-1/3 mb-5'>
                            <Banner title='Total Checklists' count={allChecklistData?.length} />
                        </div>
                        {groupedChecklists.inspection && (
                            <Accordion title="Inspection Checklist" isOpened={true}>
                                {renderChecklistTable(groupedChecklists.inspection)}
                            </Accordion>
                        )}
                        {groupedChecklists.maintenance && (
                            <Accordion title="Maintenance Checklist" isOpened={true}>
                                {renderChecklistTable(groupedChecklists.maintenance)}
                            </Accordion>
                        )}
                    </>
                )}
                {tabValue === 1 && (
                    <CheckListForm />
                )}
            </Box>

            <ConfirmationPopup
                isOpen={isConfirmationOpen}
                onConfirm={confirmDelete}
                onCancel={closeConfirmation}
            />

            <Modal show={showEditModal} onClose={handleCancel}>
                {selectedChecklist && (
                    <div className='h-[50vh] overflow-y-scroll'>
                        <Typography variant="h6" gutterBottom>Edit Checklist</Typography>
                        <CheckListForm selectedChecklist={selectedChecklist} />
                    </div>
                )}
            </Modal>

            {/* Modal for Checklist Details */}
            <Modal
                show={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="checklist-details-modal"
                aria-describedby="modal-modal-description"
            >

                {selectedChecklist && (
                    <>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Checklist Details
                            </Typography>
                            <IconButton
                                aria-label="close"
                                onClick={handleCloseModal}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Typography id="modal-modal-description" component="div">
                            <p><strong>Checklist ID:</strong> {selectedChecklist.checklist_id}</p>
                            <p><strong>Name:</strong> {selectedChecklist.name}</p>
                            <p><strong>Description:</strong> {selectedChecklist.description}</p>
                            <p><strong>Type:</strong> {selectedChecklist.checklist_type}</p>
                            <p><strong>Department:</strong> {selectedChecklist.department.department_name}</p>
                            <p><strong>Questions:</strong>
                                {selectedChecklist?.questions?.map((question: any, index: number) => (
                                    <>
                                        <p key={question.question_id}>{index + 1}. {question.question_text}
                                            <em className='text-gray-500 text-sm'>  [{question.question_type}]</em></p>

                                    </>
                                ))}
                            </p>
                            {/* Add more details as needed */}
                        </Typography>
                    </>
                )}
            </Modal>
        </div>
    );
};

export default Checklist;
