import React, { useState, useRef, useEffect } from 'react';
import { FaBars } from 'react-icons/fa';

interface FilterProps {
  label: string;
  options: string[];
  onFilterChange: (selectedOption: string) => void;
  value: string;
  className?: string;
}

const Filter: React.FC<FilterProps> = ({ label, options, onFilterChange, value, className = '' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleFilterChange = (option: string) => {
    onFilterChange(option);
    setIsOpen(false);
  };

  return (
    <div ref={dropdownRef} className={`relative inline-block w-full text-left ${className}`}>
      <button
        className="flex items-center justify-between w-full px-4 py-2 text-left border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center space-x-2">
          <FaBars className="text-gray-500" />
          <span>{value || label}</span>
        </div>
        <svg
          className={`w-5 h-5 text-gray-500 transform ${isOpen ? 'rotate-180' : 'rotate-0'}`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      {isOpen && (
        <ul className="absolute z-10 w-full mt-2 bg-white border border-gray-300 rounded-md shadow-lg">
          {options.map((option) => (
            <li
              key={option}
              className={`px-4 py-2 cursor-pointer hover:bg-gray-100 ${
                value === option ? 'bg-gray-50 font-semibold' : ''
              }`}
              onClick={() => handleFilterChange(option)}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Filter;
