import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { Input } from '../components/common/Input';
import { forgotPassword } from '../api/endpoints/APIEndpoints';

interface ForgotPasswordFormData {
    email: string;
    tenant_id: string;
}

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const { control, handleSubmit, formState: { errors } } = useForm<ForgotPasswordFormData>({
        defaultValues: {
            email: '',
            tenant_id: '',
        },
    });

    const onSubmit = async (data: ForgotPasswordFormData) => {
        setIsLoading(true);
        setError('');
        setMessage('');

        try {
            const response = await forgotPassword.post(data);
            if (response.success) {
                setMessage('Password reset link has been sent to your email');
                setTimeout(() => navigate('/login'), 3000);
            } else {
                throw new Error(response.error || 'Failed to process request');
            }
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Failed to process request');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="font-[sans-serif] relative" style={{ backgroundImage: "linear-gradient(135deg, #B3D9FF 0%, #B3E6E0 100%)" }}>
            <div className='pt-2'>
                <img src="./company_logo.png" alt="error" width="140" />
            </div>
            <div className="px-8 py-12 h-screen">
                <div className='rounded-md absolute top-8 right-8 w-1/2 sm:block hidden'>
                    <img src="./inspection-vector.png" alt="error" style={{ opacity: "0.5" }} />
                </div>
                <div className='flex'>
                    <div className="bg-white rounded-xl sm:px-6 px-4 py-8 max-w-md w-full h-max shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] max-lg:mx-auto">
                        <div className="mb-8 text-left">
                            <h3 className="text-3xl font-extrabold text-gray-800">Forgot Password</h3>
                            <p className="text-gray-600 mt-2">Enter your email and tenant ID to reset your password</p>
                        </div>

                        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: 'Email is required',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: 'Invalid email address'
                                    }
                                }}
                                render={({ field }) => (
                                    <Input
                                        label="Email"
                                        type="email"
                                        placeholder="Enter your email"
                                        error={errors.email?.message}
                                        {...field}
                                    />
                                )}
                            />

                            <Controller
                                name="tenant_id"
                                control={control}
                                rules={{
                                    required: 'Tenant ID is required',
                                    pattern: {
                                        value: /^[a-zA-Z0-9]+$/,
                                        message: 'Tenant ID must be alphanumeric'
                                    }
                                }}
                                render={({ field }) => (
                                    <Input
                                        label="Tenant ID"
                                        type="text"
                                        placeholder="Enter tenant ID"
                                        error={errors.tenant_id?.message}
                                        {...field}
                                    />
                                )}
                            />

                            {message && (
                                <div className="rounded-md bg-green-50 p-4">
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            <svg className="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                        <div className="ml-3">
                                            <p className="text-sm font-medium text-green-800">{message}</p>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {error && (
                                <div className="text-red-500 text-sm mt-2">
                                    {error}
                                </div>
                            )}

                            <button
                                type="submit"
                                disabled={isLoading}
                                className="w-full text-white py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 flex items-center justify-center"
                                style={{ background: "linear-gradient(135deg, rgba(30, 144, 255, 0.8) 0%, rgba(0, 0, 128, 0.8) 100%)" }}
                            >
                                {isLoading ? (
                                    <>
                                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        Processing...
                                    </>
                                ) : 'Reset Password'}
                            </button>

                            <div className="text-sm text-center">
                                <button
                                    type="button"
                                    onClick={() => navigate('/login')}
                                    className="text-blue-600 font-semibold hover:underline"
                                >
                                    Back to Login
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword; 