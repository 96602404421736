import React, { useState, useEffect } from 'react';
import { Task } from '../../types/tasks';
import { Employee } from '../../types/employee';
import { Input } from '../common/Input';
import { Select } from '../common/Select';
import CustomDatePicker from '../common/CustomDatePicker';
import Checkbox from '../common/Checkbox';
import { editInspection } from '../../api/endpoints/APIEndpoints';
import { toast } from 'react-toastify';

interface TaskEditFormProps {
  task: any;
  employees: Employee[];
  onSave: (updatedTask: Task) => void;
  onCancel: () => void;
}

const TaskEditForm: React.FC<TaskEditFormProps> = ({ task, employees, onSave, onCancel }) => {
  const [scheduledDate, setScheduledDate] = useState<Date | null>(task.scheduled_date ? new Date(task.scheduled_date) : null);
  const [dueDate, setDueDate] = useState<Date | null>(task.due_date ? new Date(task.due_date) : null);
  const [taskType, setTaskType] = useState(task.type || '');
  const [isBreakdownMaintenance, setIsBreakdownMaintenance] = useState(task.isbreakdownMaintenance || false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const updatedTask = {
      scheduledDate: scheduledDate,
      dueDate: dueDate,
      type: taskType,
      isbreakdownMaintenance: taskType === 'maintenance_task' ? isBreakdownMaintenance : false,
    };

    editInspection.post({scheduleId: task?.schedule_id, ...updatedTask})
    .then((res) => {
      console.log(res)
      toast.success("Task updated successfully")
      onCancel()
    })
    .catch((err) => {
      toast.error("Error updating task")
      console.log(err)
    })
  };

  return (
    <>
      <div className='text-2xl text-left font-semibold pb-4'>Edit Task</div>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <Select
            label="Task Type"
            options={[
              { value: "inspection_task", label: "Inspection Task" },
              { value: "maintenance_task", label: "Maintenance Task" }
            ]}
            value={taskType}
            onChange={(e) => setTaskType(e.target.value)}
            required
          />
        </div>

        {taskType === "maintenance_task" && (
          <div className='mb-4 flex'>
            <Checkbox
              label="Breakdown Maintenance"
              checked={isBreakdownMaintenance}
              onChange={setIsBreakdownMaintenance}
            />
          </div>
        )}

        <div className="mb-4">
          <p className='text-sm text-left pb-2'>Scheduled Date:</p>
          <CustomDatePicker
            selectedDate={scheduledDate}
            onChange={(date) => setScheduledDate(date)}
          />
        </div>

        <div className="mb-4">
          <p className='text-sm text-left pb-2'>Due Date:</p>
          <CustomDatePicker
            selectedDate={dueDate}
            onChange={(date) => setDueDate(date)}
          />
        </div>

        <div className="flex justify-end">
          <button type="button" onClick={onCancel} className="mr-2 bg-gray-300 text-gray-800 p-2 rounded">
            Cancel
          </button>
          <button type="submit" className="bg-blue-500 text-white p-2 rounded">
            Save
          </button>
        </div>
      </form>
    </>
  );
};

export default TaskEditForm;
