import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, Typography, IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import DocumentDashboard from '../components/app/DocumentDashboard';
import AddDocument from '../components/app/AddDocument';
import UpdateDocument from '../components/app/UpdateDocument';
import { shallowEqual, useSelector } from 'react-redux';
import { selectUserData } from '../store/store/authSlice';
import Loader from '../components/common/Loader';
import { fetchAllDocuments } from '../api/endpoints/APIEndpoints';
import { FaPrint } from 'react-icons/fa';
import * as XLSX from 'xlsx';

const StyledTabs = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: '#1890ff',
  },
});

const StyledTab = styled(Tab)({
  textTransform: 'none',
  fontWeight: 600,
  fontSize: '16px',
  '&.Mui-selected': {
    color: '#1890ff',
  },
});

const Document: React.FC = () => {
  const [tabValue, setTabValue] = useState(0);
  const [documentData, setDocumentData] = useState([]);
  const [departments, setDepartments] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const userDetails = useSelector(selectUserData, shallowEqual);

  useEffect(() => {
    // setIsLoading(true);
    // Fetch documents and departments data here
    // Example:
    fetchAllDocuments.get()
      .then((res) => setDocumentData(res.data))
      .catch((error) => console.log("error", error))
      .finally(() => setIsLoading(false));

    // getAllDepartments()
    //   .then((res) => {
    //     const formattedDepts = res.map(dept => ({
    //       value: dept.department_id,
    //       label: dept.department_name
    //     }));
    //     setDepartments(formattedDepts);
    //   })
    //   .catch(err => console.log("ERROR!!", err))
    //   .finally(() => setIsLoading(false));
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const exportToExcel = () => {
    const excelData = documentData.map((doc: any) => ({
      'Document ID': doc.document_id,
      'Document Name': doc.document_name,
      'Document Description': doc.document_description,
      'Initial Issue Date': doc.initial_issue_date,
      'Renewal Frequency': doc.renewal_frequency,
      'Priority': doc.priority,
      'Additional Requirements': doc.additional_requirements || 'N/A',
      // Current Renewal Details
      'Current Renewal Status': doc.renewal_history?.current_renewal?.status || 'N/A',
      'Current Renewal Due Date': doc.renewal_history?.current_renewal?.due_date 
          ? new Date(doc.renewal_history?.current_renewal?.due_date).toLocaleDateString()
          : 'N/A',
      'Current Renewal Date': doc.renewal_history?.current_renewal?.renewal_date
          ? new Date(doc.renewal_history?.current_renewal?.renewal_date).toLocaleDateString()
          : 'N/A',
      'Reminder Frequency': doc.renewal_history?.current_renewal?.reminder_frequencies || 'N/A',
      'Department ID': doc.department_id,
      'Created At': new Date(doc.created_at).toLocaleDateString(),
      'Updated At': new Date(doc.updated_at).toLocaleDateString(),
    }));

    // Create worksheet
    const ws = XLSX.utils.json_to_sheet(excelData);
    
    // Create workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Documents");
    
    // Generate Excel file and trigger download
    XLSX.writeFile(wb, `Documents_${new Date().toISOString().split('T')[0]}.xlsx`);
  };

  return (
    <div className="container mx-auto p-4 h-[90vh] overflow-y-scroll">
      <div className="flex justify-between items-center mb-6">
        <Box>
          <Typography variant="h4" component="h1" className="text-gray-800">
            Document Management
          </Typography>
          <Typography variant="body1" className="text-gray-600 mt-2">
            Manage your documents, track renewals, and maintain documentation history
          </Typography>
        </Box>
        <Tooltip title="Export to Excel">
          <IconButton 
            onClick={exportToExcel}
            className="bg-blue-500 hover:bg-blue-600 text-white"
            size="large"
          >
            <FaPrint />
          </IconButton>
        </Tooltip>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Box sx={{ width: '100%', mt: 3 }}>
            <StyledTabs value={tabValue} onChange={handleTabChange} aria-label="document tabs">
              <StyledTab label="Dashboard" />
              <StyledTab label="Add Document" />
              <StyledTab label="Update Document" />
            </StyledTabs>
          </Box>
          <Box sx={{ mt: 3 }}>
            {tabValue === 0 && <DocumentDashboard documentData={documentData} />}
            {tabValue === 1 && <AddDocument />}
            {tabValue === 2 && <UpdateDocument documentData={documentData} departmentOptions={departments} />}
          </Box>
        </>
      )}
    </div>
  );
};

export default Document;
