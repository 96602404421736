import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { shallowEqual, useSelector } from 'react-redux';
import { selectAllCompanies, selectAllDepartments } from '../../store/store/mainSlice';
import { addTeam, addProject, addDepartment } from '../../api/endpoints/APIEndpoints';
import { selectUserData } from '../../store/store/authSlice';

interface AddEntityModalProps {
    isOpen: boolean;
    onClose: () => void;
}

interface Company {
    company_id: string;
    company_name: string;
}

interface Department {
    department_id: number;
    department_name: string;
}

const entityTypes = ['Project', 'Team', 'Department', 'Subsidiary'];

const AddEntityModal: React.FC<AddEntityModalProps> = ({ isOpen, onClose }) => {
    const [step, setStep] = useState(0);
    const [entityType, setEntityType] = useState('');
    const [entityName, setEntityName] = useState('');
    const [selectedCompanyId, setSelectedCompanyId] = useState('');
    const [selectedDepartmentId, setSelectedDepartmentId] = useState('');
    const [companies, setCompanies] = useState<Company[]>([]);
    const [departments, setDepartments] = useState<Department[]>([]);
    const departmentData = useSelector(selectAllDepartments, shallowEqual);
    const companyData = useSelector(selectAllCompanies, shallowEqual);
    const userData = useSelector(selectUserData, shallowEqual);

    useEffect(() => {
        setDepartments(departmentData.map((department: any) => ({
            department_id: department.department_id,
            department_name: department.department_name
        })))
        setCompanies(companyData.map((company: any) => ({
            company_id: company.company_id,
            company_name: company.company_name
        })))
    }, [departmentData, companyData])

    useEffect(() => {
        if (isOpen) {
            resetForm();
        }
    }, [isOpen]);

    const resetForm = () => {
        setStep(0);
        setEntityType('');
        setEntityName('');
        setSelectedCompanyId('');
        setSelectedDepartmentId('');
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            let response;
            switch (entityType) {
                case 'Team':
                    response = await addTeam.post({
                        team_name: entityName,
                        department_id: selectedDepartmentId,
                    });
                    console.log("Team Added", response);
                    break;
                case 'Department':
                    response = await addDepartment.post({
                        department_name: entityName,
                        company_id: selectedCompanyId,
                        employee_id: userData?.employee_id
                    });
                    console.log("Department Added", response);
                    break;
                case 'Project':
                    response = await addProject.post({
                        project_name: entityName,
                        department_id: selectedDepartmentId,
                        employee_id: userData?.employee_id
                    });
                    console.log("Project Added", response);
                    break;
                case 'Subsidiary':
                    // response = await addSubsidiary.post({
                    //     subsidiary_name: entityName,
                    // });
                    // console.log("Subsidiary Added", response);
                    break;
                default:
                    console.error("Unknown entity type");
            }
        } catch (error) {
            console.error(`Error Adding ${entityType}`, error);
        }
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-8 max-w-md w-full">
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-2xl font-bold">Add New Entity</h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                        <X size={24} />
                    </button>
                </div>
                <form onSubmit={handleSubmit}>
                    {step === 0 && (
                        <div>
                            <h3 className="text-lg font-semibold mb-4">Select Entity Type</h3>
                            <div className="grid grid-cols-2 gap-4">
                                {entityTypes.map((type) => (
                                    <button
                                        key={type}
                                        type="button"
                                        onClick={() => {
                                            setEntityType(type);
                                            setStep(1);
                                        }}
                                        className="p-4 border rounded-lg hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    >
                                        {type}
                                    </button>
                                ))}
                            </div>
                        </div>
                    )}
                    {step === 1 && (
                        <div>
                            <h3 className="text-lg font-semibold mb-4">Enter {entityType} Details</h3>
                            <input
                                type="text"
                                value={entityName}
                                onChange={(e) => setEntityName(e.target.value)}
                                className="w-full p-2 border rounded-lg mb-4"
                                placeholder={`Enter ${entityType} name`}
                                required
                            />
                            {entityType === 'Department' && (
                                <select
                                    value={selectedCompanyId}
                                    onChange={(e) => setSelectedCompanyId(e.target.value)}
                                    className="w-full p-2 border rounded-lg mb-4"
                                    required
                                >
                                    <option value="">Select Subsidiary</option>
                                    {companies.map((company) => (
                                        <option key={company.company_id} value={company.company_id}>
                                            {company.company_name}
                                        </option>
                                    ))}
                                </select>
                            )}
                            {(entityType === 'Project' || entityType === 'Team') && (
                                <select
                                    value={selectedDepartmentId}
                                    onChange={(e) => setSelectedDepartmentId(e.target.value)}
                                    className="w-full p-2 border rounded-lg mb-4"
                                    required
                                >
                                    <option value="">Select Department</option>
                                    {departments.map((department) => (
                                        <option key={department.department_id} value={department.department_id}>
                                            {department.department_name}
                                        </option>
                                    ))}
                                </select>
                            )}
                            <div className="flex justify-between">
                                <button
                                    type="button"
                                    onClick={() => setStep(0)}
                                    className="px-4 py-2 text-blue-600 hover:text-blue-800"
                                >
                                    Back
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                                >
                                    Add {entityType}
                                </button>
                            </div>
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};

export default AddEntityModal;