// store/authSlice.ts
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './rootReducer';

const initialState: any = {
  approvalRequests: [],
  allTasksWithLevel: [],
  allDepartments: [],
  allCompanies: [],
  allEmployees: [],
  allTeams: [],
  allRoles: [],
  employeeTaskStatus: [],
  level4AllDepartments: [],
  allIssues: [],
  allTeamTaskStatus: [],
  allAlert: null
};

const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setApprovalRequestsData(state, action) {
      state.approvalRequests = action.payload;
    },
    setAllTasksWithLevel(state, action) {
      state.allTasksWithLevel = action.payload;
    },
    setAllDepartments(state, action) {
      state.allDepartments = action.payload;
    },
    setAllCompanies(state, action) {
      state.allCompanies = action.payload;
    },
    setAllEmployees(state, action) {
      state.allEmployees = action.payload;
    },
    setAllTeams(state, action) {
      state.allTeams = action.payload;
    },
    setAllRoles(state, action) {
      state.allRoles = action.payload;
    },
    setEmployeeTaskStatus(state, action) {
      state.employeeTaskStatus = action.payload;
    },
    setLevel4AllDepartments(state, action) {
      state.level4AllDepartments = action.payload;
    },
    setAllIssues(state, action) {
      state.allIssues = action.payload;
    },
    setAllTeamTaskStatus(state, action) {
      state.allTeamTaskStatus = action.payload;
    },
    setAllAlerts(state, action) {
      state.allAlert = action.payload;
    }
  },
});

export const { setApprovalRequestsData, setAllTasksWithLevel, setAllDepartments, setAllCompanies, setAllEmployees, setAllTeams, setAllRoles, setEmployeeTaskStatus, setLevel4AllDepartments, setAllIssues, setAllTeamTaskStatus, setAllAlerts } = mainSlice.actions;

export const selectApprovalRequestData = (state: RootState) => state.main.approvalRequests;
export const selectAllTasksWithLevel = (state: RootState) => state.main.allTasksWithLevel;
export const selectAllDepartments = (state: RootState) => state.main.allDepartments;
export const selectAllCompanies = (state: RootState) => state.main.allCompanies;
export const selectAllEmployees = (state: RootState) => state.main.allEmployees;
export const selectAllTeams = (state: RootState) => state.main.allTeams;
export const selectAllRoles = (state: RootState) => state.main.allRoles;
export const selectEmployeeTaskStatus = (state: RootState) => state.main.employeeTaskStatus;
export const selectLevel4AllDepartments = (state: RootState) => state.main.level4AllDepartments;
export const selectAllIssues = (state: RootState) => state.main.allIssues;
export const selectAllTeamTaskStatus = (state: RootState) => state.main.allTeamTaskStatus;
export const selectAllAlerts = (state: RootState) => state.main.allAlerts

export default mainSlice.reducer;

