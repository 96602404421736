import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Input } from '../common/Input'; // Adjust import path as needed
import { Select } from '../common/Select';
import TextArea from '../common/TextArea'
import { FaExclamationCircle, FaBell, FaCalendarAlt } from 'react-icons/fa';
import CustomDatePicker from '../common/CustomDatePicker';
import { addAlert, editAlert } from '../../api/endpoints/APIEndpoints';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import Multiselect from 'multiselect-react-dropdown';
import Modal from '../common/Modal';

interface AlertProps {
    departmentOptions: any,
    onClose: any,
    isEditMode?: any,
    editData?: any,
    toDepartmentOptions?: any
}
const AlertAddForm: React.FC<AlertProps> = ({ departmentOptions, onClose, isEditMode, editData, toDepartmentOptions }) => {
    const { control, handleSubmit, setValue, watch, formState: { errors } } = useForm();
    const [startDateTime, setStartDateTime] = useState<Dayjs | null>(null);
    const [endDateTime, setEndDateTime] = useState<Dayjs | null>(null);
    const [selectedValues, setSelectedValues] = useState<any[]>([]);
    const [extendedDateTime, setExtendedDateTime] = useState<Dayjs | null>(null);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [submittedData, setSubmittedData] = useState<any>(null);

    const handleStartDateTimeChange = (newValue: Dayjs | null) => {
        setStartDateTime(newValue);
        console.log(newValue?.format()); // This will log the selected date and time
    };

    const handleEndDateTimeChange = (newValue: Dayjs | null) => {
        setEndDateTime(newValue);
        console.log(newValue?.format()); // This will log the selected date and time
    };

    const handleExtendedDateTime = (newValue: Dayjs | null) => {
        setExtendedDateTime(newValue);
        console.log(newValue?.format()); // This will log the selected date and time
    };

    const multiSelectFormattedDepartmentOptions = toDepartmentOptions?.map((item: any) => ({
        name: item.label,
        id: item.value
    }))

    const onSubmit = (data: any) => {
        console.log(data);
        // Handle form submission

        if (isEditMode) {
            editAlert.post({
                alert_id: editData?.alert_id,
                purpose: data?.purpose,
                end_date_time: extendedDateTime
            }).then((res) => {
                console.log("alert updated!!", res)
                setSubmittedData(data);
                setSuccessModalOpen(true);
                onClose()
            })
                .catch((error) => console.log("ERROR", error))
            return;
        }

        if (!isEditMode) {
            addAlert.post({
                purpose: data?.purpose,
                start_date_time: startDateTime,
                end_date_time: endDateTime,
                precautionary_measures: data?.precautionaryMeasures,
                raised_by_department_id: data?.fromDepartment,
                target_department_ids: selectedValues?.map((item: any) => item.id),
                status: data?.status,
                title: data?.title,
                description: data?.description,
                priority: data?.priority,
                location: data?.location
            }).then((res) => {
                console.log("alert added!!", res)
                setSubmittedData(data);
                setSuccessModalOpen(true);
                onClose()
            })
                .catch((error) => console.log("ERROR", error))
        }

    };

    const statusOptions = [
        { value: 'active', label: 'Active' },
        { value: 'resolved', label: 'Resolved' },
        { value: 'pending', label: 'Pending' },
    ];

    const priorityOptions = [
        { value: 'high', label: 'High' },
        { value: 'medium', label: 'Medium' },
        { value: 'low', label: 'Low' },
    ];

    console.log("selectedDateTime", startDateTime?.format())

    const onSelect = (selectedList: any, selectedItem: any) => {
        setSelectedValues(selectedList);
        console.log('Selected:', selectedList);
    };

    const onRemove = (selectedList: any, removedItem: any) => {
        setSelectedValues(selectedList);
        console.log('Removed:', removedItem);
    };

    useEffect(() => {
        if (isEditMode && editData && departmentOptions) {
            // Set form values
            setValue('title', editData.title);
            setValue('description', editData.alert_description);
            setValue('precautionaryMeasures', editData.precautionary_measures);
            setValue('purpose', editData.purpose);
            setValue('status', editData.status);
            setValue('priority', editData.priority);
            // Find the correct department option
            const fromDepartment = departmentOptions.find(
                (dept: { value: any; }) => dept.value === editData.raised_by_department_id
            );

            // Set the value if found, otherwise set to null or a default value
            setValue('fromDepartment', fromDepartment ? fromDepartment.value : null);

            // Set date-time values
            setStartDateTime(editData.start_date_time ? dayjs(editData.start_date_time) : null);
            setEndDateTime(editData.end_date_time ? dayjs(editData.end_date_time) : null);
            // Set selected departments for the Multiselect
            const selectedDepartments = toDepartmentOptions?.filter(
                (dept: any) => editData.target_department_ids.includes(dept.value)
            ).map((dept: any) => ({ name: dept.label, id: dept.value }));
            setSelectedValues(selectedDepartments || []);
        }
    }, [isEditMode, editData, setValue, departmentOptions, toDepartmentOptions]);

    return (
        <div className="max-w-4xl mx-auto bg-white rounded-lg overflow-hidden">
            <p className='text-sm text-gray-700 font-semibold'>{isEditMode ? "Update Alert Data" : "Create New Alert"}</p>

            <form onSubmit={handleSubmit(onSubmit)} className="p-6">
                <div className="grid grid-cols-2 gap-6">
                    <div className="col-span-2">
                        <Controller
                            name="title"
                            control={control}
                            rules={{ required: 'Title is required' }}
                            render={({ field }) => (
                                <Input
                                    label="Alert Title"
                                    {...field}
                                />
                            )}
                        />
                    </div>
                    <div className="col-span-2">
                        <Controller
                            name="description"
                            control={control}
                            rules={{ required: 'Description is required' }}
                            render={({ field }) => (
                                <TextArea
                                    label="Alert Description"
                                    {...field}
                                />
                            )}
                        />
                    </div>

                    <div className="col-span-2">
                        <Controller
                            name="precautionaryMeasures"
                            control={control}
                            rules={{ required: 'precautionaryMeasures is required' }}
                            render={({ field }) => (
                                <TextArea
                                    label="Precautionary measures"
                                    {...field}
                                />
                            )}
                        />
                    </div>
                    <div>
                        <Controller
                            name="purpose"
                            control={control}
                            rules={{ required: 'Purpose is required' }}
                            render={({ field }) => (
                                <Input
                                    label="Purpose"
                                    {...field}
                                />
                            )}
                        />
                    </div>

                    <div>
                        <Controller
                            name="location"
                            control={control}
                            rules={{ required: 'Location is required' }} // Optional: Add validation if needed
                            render={({ field }) => (
                                <Input
                                    label="Location" 
                                    {...field}
                                />
                            )}
                        />
                    </div>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DateTimePicker']}>
                            <DateTimePicker label="Start date time"
                                value={startDateTime}
                                onChange={handleStartDateTimeChange}
                                minDateTime={dayjs()} // This disables past dates and times
                                disablePast={true}  
                            />
                        </DemoContainer>
                    </LocalizationProvider>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DateTimePicker']}>
                            <DateTimePicker label="End date time"
                                value={endDateTime}
                                onChange={handleEndDateTimeChange}
                                minDateTime={dayjs()} // This disables past dates and times
                                disablePast={true}  
                            />
                        </DemoContainer>
                    </LocalizationProvider>



                    {isEditMode && <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DateTimePicker']}>
                            <DateTimePicker label="Extend date time"
                                value={extendedDateTime}
                                onChange={handleExtendedDateTime}
                            />
                        </DemoContainer>
                    </LocalizationProvider>}
                    <div>
                        <Controller
                            name="status"
                            control={control}
                            rules={{ required: 'Status is required' }}
                            render={({ field }) => (
                                <Select
                                    label="Status"
                                    options={statusOptions}
                                    {...field}
                                />
                            )}
                        />
                    </div>
                    <div>
                        <Controller
                            name="priority"
                            control={control}
                            rules={{ required: 'Priority is required' }}
                            render={({ field }) => (
                                <Select
                                    label="Priority"
                                    options={priorityOptions}
                                    {...field}
                                />
                            )}
                        />
                    </div>
                    <div>
                        <Controller
                            name="fromDepartment"
                            control={control}
                            rules={{ required: 'From Department is required' }}
                            render={({ field }) => (
                                <Select
                                    label="From Department"
                                    options={departmentOptions}
                                   {...field}
                                />
                            )}
                        />
                    </div>
                    <div>
                        <p className='text-left text-sm pb-2 text-gray-800'>Target Department(s)</p>
                        <Multiselect
                            options={toDepartmentOptions?.map((item: any) => ({
                                name: item.label,
                                id: item.value
                            }))}
                            selectedValues={selectedValues}
                            onSelect={(selectedList) => setSelectedValues(selectedList)}
                            onRemove={(selectedList) => setSelectedValues(selectedList)}
                            displayValue="name"
                        />
                    </div>
                </div>
                <div className="mt-8 flex justify-end space-x-4">
                    <button type="button" className="px-6 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 transition-colors"
                        onClick={onClose}>
                        Cancel
                    </button>
                    <button type="submit" className="px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors flex items-center">
                        <FaExclamationCircle className="mr-2" /> {isEditMode ? "Update Alert" : "Create Alert"}
                    </button>
                </div>
            </form>

            <Modal show={successModalOpen} onClose={() => setSuccessModalOpen(false)}>
                <div className="p-6 bg-white rounded-lg">
                    <img
                        src="/success.png"
                        alt="Success"
                        className="mx-auto mb-6 w-24 h-24"
                    />
                    <h3 className="mb-4 text-2xl font-semibold text-green-600">
                        Alert Added Successfully!
                    </h3>
                    <div className="mb-6 text-left bg-gray-50 p-4 rounded-md">
                        <p className="mb-2">
                            <span className="font-semibold">Title:</span> {submittedData?.title}
                        </p>
                        <p className="mb-2">
                            <span className="font-semibold">Purpose:</span> {submittedData?.purpose}
                        </p>
                        <p className="mb-2">
                            <span className="font-semibold">Status:</span> {submittedData?.status}
                        </p>
                        <p className="mb-2">
                            <span className="font-semibold">Priority:</span> {submittedData?.priority}
                        </p>
                        <p className="mb-2">
                            <span className="font-semibold">Start Date:</span> {dayjs(submittedData?.start_date_time).format('YYYY-MM-DD HH:mm:ss')}
                        </p>
                        <p className="mb-2">
                            <span className="font-semibold">End Date:</span> {dayjs(submittedData?.end_date_time).format('YYYY-MM-DD HH:mm:ss')}
                        </p>
                        <p className="mb-2">
                            <span className="font-semibold">Location:</span> {submittedData?.location}
                        </p>
                    </div>
                    <button
                        type="button"
                        className="text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center transition duration-300 ease-in-out transform hover:scale-105"
                        onClick={() => {
                            setSuccessModalOpen(false);
                            onClose();
                        }}
                    >
                        Close
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default AlertAddForm;
