import React, { useState } from 'react';
import { Plus, Minus, Printer } from 'lucide-react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

interface Accordion {
    title: string;
    children?: any;
    isOpened: boolean;
    data?: any[];
    columns?: string[];
}

const Accordion: React.FC<Accordion> = ({ title, children, isOpened = false, data, columns }) => {
  const [isOpen, setIsOpen] = useState(isOpened);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const handlePrint = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!data || !columns) return;

    const doc = new jsPDF();
    
    // Add title
    doc.setFontSize(16);
    doc.text(title, 14, 15);
    
    // Create table
    (doc as any).autoTable({
      head: [columns],
      body: data.map(item => columns.map(col => item[col] || '--')),
      startY: 25,
      styles: { fontSize: 8 },
      headStyles: { fillColor: [66, 66, 66] },
    });

    // Save PDF
    doc.save(`${title.toLowerCase().replace(/\s+/g, '_')}.pdf`);
  };

  return (
    <div className="border rounded-lg shadow-sm mb-4">
      <div 
        className="flex justify-between items-center p-4 cursor-pointer bg-gray-100 hover:bg-gray-200 transition-colors"
        onClick={toggleAccordion}
      >
        <h3 className="text-lg font-semibold">{title}</h3>
        <div className="flex items-center gap-2">
          {data && (
            <button
              onClick={handlePrint}
              className="p-2 hover:bg-gray-300 rounded-full transition-colors"
              title="Print"
            >
              <Printer className="w-5 h-5" />
            </button>
          )}
          {isOpen ? <Minus className="w-6 h-6" /> : <Plus className="w-6 h-6" />}
        </div>
      </div>
      {isOpen && (
        <div className="p-4 max-h-[50vh] overflow-y-auto">
          {children}
        </div>
      )}
    </div>
  );
};

export default Accordion;