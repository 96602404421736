import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import AssetDashboard from '../components/app/AssetDashboard';
import AddAsset from '../components/app/AddAsset';
import { shallowEqual, useSelector } from 'react-redux';
import { selectUserData } from '../store/store/authSlice';
import Loader from '../components/common/Loader';
import { fetchAllAssets } from '../api/endpoints/APIEndpoints';

const StyledTabs = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: '#1890ff',
  },
});

const StyledTab = styled(Tab)({
  textTransform: 'none',
  fontWeight: 600,
  fontSize: '16px',
  '&.Mui-selected': {
    color: '#1890ff',
  },
});

const AssetManagement: React.FC = () => {
  const [tabValue, setTabValue] = useState(0);
  const [assetData, setAssetData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const userDetails = useSelector(selectUserData, shallowEqual);

  useEffect(() => {
    // Fetch assets data here
    // setIsL?oading(true);
    fetchAllAssets.get()
      .then((res) => setAssetData(res.data))
      .catch((error) => console.log("error", error))
      .finally(() => setIsLoading(false));
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <div className="container mx-auto p-4 h-[90vh] overflow-y-scroll">
      <Box className="mb-6">
        <Typography variant="h4" component="h1" className="text-gray-800">
          Asset Management
        </Typography>
        <Typography variant="body1" className="text-gray-600 mt-2">
          Manage your assets, track depreciation, and maintain asset inventory
        </Typography>
      </Box>
      
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Box sx={{ width: '100%', mt: 3 }}>
            <StyledTabs value={tabValue} onChange={handleTabChange} aria-label="asset tabs">
              <StyledTab label="Dashboard" />
              <StyledTab label="Add Asset" />
              <StyledTab label="Asset Worth" />
            </StyledTabs>
          </Box>
          <Box sx={{ mt: 3 }}>
            {tabValue === 0 && <AssetDashboard assetData={assetData} />}
            {tabValue === 1 && <AddAsset />}
            {tabValue === 2 && <div>Asset Worth Component</div>}
          </Box>
        </>
      )}
    </div>
  );
};

export default AssetManagement;
