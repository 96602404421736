// store.ts
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import storageSession from 'redux-persist/lib/storage/session'; // sessionStorage
import { persistReducer, persistStore } from 'redux-persist';
// import userReducer from './slices/userSlice';
import authReducer from './authSlice';
import mainReducer from './mainSlice';
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';

// Combine your reducers
const rootReducer = combineReducers({
  // user: userReducer,
  auth: authReducer,
  main: mainReducer
  // Add other reducers here
});

// Configure persist with sessionStorage
const persistConfig = {
  key: 'root',
  storage: storageSession, // Use sessionStorage instead of localStorage
  whitelist: ['user', 'auth'], // only persist these reducers
  // blacklist: [], // reducers you don't want to persist
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Create persistor
export const persistor = persistStore(store);
