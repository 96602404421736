import React from 'react';
import FlipCard from '../common/FlipCard';

interface FrontContent {
    labels: string;
    [key: string]: any;
}

interface InspectionCardData {
    frontContent: FrontContent;
    backContent: Record<string, number>;
}

const InspectionCard: React.FC<{ data: InspectionCardData[] }> = ({ data }) => {
    const { frontContent, backContent } = data[0];

    const renderFrontContent = (content: FrontContent) => {
        const { labels, ...rest } = content;
        return (
            <div className='bg-gray-800 text-white p-4 rounded-lg w-full h-full'>
                <h2 className="text-xl font-bold mb-4">{labels}</h2>
                {Object.entries(rest).map(([key, value]) => (
                    <div key={key} className="mb-2 text-left">
                        <h3 className="font-semibold">{ key.charAt(0).toUpperCase() + key.slice(1)}</h3>
                        <div className="flex justify-between text-sm">
                            <span>This Month: {value.thisMonth}</span>
                            <span>Previous Month: {value.previousMonth}</span>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const renderBackContent = () => (
        <div className="bg-gray-800 text-white p-4 rounded-lg w-full h-full">
            <h3 className="text-md font-bold mb-4">Inspection Details</h3>
            <div className='text-sm'>
                {Object.entries(backContent).map(([key, value]) => (
                    <p key={key}>{key}: {String(value)}</p>
                ))}
            </div>
        </div>
    );

    return (
        <FlipCard
            frontContent={renderFrontContent(frontContent)}
            backContent={renderBackContent()}
            className='w-80 h-48'
        />
    );
};

export default InspectionCard;
