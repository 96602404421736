import { AxiosInstance, AxiosRequestConfig, AxiosError, InternalAxiosRequestConfig } from "axios";
import axios from 'axios';

type TokenInjectionInterceptor = (
    config: InternalAxiosRequestConfig
  ) => Promise<InternalAxiosRequestConfig>;

type AuthorizationErrorInterceptor = (
  refreshAccessToken: () => Promise<string>,
  axiosInstance: AxiosInstance
) => (error: AxiosError) => Promise<any>;

interface APIInterceptorsType {
  tokenInjectionInterceptor: TokenInjectionInterceptor;
  authorizationErrorInterceptor: AuthorizationErrorInterceptor;
}

const getToken = (): string | null => {
  return typeof window !== 'undefined' ? window.localStorage.getItem("auth_token") : null;
};

const getTenantId = (): string | null => {
  return typeof window !== 'undefined' ? window.localStorage.getItem("tenant_id") : null;
};

const tokenInjectionInterceptor: TokenInjectionInterceptor = async (config) => {
    if (typeof window === 'undefined') return config;
  
    const token = getToken();
    if (token) {
      config.headers.set('Authorization', `Bearer ${token}`);
    }
  
    const tenantId = getTenantId();
    if (tenantId) {
      config.headers.set('X-Tenant-ID', tenantId);
    }
  
    config.headers.set('Accept', 'application/json');
  
    return config;
  };

const authorizationErrorInterceptor: AuthorizationErrorInterceptor = (
  refreshAccessToken,
  axiosInstance
) => async (error) => {
  const originalRequest = error.config as AxiosRequestConfig & { _retry?: boolean };

  if (error.response?.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    try {
      const refreshToken = localStorage.getItem("refresh_token");
      const response = await axiosInstance.post('/auth/refresh', { token: refreshToken });
      const newAccessToken = response.data.accessToken;

      // Update the local storage with the new access token
      localStorage.setItem("auth_token", newAccessToken);
      if (originalRequest.headers) {
        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
      }

      return axiosInstance(originalRequest);
    } catch (err) {
      console.error("Failed to refresh access token:", err);
      // Logout user and redirect to login
      localStorage.removeItem("auth_token");
      localStorage.removeItem("refresh_token");
      window.location.href = '/login'; // Redirect to login
      return Promise.reject(err);
    }
  }
  
  return Promise.reject(error);
};

const APIInterceptors: APIInterceptorsType = {
  tokenInjectionInterceptor,
  authorizationErrorInterceptor,
};

export default APIInterceptors;
