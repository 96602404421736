import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { fetchAllAlerts, getAllDepartments } from '../api/endpoints/APIEndpoints';
import AlertAddForm from '../components/app/AlertAddForm';
import AlertDashboard from '../components/app/AlertDashboard';
import ExtendAlert from '../components/app/ExtendAlert';
import AlertHeader from '../components/app/AlertHeader';
import { shallowEqual, useSelector } from 'react-redux';
import { selectUserData } from '../store/store/authSlice';
import Loader from '../components/common/Loader';

const StyledTabs = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: '#1890ff',
  },
});

const StyledTab = styled(Tab)({
  textTransform: 'none',
  fontWeight: 600,
  fontSize: '16px',
  '&.Mui-selected': {
    color: '#1890ff',
  },
});

const Alert: React.FC = () => {
  const [tabValue, setTabValue] = useState(0);
  const [alertData, setAlertData] = useState([]);
  const [fromDepartment, setFromDepartment] = useState<any>([]);
  const [toDepartment, setToDepartment] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const userDetails = useSelector(selectUserData, shallowEqual);

  useEffect(() => {
    setIsLoading(true);
    
    fetchAllAlerts.post({employee_id: userDetails?.employee_id})
      .then((res) => setAlertData(res.alerts))
      .catch((error) => console.log("error", error))
      .finally(() => setIsLoading(false));

    getAllDepartments.get()
      .then((res) => {
        let formattedDepartmentData = res?.map((department: { 
          department_id: any; 
          department_name: any;
        }) => {
          // For level 4, include all departments
          if (userDetails?.role?.level === 4) {
            return {
              value: department?.department_id,
              label: department?.department_name
            };
          }
          
          // For levels 2 and 3, include only departments the user has access to
          if (userDetails?.role?.level === 2 || userDetails?.role?.level === 3) {
            const hasAccess = userDetails.departments.some(
              (userDept: any) => userDept.department_id === department.department_id
            );
            if (hasAccess) {
              return {
                value: department?.department_id,
                label: department?.department_name
              };
            }
          }
          
          // For other levels, don't include any departments
          return null;
        }).filter(Boolean) ?? []; // Filter out null values
        setFromDepartment(formattedDepartmentData);
        const toDepartmentOptions = res?.map((department: any) => ({
          value: department?.department_id,
          label: department?.department_name
        }));
        setToDepartment(toDepartmentOptions);
      }).catch(err => console.log("ERROR!!", err))
      .finally(() => setIsLoading(false));
  }, []);

  console.log("todep  ", toDepartment)

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <div className="container mx-auto p-4 h-[90vh] overflow-y-scroll">
      <AlertHeader />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Box sx={{ width: '100%', mt: 3 }}>
            <StyledTabs value={tabValue} onChange={handleTabChange} aria-label="alert tabs">
              <StyledTab label="Dashboard" />
              <StyledTab label="Add Alert" />
              <StyledTab label="Extend Alert" />
            </StyledTabs>
          </Box>
          <Box sx={{ mt: 3 }}>
            {tabValue === 0 && <AlertDashboard alertData={alertData} departmentOptions={fromDepartment} toDepartmentOptions={toDepartment} />}
            {tabValue === 1 && <AlertAddForm departmentOptions={fromDepartment} onClose={undefined} toDepartmentOptions={toDepartment} />}
            {tabValue === 2 && <ExtendAlert alertData={alertData.filter((alert: any)=> alert?.status?.toLowerCase() !== 'closed')} departmentOptions={fromDepartment} />}
          </Box>
        </>
      )}
    </div>
  );
};

export default Alert;