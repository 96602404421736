import React, { useState, useEffect, useRef } from 'react';
import { Bell } from 'lucide-react';
import { fetchNotifications } from '../../api/endpoints/APIEndpoints';
import { shallowEqual, useSelector } from 'react-redux';
import { selectUserData } from '../../store/store/authSlice';

interface Notification {
  id: number;
  message: string;
  read?: boolean;
  created_at: string;
}

const NotificationComponent: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const userDetails = useSelector(selectUserData, shallowEqual);
  
  useEffect(() => {
    // Simulating fetching notifications from an API
    fetchNotifications.post({department_id: userDetails?.departments[0]?.department_id})
    .then((res)=> setNotifications(res))
    .catch((error) => console.log("ERROR!!", error))
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const unreadCount: number = notifications.filter(n => !n.read).length;

  const sortedNotifications: Notification[] = [...notifications].sort((a, b) => {
    if (a.read === b.read) {
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    }
    return a.read ? 1 : -1;
  });

  const markAsRead = (id: number): void => {
    setNotifications(notifications.map(n => 
      n.id === id ? { ...n, read: true } : n
    ));
  };

  const toggleDropdown = (): void => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative inline-block" ref={dropdownRef}>
      <div 
        className="cursor-pointer p-2 rounded-full hover:bg-gray-100"
        onClick={toggleDropdown}
      >
        <Bell className="w-6 h-6" />
        {unreadCount > 0 && (
          <span className="absolute top-0 right-0 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
            {unreadCount}
          </span>
        )}
      </div>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-80 bg-white rounded-md shadow-lg z-10 max-h-96 overflow-y-auto text-left">
          <div className="py-2">
            {sortedNotifications.length > 0 ? (
              sortedNotifications.map((notification: Notification) => (
                <div 
                  key={notification.id} 
                  className={`px-4 py-3 hover:bg-gray-50 cursor-pointer ${notification.read ? 'bg-white' : 'bg-gray-100'}`}
                  onClick={() => markAsRead(notification.id)}
                >
                  <p className="text-sm">{notification.message}</p>
                  {/* <p className="text-xs text-gray-500 mt-1">
                    {new Date(notification.created_at).toLocaleString()}
                  </p> */}
                </div>
              ))
            ) : (
              <div className="px-4 py-3 text-sm text-gray-500">No notifications</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationComponent;