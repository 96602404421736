import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Input } from '../components/common/Input';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { login } from '../api/endpoints/APIEndpoints';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setIsAuthenticated, setUserData } from '../store/store/authSlice';
import {QRCodeSVG} from 'qrcode.react';

interface LoginFormData {
    email: string;
    password: string;
    tenantId: string;
}

export const Login: React.FC = () => {
    const { control, handleSubmit, formState: { errors } } = useForm<LoginFormData>({
        defaultValues: {
            email: '',
            password: '',
            tenantId: '',
        },
    });

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const onSubmit = (data: LoginFormData) => {
        setIsLoading(true);
        setError(null);
        login.post({
            email: data.email,
            password: data.password,
            tenantId: data.tenantId
        })
            .then((res) => {
                localStorage.setItem("auth_token", res.accessToken);
                localStorage.setItem("refresh_token", res.refreshToken);
                localStorage.setItem("tenant_id", data.tenantId);
                dispatch(setIsAuthenticated(true))
                dispatch(setUserData(res.user))
                navigate('/')
            })
            .catch((error) => {
                console.error("Login error:", error);
                setError("Failed to login. Please check your credentials and try again.");
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const downloadUrl = "https://apkbucket1326.s3.ap-south-1.amazonaws.com/spectent-app.apk";

    return (
        <div className="font-[sans-serif] relative" style={{ backgroundImage: "linear-gradient(135deg, #B3D9FF 0%, #B3E6E0 100%)" }}>
            <div className='pt-2' >
                <img src="./company_logo.png" alt="error" width="140" />
            </div>
            <div className="absolute right-24 top-0 mt-8">
                <div className="text-center">
                    <p className="text-gray-800 mb-2 pl-20">Scan the QR code to download the app:</p>
                    <div className='pl-60'><QRCodeSVG value={downloadUrl} size={128} /></div>
                </div>
            </div>
            <div className="px-8 py-12 " >
                <div className='rounded-md absolute top-8 right-8 w-1/2 sm:block hidden'>
                    <img src="./inspection-vector.png" alt="error" style={{ opacity: "0.5" }} />
                </div>
                <div className='flex'>

                    <div className="bg-white rounded-xl sm:px-6 px-4 py-8 max-w-md w-full h-max shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] max-lg:mx-auto">

                        <div className="mb-8 text-left">
                            <h3 className="text-3xl font-extrabold text-gray-800">Sign in</h3>
                        </div>

                        <button type="button" className="flex py-2.5  px-4 text-sm font-semibold rounded-md text-blue-500 bg-blue-100 hover:bg-blue-200 focus:outline-none self-left">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20px" className="inline mr-4" viewBox="0 0 512 512">
                                <path fill="#fbbd00"
                                    d="M120 256c0-25.367 6.989-49.13 19.131-69.477v-86.308H52.823C18.568 144.703 0 198.922 0 256s18.568 111.297 52.823 155.785h86.308v-86.308C126.989 305.13 120 281.367 120 256z"
                                    data-original="#fbbd00" />
                                <path fill="#0f9d58"
                                    d="m256 392-60 60 60 60c57.079 0 111.297-18.568 155.785-52.823v-86.216h-86.216C305.044 385.147 281.181 392 256 392z"
                                    data-original="#0f9d58" />
                                <path fill="#31aa52"
                                    d="m139.131 325.477-86.308 86.308a260.085 260.085 0 0 0 22.158 25.235C123.333 485.371 187.62 512 256 512V392c-49.624 0-93.117-26.72-116.869-66.523z"
                                    data-original="#31aa52" />
                                <path fill="#3c79e6"
                                    d="M512 256a258.24 258.24 0 0 0-4.192-46.377l-2.251-12.299H256v120h121.452a135.385 135.385 0 0 1-51.884 55.638l86.216 86.216a260.085 260.085 0 0 0 25.235-22.158C485.371 388.667 512 324.38 512 256z"
                                    data-original="#3c79e6" />
                                <path fill="#cf2d48"
                                    d="m352.167 159.833 10.606 10.606 84.853-84.852-10.606-10.606C388.668 26.629 324.381 0 256 0l-60 60 60 60c36.326 0 70.479 14.146 96.167 39.833z"
                                    data-original="#cf2d48" />
                                <path fill="#eb4132"
                                    d="M256 120V0C187.62 0 123.333 26.629 74.98 74.98a259.849 259.849 0 0 0-22.158 25.235l86.308 86.308C162.883 146.72 206.376 120 256 120z"
                                    data-original="#eb4132" />
                            </svg>
                            Sign in with Google
                        </button>
                        <form onSubmit={handleSubmit(onSubmit)} className="max-w-md mx-auto mt-8 bg-white rounded-lg">
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: 'Email is required',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: 'Invalid email address'
                                    }
                                }}
                                render={({ field }) => (
                                    <Input
                                        label="Email"
                                        type="email"
                                        placeholder='Enter email'
                                        error={errors.email?.message}
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                name="tenantId"
                                control={control}
                                rules={{
                                    required: 'Tenant ID is required',
                                    pattern: {
                                        value: /^[a-zA-Z0-9]+$/,
                                        message: 'Tenant ID must be alphanumeric'
                                    }
                                }}
                                render={({ field }) => (
                                    <Input
                                        label="Tenant ID"
                                        type="text"
                                        placeholder='Enter tenant ID'
                                        error={errors.tenantId?.message}
                                        {...field}
                                    />
                                )}
                            />
                            <div className="relative mb-4">
                                <Controller
                                    name="password"
                                    control={control}
                                    rules={{ required: 'Password is required' }}
                                    render={({ field }) => (
                                        <div className="relative">
                                            <Input
                                                label="Password"
                                                type={passwordVisible ? "text" : "password"}
                                                error={errors.password?.message}
                                                placeholder='Enter password'
                                                {...field}
                                            />
                                            <div
                                                className="absolute inset-y-0 right-0 top-[45%] pr-3 flex items-center cursor-pointer"
                                                onClick={togglePasswordVisibility}
                                            >
                                                {passwordVisible ? <FaEye /> : <FaEyeSlash />}
                                            </div>
                                        </div>
                                    )}
                                />
                                <div className="mt-4 text-right">
                                    <a className="text-blue-600 text-sm font-semibold hover:underline cursor-pointer" onClick={() => navigate("/forgot-password")}>
                                        Forgot your password?
                                    </a>
                                </div>
                            </div>
                            {error && (
                                <div className="text-red-500 text-sm mt-2 mb-4">
                                    {error}
                                </div>
                            )}
                            <button
                                type="submit"
                                className="w-full  text-white py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 mt-8 flex items-center justify-center"
                                disabled={isLoading}
                                style={{ background: "linear-gradient(135deg, rgba(30, 144, 255, 0.8) 0%, rgba(0, 0, 128, 0.8) 100%)" }}
                            >
                                {isLoading ? (
                                    <>
                                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        Logging in...
                                    </>
                                ) : 'Login'}
                            </button>
                            <p className="text-sm mt-8 text-center text-gray-800">Don't have an account
                                <a href="javascript:void(0);" className="text-blue-600 font-semibold hover:underline ml-1 whitespace-nowrap" onClick={() => navigate("/register")}>Register here</a>
                            </p>
                        </form>
                    </div>

                </div>

            </div>
            
        </div>
    );
};
