import React, { useEffect, useState } from 'react';
import { User } from 'lucide-react';
import { JSX } from 'react/jsx-runtime';
import { shallowEqual } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectAllTeamTaskStatus } from '../../store/store/mainSlice';
import { convertPercentageToFiveScale } from '../../utils/common';

interface TaskSummary {
  label: string;
  count: number;
  icon: React.ReactNode;
}

interface EmployeePerformance {
  id: string;
  name: string;
  completedTasks: number;
  icon: React.ReactNode;
  totalTasks: number;
}

interface RightPanelProps {
  taskSummaries: TaskSummary[];
  topPerformers: any;
  userLevel?: number; // Make userLevel optional
}

const TaskSummaryCard: React.FC<TaskSummary> = ({ label, count, icon }) => (
  <div className="bg-blue-100 rounded-xl p-4 flex flex-col items-center">
    <div className="text-gray-600 mb-2">{icon}</div>
    <p className="font-semibold">{label}</p>
    <p className="font-bold mt-2">{count ?? 0}</p>
  </div>
);

const EmployeePerformanceItem: React.FC<EmployeePerformance & { monthYear: string }> =
  ({ name, completedTasks, icon, monthYear, totalTasks }) => {
    const calculateRating = (completedTasks: number, totalTasks: number) => {
      const rating = Math.round((completedTasks / totalTasks) * 100);
      return convertPercentageToFiveScale(rating);
    }

    return (
      <div className="flex items-center justify-between py-3 px-4 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow">
        <div className="flex items-center">
          <div className="bg-gray-100 rounded-full p-2 mr-3">
            <User className="w-5 h-5 text-gray-600" />
          </div>
          <div>
            <p className="font-semibold text-gray-800">{name}</p>
          </div>
        </div>
        <div className="flex flex-col items-end">
          <p className="font-bold text-green-500 text-lg">
            {calculateRating(completedTasks, totalTasks)+ "/5"}
          </p>
        </div>
      </div>
    )
  };

const RightPanel: React.FC<RightPanelProps> = ({ taskSummaries, topPerformers, userLevel }) => {
  // Ensure taskSummaries always has 4 items
  const [defaultSummaries, setDefaultSummaries] = useState<TaskSummary[]>([
    { label: 'Total Tasks', count: 0, icon: <span>📋</span> },
    { label: 'Completed', count: 0, icon: <span>✅</span> },
    { label: 'Follow Up', count: 0, icon: <span>🔍</span> },
    { label: 'Pending', count: 0, icon: <span>⏳</span> }
  ]);

  const allTeamTaskStatus = useSelector(selectAllTeamTaskStatus, shallowEqual);
  console.log("top per", topPerformers)

  useEffect(() => {
    if (allTeamTaskStatus && allTeamTaskStatus.length > 0) {
      // Calculate totals across all team members
      const totals = allTeamTaskStatus.reduce((acc: any, member: any) => {
        return {
          completed: acc.completed + member.taskStatus.completed,
          scheduled: acc.scheduled + member.taskStatus.scheduled,
          pastDue: acc.pastDue + member.taskStatus.pastDue,
          due: acc.due + member.taskStatus.due,
          followUp: acc.followUp + member.taskStatus.followUp
        };
      }, {
        completed: 0,
        scheduled: 0,
        pastDue: 0,
        due: 0,
        followUp: 0
      });

      // Calculate total tasks
      const totalTasks = (Object.values(totals) as number[]).reduce((sum, count) => sum + count, 0);

      // Format the data for display
      const formattedStatus: TaskSummary[] = [
        {
          label: 'Total Tasks',
          count: totalTasks,
          icon: defaultSummaries[0].icon
        },
        {
          label: 'Completed',
          count: totals.completed,
          icon: defaultSummaries[1].icon
        },
        {
          label: 'Follow Up',
          count: totals.followUp,
          icon: defaultSummaries[2].icon
        },

        {
          label: 'Pending',
          count: totals.scheduled - totals.completed,
          icon: defaultSummaries[3].icon
        },
      ];

      setDefaultSummaries(formattedStatus);
    }
  }, [allTeamTaskStatus]);

  const displaySummaries = taskSummaries.length > 0
    ? taskSummaries
    : defaultSummaries;

  // Add this to get formatted current month and year
  const currentMonthYear = new Date().toLocaleDateString('en-US', {
    month: 'short',
    year: 'numeric'
  });

  return (
    <div className="p-6 pt-0 max-w-md mx-auto mt-4">
      <div className="grid grid-cols-2 gap-4 mb-6">
        {displaySummaries.map((summary, index) => (
          <TaskSummaryCard key={index} {...summary} />
        ))}
      </div>

      <div className="bg-gray-50 rounded-xl p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-gray-800">Top Performers</h2>
          <span className="text-sm text-gray-500">{currentMonthYear}</span>
        </div>
        <div className="space-y-3">
          {topPerformers.length > 0 ? (
            topPerformers.map((performer: JSX.IntrinsicAttributes & EmployeePerformance & { monthYear: string; }) => (
              <EmployeePerformanceItem
                key={performer.id}
                {...performer}
                monthYear={currentMonthYear} // Pass to EmployeePerformanceItem
              />
            ))
          ) : (
            <p className="text-center text-gray-500 py-4">No performance data available</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default RightPanel;
