import dayjs from "dayjs";

interface IssueDetails {
  title: string;
  issue_id: string;
  appliance_name: string;
  description: string;
  status: string;
  raised_date: string;
  reported_by: string;
  assigned_to: {
    first_name: string;
    last_name: string;
  };
  resolution: string;
  priority: string;
}

interface Props {
  issueDetails: any;
  onClose: () => void;
}

const IssueDetailsModal: React.FC<Props> = ({ issueDetails, onClose }) => {
  console.log("iss", issueDetails)
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg max-w-2xl w-full">
        <h2 className="text-2xl font-bold mb-6 text-green-600">
          {issueDetails.title}
        </h2>

        <div className="space-y-4">
          <p className="mb-2">
            <span className="font-semibold">Issue ID:</span> {issueDetails.issue_id}
          </p>
          <p className="mb-2">
            <span className="font-semibold">Appliance:</span> {issueDetails.appliance_name}
          </p>
          <p className="mb-2">
            <span className="font-semibold">Description:</span> {issueDetails.description}
          </p>
          <p className="mb-2">
            <span className="font-semibold">Status:</span> {issueDetails.status}
          </p>
          <p className="mb-2">
            <span className="font-semibold">Raised Date:</span> {issueDetails.raised_date}
          </p>
          <p className="mb-2">
            <span className="font-semibold">Reported By:</span> {issueDetails.reportedBy?.first_name + " " + issueDetails.reportedBy?.last_name + " - " + issueDetails.reportedBy?.employee_id}
          </p>
          <p className="mb-2">
            <span className="font-semibold">Resolved By:</span> {issueDetails.resolvedBy?.first_name + " " + issueDetails.resolvedBy?.last_name + " - " + issueDetails.resolvedBy?.employee_id}
          </p>
          <p className="mb-2">
            <span className="font-semibold">Resolved Date:</span> {dayjs(issueDetails.resolved_date).format('YYYY-MM-DD HH:mm:ss')}
          </p>
          <p className="mb-2">
            <span className="font-semibold">Assigned To:</span>{' '}
            {issueDetails.assigned_to
              ? `${issueDetails.assigned_to}`
              : 'Not Assigned'}
          </p>
          <p className="mb-2">
            <span className="font-semibold">Priority:</span> {issueDetails.priority}
          </p>
          {issueDetails.resolution && (
            <p className="mb-2">
              <span className="font-semibold">Resolution:</span> {issueDetails.resolution}
            </p>
          )}
        </div>

        <button
          onClick={onClose}
          className="mt-6 bg-green-600 text-white px-6 py-2 rounded-md hover:bg-green-700"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default IssueDetailsModal; 