import React, { useEffect, useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import Modal from '../common/Modal';
import Textarea from '../common/TextArea';
import Button from '../common/Button';
import { updateQuestion } from '../../api/endpoints/APIEndpoints'; // Assume this API endpoint exists
import { Select } from '../common/Select';
import BackButton from '../../components/common/BackButton';

interface QuestionForm {
  appQuestions: any;
  onDataSend?: any
  category?: string
}

interface Question {
  question_id: number;
  question_text: string;
  question_type: string;
  variant: string;
  category: string;
  maintenance_phase: string;
}

interface QuestionFormProps {
  appQuestions: any;
  onDataSend: (data: any) => void;
  category?: string;
  onSave: (questions: any[]) => void;
  handleQuestionsBackButtonClick: any;
}

const QuestionForm: React.FC<QuestionFormProps> = ({ appQuestions, onDataSend, category, onSave, handleQuestionsBackButtonClick }) => {
  const [questions, setQuestions] = useState<any>(appQuestions || []);
  const [showAddQuestionModal, setShowAddQuestionModal] = useState(false);
  const [showEditQuestionModal, setShowEditQuestionModal] = useState(false);
  const [newQuestion, setNewQuestion] = useState("");
  const [editingQuestion, setEditingQuestion] = useState<Question | null>(null);
  const [phase, setPhase] = useState("");
  const [questionType, setQuestionType] = useState("");

  useEffect(() => {
    setQuestions(appQuestions);
  }, [appQuestions]);

  const handleEdit = (question: Question) => {
    setEditingQuestion(question);
    setShowEditQuestionModal(true);
  };

  const handleUpdateQuestion = async () => {
    if (editingQuestion) {
      try {
        await updateQuestion.post({
          question_id: editingQuestion.question_id,
          question_text: editingQuestion.question_text,
          question_type: editingQuestion.question_type
        });

        setQuestions(questions.map((q: { question_id: number; }) =>
          q.question_id === editingQuestion.question_id ? editingQuestion : q
        ));
        setShowEditQuestionModal(false);
        setEditingQuestion(null);
      } catch (error) {
        console.error("Failed to update question:", error);
        // Handle error (e.g., show error message to user)
      }
    }
  };

  const handleAddQuestion = (e: React.MouseEvent) => {
    e.preventDefault();
    
    if (questions.length === 0) {
      alert('Please add at least one question');
      return;
    }

    onSave(questions);
    onDataSend(questions);
  };

  const handleCancel = () => {
    // Handle cancel logic
    console.log('Cancel changes');
    setShowAddQuestionModal(false)

  };

  const handleBackClick = () => {
    handleQuestionsBackButtonClick()
  };

  return (
    <div style={{ padding: '20px' }} className='h-50vh overflow-y-scroll'>
      <BackButton handleClick={handleBackClick}/>
      {questions?.map((question: Question) => (
        <div
          key={question.question_id}
          className="border border-gray-300 rounded-lg p-4 mb-3 relative"
        >
          <span
            className="absolute top-2.5 right-2.5 cursor-pointer"
            onClick={() => handleEdit(question)}
          >
            <FaEdit />
          </span>
          <p className="text-left text-lg font-medium text-gray-800 mb-2">
            {question.question_text}
          </p>
          <p className="text-left text-sm text-gray-600">
            Type: {question.question_type}
          </p>
        </div>
      ))}
      <button
        onClick={() => setShowAddQuestionModal(true)}
        type="button"
        style={{
          display: 'block',
          margin: '20px auto',
          padding: '10px 20px',
          backgroundColor: '#007bff',
          color: '#fff',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
        }}
      >
        Add Question
      </button>

      <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '20px' }}>
        <button
          onClick={handleCancel}
          style={{
            padding: '10px 20px',
            backgroundColor: '#6c757d',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          Cancel
        </button>
        <button
          onClick={handleAddQuestion}
          // type="button"
          style={{
            padding: '10px 20px',
            backgroundColor: '#28a745',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          Save
        </button>
      </div>

      <Modal show={showAddQuestionModal} onClose={handleCancel}>
        <>
          <Textarea label={'Question Text'}
            value={newQuestion}
            onChange={(e) => setNewQuestion(e.target.value)} />

          <Select label={'Question Type'} options={[
            { value: 'yes_no', label: 'Yes/No' },
            { value: 'descriptive', label: 'Descriptive' }
          ]} value={questionType} onChange={(e) => setQuestionType(e.target.value)} />

          {
            category === "maintenance" && <Select label={'Maintenance Phase'} options={[
              { label: 'pre', value: ' Pre' },
              { label: 'post', value: ' Post' },
              { label: 'during', value: ' During' }]}
              value={phase}
              onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setPhase(e.target.value)} />
          }

          <Button label={'Add Question'} onClick={function (): void {
            setQuestions([...questions,
            {
              question_text: newQuestion,
              question_type: questionType,
              variant: 'new',
              category: category,
              maintenance_phase: phase || undefined
            }])
            setShowAddQuestionModal(false)
            setNewQuestion('')
          }} />
        </>
      </Modal>

      <Modal show={showEditQuestionModal} onClose={() => setShowEditQuestionModal(false)}>
        {editingQuestion && (
          <>
            <Textarea
              label={'Edit Question'}
              value={editingQuestion.question_text}
              onChange={(e) => setEditingQuestion({ ...editingQuestion, question_text: e.target.value })}
            />
            <Select
              label={'Question Type'}
              options={[
                { value: 'yes_no', label: 'Yes/No' },
                { value: 'descriptive', label: 'Descriptive' }
              ]}
              value={editingQuestion.question_type}
              onChange={(e) => setEditingQuestion({ ...editingQuestion, question_type: e.target.value })}
            />
            <Button
              label={'Update Question'}
              onClick={handleUpdateQuestion}
            />
          </>
        )}
      </Modal>
    </div>
  );
};

export default QuestionForm;
