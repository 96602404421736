import React, { useEffect, useState } from 'react';
import { fetchAssignedTasksForEmployee } from '../api/endpoints/APIEndpoints';
import Features from '../components/app/Features';
import { shallowEqual, useSelector } from 'react-redux';
import { selectUserData } from '../store/store/authSlice';

const Home: React.FC = () => {

    const userDetails = useSelector(selectUserData, shallowEqual);

    // useEffect(() => {
    //     fetchAssignedTasksForEmployee.post({ employeeId: userDetails?.employee_id })
    //         .then((res) => console.log("res", res))
    //         .catch((error) => console.log("ERROR!!", error))
    // }, [])

    return <>
        <div className="relative h-screen">
            <Features/>
        </div>
    </>;
};

export default Home;
