// store/authSlice.ts
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './rootReducer';
import { User } from '../../types/common';

interface AuthState {
  isAuthenticated: boolean;
  token: string | null;
  userData: User | null;
}

const initialState: AuthState = {
  isAuthenticated: false,
  token: null,
  userData: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuthenticated(state, action) {
      state.isAuthenticated = action.payload;
    },
    setUserData(state, action) {
      state.userData = action.payload
    }
  },
});

export const { setIsAuthenticated,setUserData } = authSlice.actions;

export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const selectAuthToken = (state: RootState) => state.auth.token;
export const selectUserData = (state: RootState) => state.auth.userData

export default authSlice.reducer;
