import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import EmployeeTaskStatusDashboard from './EmployeeTaskStatusDashboard';

interface TeamMember {
  employee_id: string;
  first_name: string;
  last_name: string;
  email: string;
  role: string;
}

interface Project {
  project_id: number;
  project_name: string;
}

interface Team {
  team_id: number;
  team_name: string;
  projects: Project[];
  members: TeamMember[];
}

interface TeamMembersProps {
  teams: any;
}

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&.Mui-expanded': {
    minHeight: 56,
  },
}));

const TeamMembers: React.FC<TeamMembersProps> = ({ teams }) => {
  const [expandedPanel, setExpandedPanel] = useState<number | false>(0);

  const handleAccordionChange = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandedPanel(isExpanded ? panel : false);
  };
  
  return (
    <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
      <EmployeeTaskStatusDashboard/>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teams.map((member: any) => (
              <TableRow key={member.employee_id}>
                <TableCell>{`${member.firstName} ${member.lastName}`}</TableCell>
                <TableCell>{member.email}</TableCell>
                <TableCell>
                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    {member.role.roleName}
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TeamMembers;