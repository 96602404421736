import React, { useState } from 'react';
import RightArrow from '../../icons/RightArrow';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AlertAddForm from './AlertAddForm'; // Import the AlertAddForm component
import { Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Printer from '@mui/icons-material/Print';
import jsPDF from 'jspdf';
import dayjs from 'dayjs';

interface AlertDashboardProps {
  alertData: any[];
  toDepartmentOptions: any[]; // Add this prop for department options
  departmentOptions: any[]; // Add this prop for department options
}

const AlertDashboard: React.FC<AlertDashboardProps> = ({ alertData, departmentOptions, toDepartmentOptions }) => {
  const [selectedAlert, setSelectedAlert] = useState<any | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const StatusIcon = ({ status }: { status: string }) => {
    switch (status) {
      case "active":
        return (
          <svg
            className="w-4 h-4 text-yellow-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        );
      case "completed":
        return (
          <svg
            className="w-4 h-4 text-green-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        );
      case "Open":
        return (
          <svg
            className="w-4 h-4 text-gray-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        );
      default:
        return null;
    }
  };

  const getStatusStyle = (status: string) => {
    switch (status.toLowerCase()) {
      case "closed":
        return "text-green-800 bg-green-100";
      case "open":
        return "text-yellow-800 bg-yellow-100";
      default:
        return "text-gray-800 bg-gray-100";
    }
  };

  const getPriorityStyle = (priority: string) => {
    switch (priority) {
      case "high":
        return "text-red-800 bg-red-100";
      case "medium":
        return "text-yellow-800 bg-yellow-100";
      case "low":
        return "text-green-800 bg-green-100";
      default:
        return "text-gray-800 bg-gray-100";
    }
  };

  const handleAlertClick = (alert: any) => {
    setSelectedAlert(alert);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedAlert(null);
  };

  const handleEditClick = (alert: any) => {
    setSelectedAlert(alert);
    setIsEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedAlert(null);
  };

  const handlePrintAlert = (alert: any) => {
    const doc = new jsPDF();

    // Add title
    doc.setFontSize(16);
    doc.text(`Alert Details: ${alert.alert_id}`, 14, 15);

    // Create table
    (doc as any).autoTable({
      head: [['Field', 'Value']],
      body: [
        ['Alert ID', alert.alert_id],
        ['Title', alert.title],
        ['Desription', alert.alert_description],
        ['Precautionary measures', alert.precautionary_measures],
        ['Purpose', alert.purpose],
        ['Status', alert.status],
        ['Raised Date', alert.raised_date],
        ['Raised By', alert.raisedByDepartment.department_name],
        ['Target Department(s)', alert.target_department_ids],
        ['Start Date', `${dayjs(alert.start_date_time).format('YYYY-MM-DD HH:mm:ss')}`],
        ['End Date', `${dayjs(alert.end_date_time).format('YYYY-MM-DD HH:mm:ss')}`],
        ['location', alert.location]
      ],
      startY: 25,
      styles: { fontSize: 8 },
      headStyles: { fillColor: [66, 66, 66] },
    });

    // Save PDF
    doc.save(`alert_${alert.alert_id}.pdf`);
  }

  return (
    <div className="w-full">
      <div className="overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-100">
            <tr>
              <th scope="col" className="px-6 py-3">Alert Number</th>
              <th scope="col" className="px-6 py-3">Purpose of Alert</th>
              <th scope="col" className="px-6 py-3">Status</th>
              <th scope="col" className="px-6 py-3">Priority</th>
              <th scope="col" className="px-6 py-3">Date of Alert</th>
              <th scope="col" className="px-6 py-3">Action</th>
            </tr>
          </thead>
          <tbody>
            {alertData?.map((alert: any) => (
              <tr key={alert.id} className="bg-white border-b hover:bg-gray-50">
                <td
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap cursor-pointer hover:text-blue-600"
                  onClick={() => handleAlertClick(alert)}
                >
                  {alert.alert_id}
                </td>
                <td className="px-6 py-4">
                  <a href="#" className="font-medium text-blue-600 hover:underline">{alert.purpose}</a>
                  <div className="flex items-center mt-2 text-xs gap-2">
                    <span className="bg-orange-200 text-gray-700 px-2 py-1 rounded">{alert.raised_by_department_id || "--"}</span>
                    <RightArrow />
                    <span className="bg-green-200 text-gray-700 px-2 py-1 rounded">{alert.target_department_ids.map((item: any) => item).sort((a: number, b: number) => a - b).join(', ') || "--"}</span>
                  </div>
                </td>
                <td className="px-6 py-4 min-w-[160px]">
                  <div className="flex items-center gap-1 w-full">
                    <StatusIcon status={alert.status} />
                    <span className={`px-2 py-1 text-xs font-semibold rounded-full ${getStatusStyle(alert.status)}`}>{alert.status.toUpperCase()}</span>
                  </div>
                </td>
                <td className="px-6 py-4">
                  <span className={`px-2 py-1 text-xs font-semibold rounded-full ${getPriorityStyle(alert.priority)}`}>{alert.priority.toUpperCase()}</span>
                </td>
                <td className="px-6 py-4">{alert.raised_date}</td>
                <td className="px-6 py-4 text-right">
                  <Tooltip title="Print">
                    <IconButton onClick={() => handlePrintAlert(alert)} size="small">
                      <Printer className="w-5 h-5" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit">
                    <IconButton className={`${(['CLOSED'].includes(alert.status.toUpperCase())) && 'invisible'}`} onClick={() => handleEditClick(alert)} size="small">
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between items-center mt-4 px-6">
        <button className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50">
          Previous
        </button>
        <span className="text-sm text-gray-700">
          Page <span className="font-medium">1</span> of{" "}
          <span className="font-medium">10</span>
        </span>
        <button className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50">
          Next
        </button>
      </div>

      {/* Modal for Alert Details */}
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="alert-details-modal"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          maxHeight: '80vh',
          overflowY: 'auto',
        }}>
          {selectedAlert && (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Alert Details
                </Typography>
                <IconButton
                  aria-label="close"
                  onClick={handleCloseModal}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <Typography id="modal-modal-description" component="div">
                <p><strong>Alert ID:</strong> {selectedAlert.alert_id}</p>
                <p><strong>Purpose:</strong> {selectedAlert.purpose}</p>
                <p><strong>Status:</strong> {selectedAlert.status}</p>
                <p><strong>Priority:</strong> {selectedAlert.priority}</p>
                <p><strong>Raised Date:</strong> {selectedAlert.raised_date}</p>
                <p><strong>End Date:</strong> {selectedAlert.end_date_time}</p>
                <p><strong>Raised By Employee:</strong> {selectedAlert.raisedBy.first_name + " " + selectedAlert.raisedBy.last_name}</p>
                <p><strong>Raised By Department:</strong> {selectedAlert.raised_by_department_id}</p>
                {/* Add more details as needed */}
              </Typography>
            </>
          )}
        </Box>
      </Modal>

      {/* Modal for Editing Alert */}
      <Modal
        open={isEditModalOpen}
        onClose={handleCloseEditModal}
        aria-labelledby="edit-alert-modal"
        aria-describedby="edit-alert-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxWidth: '800px',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          maxHeight: '90vh',
          overflowY: 'auto',
        }}>
          <IconButton
            aria-label="close"
            onClick={handleCloseEditModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="edit-alert-modal" variant="h6" component="h2" sx={{ mb: 2 }}>
            Edit Alert
          </Typography>
          {selectedAlert && (
            <AlertAddForm
              departmentOptions={departmentOptions}
              toDepartmentOptions={toDepartmentOptions}
              onClose={handleCloseEditModal}
              isEditMode={true}
              editData={selectedAlert}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default AlertDashboard;
