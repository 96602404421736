import React, { useState } from 'react';
import { Select } from '../common/Select';
import Button from '../common/Button';

import { addEmployeeToTeam, fetchSelectedEmployeeDetails } from '../../api/endpoints/APIEndpoints';
import { DetailItemProps, Employee } from '../../types/teamTypes';

interface AddMemberProps {
  allDepartmentEmployees: Employee[];
  userDetails: any; // Replace 'any' with the correct type from your auth slice
}

const AddMember: React.FC<AddMemberProps> = ({ allDepartmentEmployees, userDetails }) => {
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [employeeDetails, setEmployeeDetails] = useState<any>();

  const handleMemberSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedEmployee(e.target.value);
    fetchSelectedEmployeeDetails.post({ employeeId: e.target.value })
      .then((res) => setEmployeeDetails(res))
      .catch((error) => console.log("ERROR!!", error));
  };

  const addMemberToTeam = () => {
    addEmployeeToTeam.post({
      employeeId: Number(selectedEmployee),
      teamId: userDetails?.teams[0]?.team_id,
      requestor_id: userDetails?.employee_id,
      approver_id: userDetails?.supervisor_id || userDetails?.employee_id
    }).then((res) => console.log("employee added to team", res))
      .catch((error) => console.log("ERROR!!", error));
  };

  return (
    <>
      <div className='w-2/3'>
        <Select
          label="Select member"
          options={allDepartmentEmployees.map((employee) => ({
            value: employee.employee_id.toString(),
            label: `${employee.first_name} ${employee.last_name}`
          }))}
          value={selectedEmployee}
          onChange={handleMemberSelection}
          required
        />
      </div>

      {selectedEmployee && (
        <div className="bg-white shadow-lg rounded-lg p-6 max-w-md">
          <h2 className="text-2xl font-bold mb-4 text-gray-800">Employee Details</h2>
          <div className="space-y-3">
            <DetailItem label="Name" value={`${employeeDetails?.first_name} ${employeeDetails?.last_name}`} />
            <DetailItem label="Email" value={employeeDetails?.email || ""} />
            <DetailItem label="Role" value={employeeDetails?.role?.role_name || ""} />
            <DetailItem label="Project" value={employeeDetails?.project?.project_name || 'Not Assigned'} />
            <DetailItem label="Department" value={employeeDetails?.department?.department_name || 'Not Assigned'} />
          </div>
        </div>
      )}

      <Button label='Add Member' onClick={addMemberToTeam} className='mt-4' />
    </>
  );
};

const DetailItem: React.FC<DetailItemProps> = ({ label, value }) => (
  <div className="flex flex-col sm:flex-row sm:justify-between">
    <span className="text-gray-600 font-medium">{label}:</span>
    <span className="text-gray-800">{value}</span>
  </div>
);

export default AddMember;