import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import APIInterceptors from "./APIInterceptor";
import { toast } from 'react-toastify';

const axiosInstance: AxiosInstance = axios.create({
baseURL: 'https://spectent-be.onrender.com'
  // baseURL: 'http://localhost:3000',
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const updatedConfig = await APIInterceptors.tokenInjectionInterceptor(config);
    return updatedConfig;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      if (typeof window !== 'undefined') {
        localStorage.setItem("is401", "true");
        const journeyType = localStorage.getItem("journeyType");
        if (journeyType === "ASSISTED") {
          window.location.href = `${window.location.origin}/partner/signin`;
        }
        window.dispatchEvent(new Event('storage'));
      }
    }
    return Promise.reject(error);
  }
);

const refreshAccessToken = async (): Promise<string> => {
  try {
    const response = await axiosInstance.post('/refresh-token', {}, {
      withCredentials: true // Important for sending cookies
    });
    const newToken = response.data.accessToken;
    localStorage.setItem("auth_token", newToken);
    return newToken;
  } catch (error) {
    console.error("Error refreshing token:", error);
    throw error;
  }
};

APIInterceptors.authorizationErrorInterceptor(refreshAccessToken, axiosInstance);

class APIInterface {
  url: string;
  config: AxiosRequestConfig;

  constructor(url: string, overrideConfig: AxiosRequestConfig = {}) {
    this.url = url;
    this.config = overrideConfig;
  }

  private handleSuccess(response: any) {
    // You can customize this message or extract it from the response if your API provides one
    // toast.success('Operation completed successfully');
    return response;
  }

  private handleError(error: any) {
    let errorMessage = 'An error occurred';
    if (error.response && error.response.data && error.response.data.message) {
      errorMessage = error.response.data.message;
    } else if (error.message) {
      errorMessage = error.message;
    }
    toast.error(errorMessage);
    return Promise.reject(error);
  }

  get(params?: any, config: AxiosRequestConfig = {}): Promise<any> {
    return axiosInstance.get(this.url, { ...this.config, ...config, params })
      .then(this.handleSuccess)
      .catch(this.handleError);
  }

  post(postData: any, config: AxiosRequestConfig = {}): Promise<any> {
    return axiosInstance.post(this.url, postData, { ...this.config, ...config })
      .then(this.handleSuccess)
      .catch(this.handleError);
  }

  patch(patchData: any, config: AxiosRequestConfig = {}): Promise<any> {
    return axiosInstance.patch(this.url, patchData, { ...this.config, ...config })
      .then(this.handleSuccess)
      .catch(this.handleError);
  }

  delete(body: any = {}, config: AxiosRequestConfig = {}): Promise<any> {
    return axiosInstance.delete(this.url, {
      ...this.config,
      ...config,
      data: body  // This allows sending a request body with DELETE
    })
      .then(this.handleSuccess)
      .catch(this.handleError);
  }
}

export default APIInterface;