import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { selectUserData } from '../store/store/authSlice';
import { Team } from '../types/common';
import { selectAllTasksWithLevel } from '../store/store/mainSlice';
import { fetchAllAppliances, fetchAllIssues, fetchAllAlerts, fetchEmployeeByDepartment, fetchTeamMemberInspection, fetchApplianceReport } from '../api/endpoints/APIEndpoints';
import Filter from '../components/common/Filter';
import ReusableTable from '../components/common/ReusableTableComponent';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { utils as XLSXUtils, writeFile } from 'xlsx';
import Multiselect from 'multiselect-react-dropdown';

// Constants
const REPORT_TYPES = {
  INSPECTION: 'Inspection Reports',
  CUSTOM: 'Custom Data Reports',
  FREQUENCY: 'Report Frequency'
} as const;

const INITIAL_VIEW = {
  inspectionReport: "Inspections",
  customDateReport: "",
  reportFrequency: ""
};

interface ReportView {
  inspectionReport: string;
  customDateReport: string;
  reportFrequency: string;
}

// Add new interfaces
interface ApplianceSelection {
  id: string;
  name: string;
  subParts: string[];
}

interface CustomReportFilters {
  appliances: ApplianceSelection[];
  teamMembers: TeamMemberSelection[];
  fromDate: string;
  toDate: string;
}

// Add new interface for team member selection
interface TeamMemberSelection {
  id: string;
  selectedTeamMember: string;
  selectedAppliances: string[];
}

// Add helper function to format date
const formatDate = (dateString: string) => {
  return new Date(dateString).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
};

// Add this type declaration at the top of the file
interface JsPDFWithAutoTable extends jsPDF {
  autoTable: (options: any) => void;
}

// Add this helper function to check if we're in custom reports
const isCustomReport = (view: ReportView) => {
  return view.customDateReport === "Team Member Reports" || 
         view.customDateReport === "Appliance Reports";
};

// Add this helper function to check if we're in inspection reports view
const isInspectionReport = (view: ReportView) => {
  return Boolean(view.inspectionReport) && !view.customDateReport && !view.reportFrequency;
};

// Add frequency options constant
const FREQUENCY_OPTIONS = [
  { value: 'monthly', label: 'Monthly' },
  { value: 'quarterly', label: 'Quarterly' },
  { value: 'semiannually', label: 'Semi-Annually' },
  { value: 'annually', label: 'Annually' }
];

// Add helper function to filter data by frequency
const filterDataByFrequency = (data: any[], frequency: string): any[] => {
  if (!frequency) return data;
  
  const today = new Date();
  const getDateRange = (freq: string) => {
    switch (freq) {
      case 'monthly':
        return 30;
      case 'quarterly':
        return 90;
      case 'semiannually':
        return 180;
      case 'annually':
        return 365;
      default:
        return 0;
    }
  };

  const daysRange = getDateRange(frequency);
  return data.filter(item => {
    const dueDate = new Date(item.inspection_due_date || item.maintenance_due_date);
    const diffTime = Math.abs(dueDate.getTime() - today.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= daysRange;
  });
};

const Reports: React.FC = () => {
  // State
  const [view, setView] = useState<ReportView>({
    inspectionReport: '',
    customDateReport: '',
    reportFrequency: ''
  });
  const [data, setData] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [applianceData, setApplianceData] = useState<Array<{
    value: string;
    label: string;
  }>>([]);
  const [teams, setTeams] = useState<Team>();
  const [customFilters, setCustomFilters] = useState<CustomReportFilters>({
    appliances: [{ id: '1', name: '', subParts: [] }],
    teamMembers: [{ 
      id: '1', 
      selectedTeamMember: '',
      selectedAppliances: [] 
    }],
    fromDate: '',
    toDate: ''
  });

  // Add state for team members data
  const [teamMembersData, setTeamMembersData] = useState<any[]>([]);

  // Add new state for report data and pending export
  const [reportData, setReportData] = useState<any>(null);
  const [pendingExport, setPendingExport] = useState<'pdf' | 'csv' | null>(null);

  // Add new state for filtered data
  const [filteredData, setFilteredData] = useState<any[]>([]);

  // Selectors
  const userDetails = useSelector(selectUserData, shallowEqual);
  const allDepartmentTasks = useSelector(selectAllTasksWithLevel, shallowEqual);

  // Initial load
  useEffect(() => {
    handleInspectionReports("Inspections"); // Load first option by default
  }, []);

  // Handlers
  const handleInspectionReports = useCallback((selectedFilter: string) => {
    // Reset states
    setReportData(null);
    setPendingExport(null);
    setError(null);
    setCustomFilters({
      appliances: [{ id: '1', name: '', subParts: [] }],
      teamMembers: [{ 
        id: '1', 
        selectedTeamMember: '',
        selectedAppliances: [] 
      }],
      fromDate: '',
      toDate: ''
    });

    setView(prev => ({
      ...prev,
      inspectionReport: selectedFilter,
      customDateReport: ""
    }));

    switch (selectedFilter) {
      case "Follow up":
        setColumns([
          { id: 'sl_no', label: 'Sl. No' },
          { id: 'tag_no', label: 'Tag No' },
          { id: 'appliance', label: 'Appliance' },
          { id: 'location', label: 'Location' },
          { id: 'status', label: 'Status' },
          { id: 'follow_up_request', label: 'Follow Up' },
          { id: 'inspection_due_date', label: 'Due Date' }
        ]);
        setData(processFollowUpData(allDepartmentTasks));
        break;
      case "Inspections":
        const columns = [
          { id: 'sl_no', label: 'Sl. No' },
          { id: 'tag_no', label: 'Tag No' },
          { id: 'appliance', label: 'Appliance' },
          { id: 'location', label: 'Location' },
          { id: 'status', label: 'Status' },
          { id: 'inspection_due_date', label: 'Inspection Due Date' }
        ];
        setColumns(columns);

        const inspectionData = allDepartmentTasks
          .filter((task: any) => task.type === "inspection_task")
          .map((data: any, index: number) => ({
            sl_no: index + 1,
            tag_no: data.appliance.tag_id,
            appliance: data.appliance.appliance_name,
            location: data.appliance.appliance_description?.split("Located At: ")[1] || "N/A",
            status: data.status,
            inspection_due_date: data.due_date
          }));
        setData(inspectionData);
        break;

      case "Maintenance":
        setColumns([
          { id: 'sl_no', label: 'Sl. No' },
          { id: 'tag_no', label: 'Tag No' },
          { id: 'appliance', label: 'Appliance' },
          { id: 'location', label: 'Location' },
          { id: 'status', label: 'Status' },
          { id: 'maintenance_due_date', label: 'MaintenanceDue Date' }
        ]);
        const maintenanceData = allDepartmentTasks
          .filter((task: any) => task.type === "maintenance_task") 
          .map((data: any, index: number) => ({
            sl_no: index + 1,
            tag_no: data.appliance.tag_id,
            appliance: data.appliance.appliance_name,
            location: data.appliance.appliance_description?.split("Located At: ")[1] || "N/A",
            status: data.status,
            maintenance_due_date: data.due_date
          }));
        setData(maintenanceData);
        break;

      case "Issues":
        setIsLoading(true);
        const issueColumns = [
          { id: 'issue_id', label: 'Issue ID' },
          { id: 'name', label: 'Name' },
          { id: 'type', label: 'Type' },
          { id: 'raised_date', label: 'Raised Date' },
          { id: 'resolved_date', label: 'Resolved Date' },
          { id: 'status', label: 'Status' },
          { id: 'priority', label: 'Priority' },
          { id: 'location', label: 'Location' }
        ];
        setColumns(issueColumns);

        // Fetch issues data
        fetchAllIssues.post({ employee_id: userDetails?.employee_id })
          .then((response) => {
            const processedIssues = response.issues.map((issue: any) => ({
              issue_id: issue.issue_id,
              name: issue.name,
              type: issue.type,
              raised_date: issue.raised_date,
              resolved_date: issue.resolved_date || "--",
              status: issue.status,
              priority: issue.priority,
              location: issue.location
            }));
            setData(processedIssues);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching issues:", error);
            setError("Failed to fetch issues data");
            setData([]);
            setIsLoading(false);
          });
        break;

      case "Alert":
        setIsLoading(true);
        const alertColumns = [
          { id: 'sl_no', label: 'Sl. No' },
          { id: 'alert_id', label: 'Alert ID' },
          { id: 'from_department', label: 'From Department' },
          { id: 'to_department', label: 'To Department' },
          { id: 'start_date', label: 'Start Date' },
          { id: 'end_date', label: 'End Date' },
          { id: 'status', label: 'Status' },
          { id: 'priority', label: 'Priority' }
        ];
        setColumns(alertColumns);

        // Fetch alerts data using the same API as Alert page
        fetchAllAlerts.post({ employee_id: userDetails?.employee_id })
          .then((response) => {
            const processedAlerts = response.alerts.map((alert: any, index: number) => ({
              sl_no: index + 1,
              alert_id: alert.alert_id,
              from_department: alert.raisedByDepartment?.department_name || 'N/A',
              to_department: 'N/A',
              start_date: alert.start_date_time,
              end_date: alert.end_date_time,
              status: alert.status,
              priority: alert.priority
            }));
            setData(processedAlerts);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching alerts:", error);
            setError("Failed to fetch alerts data");
            setData([]);
            setIsLoading(false);
          });
        break;

      default:
        setColumns([]);
        setData([]);
    }
  }, [userDetails?.employee_id]);

  const handleCustomReports = useCallback((selectedFilter: string) => {
    // Reset states
    setData([]);
    setColumns([]);
    setReportData(null);
    setPendingExport(null);
    setError(null);
    setCustomFilters({
      appliances: [{ id: '1', name: '', subParts: [] }],
      teamMembers: [{ 
        id: '1', 
        selectedTeamMember: '',
        selectedAppliances: [] 
      }],
      fromDate: '',
      toDate: ''
    });

    setView(prev => ({
      ...prev,
      customDateReport: selectedFilter,
      inspectionReport: ""
    }));

    setIsLoading(true);

    if (selectedFilter === "Team Member Reports") {
      // Fetch both team members and appliances
      Promise.all([
        fetchEmployeeByDepartment.post({ departmentId: userDetails?.departments[0].department_id }),
        fetchAllAppliances.post({ employeeId: userDetails?.employee_id })
      ])
        .then(([teamResponse, applianceResponse]) => {
          // Process team members data
          const processedTeamData = teamResponse.map((member: any) => ({
            value: member.employeeId,
            label: `${member.firstName} ${member.lastName} (${member.department?.department_name || 'N/A'})`
          }));
          setTeamMembersData(processedTeamData);

          // Process appliance data
          const processedApplianceData = applianceResponse.map((appliance: any) => ({
            value: appliance.appliance_id,
            label: `${appliance.appliance_name} (${appliance.appliance_id})`
          }));
          setApplianceData(processedApplianceData);
          
          setIsLoading(false);
        })
        .catch(error => {
          console.error("Error fetching data:", error);
          setError("Failed to fetch team members and appliances");
          setIsLoading(false);
        });
    } 
    else if (selectedFilter === "Appliance Reports") {
      fetchAllAppliances.post({ employeeId: userDetails?.employee_id })
        .then(response => {
          const processedApplianceData = response.map((appliance: any) => ({
            value: appliance.appliance_id,
            label: `${appliance.appliance_name} (${appliance.appliance_id})`
          }));
          setApplianceData(processedApplianceData);
          setIsLoading(false);
        })
        .catch(error => {
          console.error("Error fetching appliances:", error);
          setError("Failed to fetch appliance data");
          setIsLoading(false);
        });
    }
  }, [userDetails?.employee_id]);

  const handleReportFrequency = useCallback((selectedFilter: string) => {
    setView(prev => ({
      ...prev,
      reportFrequency: selectedFilter
    }));
  }, []);

  const [tableFilteredData, setTableFilteredData] = useState<any[]>([]);

  // Add handler for filter changes
  const handleTableFilterChange = useCallback((filteredData: any[]) => {
    setTableFilteredData(filteredData);
  }, []);

  const handleExport = useCallback(async (type: 'csv' | 'pdf') => {
    try {
      setIsLoading(true);
      setError(null);

      // Use tableFilteredData if it exists, otherwise use original data
      let dataToExport = tableFilteredData.length > 0 ? tableFilteredData : data;
      
      // Apply frequency filter if selected
      if (view.reportFrequency) {
        dataToExport = filterDataByFrequency(dataToExport, view.reportFrequency);
      }

      const timestamp = new Date().toISOString().split('T')[0];
      const reportType = view.inspectionReport || view.customDateReport || 'report';
      const frequency = view.reportFrequency ? `_${view.reportFrequency}` : '';
      const fileName = `${reportType}${frequency}_${timestamp}`;

      if (type === 'csv') {
        // Prepare data for CSV
        const csvData = dataToExport.map((row: { [x: string]: string; }) => {
          const rowData: { [key: string]: any } = {};
          columns.forEach(col => {
            rowData[col.label] = row[col.id] || '';
          });
          return rowData;
        });

        // Create worksheet
        const ws = XLSXUtils.json_to_sheet(csvData);
        const wb = XLSXUtils.book_new();
        XLSXUtils.book_append_sheet(wb, ws, 'Report');

        // Generate and download CSV
        writeFile(wb, `${fileName}.csv`);
      } else if (type === 'pdf') {
        // Initialize PDF
        const doc = new jsPDF() as JsPDFWithAutoTable;
        
        // Prepare data for PDF
        const pdfData = dataToExport.map(row => 
          columns.map(col => row[col.id] || 'N/A')
        );

        // Add title
        doc.setFontSize(16);
        doc.text(reportType.toUpperCase(), 14, 15);
        
        // Add timestamp
        doc.setFontSize(10);
        doc.text(`Generated on: ${new Date().toLocaleString()}`, 14, 25);
        // Generate table
        doc.autoTable({
          head: [columns.map(col => col.label)],
          body: pdfData,
          startY: 30,
          styles: { fontSize: 8 },
          headStyles: { fillColor: [66, 139, 202] },
          alternateRowStyles: { fillColor: [245, 245, 245] },
          margin: { top: 30 }
        });

        // Save PDF
        doc.save(`${fileName}.pdf`);
      }

      setIsLoading(false);
    } catch (err) {
      console.error('Export error:', err);
      setError(`Failed to export as ${type.toUpperCase()}`);
      setIsLoading(false);
    }
  }, [tableFilteredData, data, columns, view.reportFrequency, view.inspectionReport, view.customDateReport]);

  // Add this function to process follow-up data
  const processFollowUpData = useCallback((tasks: any[]) => {
    return tasks
      .filter(task => task.follow_up_request === true)
      .map((task, index) => ({
        sl_no: index + 1,
        tag_no: task.appliance?.tag_id || 'N/A',
        appliance: task.appliance?.appliance_name || 'N/A',
        location: task.appliance?.appliance_description?.split("Located At: ")[1] || "N/A",
        status: task.status,
        follow_up_request: task.follow_up_request ? 'Yes' : 'No',
        inspection_due_date: task.due_date
      }));
  }, []);

  const getSubPartsForAppliance = (applianceName: string): string[] => {
    switch (applianceName) {
      case 'Hydrant':
        return ['Hose', 'Valve', 'Nozzle', 'Pump'];
      case 'Extinguisher':
        return ['Cylinder', 'Nozzle', 'Pressure Gauge', 'Pin'];
      case 'Sprinkler':
        return ['Head', 'Pipe', 'Valve', 'Sensor'];
      case 'Fire Door':
        return ['Frame', 'Handle', 'Closer', 'Lock'];
      case 'Alarm':
        return ['Panel', 'Sensor', 'Bell', 'Battery'];
      default:
        return [];
    }
  };

  const handleApplianceChange = (id: string, selectedOption: string) => {
    // Extract appliance ID from the selected option (format: "Name (ID)")
    const applianceId = selectedOption.split('(')[1]?.replace(')', '') || '';
    
    setCustomFilters(prev => ({
      ...prev,
      appliances: prev.appliances.map(app => 
        app.id === id ? { 
          ...app, 
          id: applianceId, // Update the ID to actual appliance ID
          name: selectedOption,
          subParts: [] 
        } : app
      )
    }));
  };

  const handleSubPartChange = (id: string, subPart: string, checked: boolean) => {
    setCustomFilters(prev => ({
      ...prev,
      appliances: prev.appliances.map(app => {
        if (app.id === id) {
          return {
            ...app,
            subParts: checked 
              ? [...app.subParts, subPart]
              : app.subParts.filter(part => part !== subPart)
          };
        }
        return app;
      })
    }));
  };

  const addNewAppliance = () => {
    setCustomFilters(prev => ({
      ...prev,
      appliances: [...prev.appliances, { 
        id: `temp_${Date.now()}`, // Temporary ID until an appliance is selected
        name: '', 
        subParts: [] 
      }]
    }));
  };

  const removeAppliance = (id: string) => {
    setCustomFilters(prev => ({
      ...prev,
      appliances: prev.appliances.filter(app => app.id !== id)
    }));
  };

  // Update team member handlers
  const handleTeamMemberSelection = (id: string, selectedMember: string) => {
    setCustomFilters(prev => ({
      ...prev,
      teamMembers: prev.teamMembers.map(member => 
        member.id === id ? { 
          ...member, 
          selectedTeamMember: selectedMember,
          selectedAppliances: [] // Reset appliances when team member changes
        } : member
      )
    }));
  };

  const addNewTeamMember = () => {
    setCustomFilters(prev => ({
      ...prev,
      teamMembers: [...prev.teamMembers, { id: Date.now().toString(), selectedTeamMember: '', selectedAppliances: [] }]
    }));
  };

  const removeTeamMember = (id: string) => {
    setCustomFilters(prev => ({
      ...prev,
      teamMembers: prev.teamMembers.filter(member => member.id !== id)
    }));
  };

  // Add this handler for multiselect
  const handleApplianceMultiSelect = (memberId: string, selectedList: any[]) => {
    setCustomFilters(prev => ({
      ...prev,
      teamMembers: prev.teamMembers.map(member => {
        if (member.id === memberId) {
          return {
            ...member,
            selectedAppliances: selectedList.map(item => item.id)
          };
        }
        return member;
      })
    }));
  };

  const handleApplianceExport = async (exportFormat: 'pdf' | 'csv') => {
    try {
      setIsLoading(true);
      setError(null);

      // Validate inputs
      if (!customFilters.fromDate || !customFilters.toDate) {
        throw new Error('Please select date range');
      }

      if (!customFilters.appliances[0].name) {
        throw new Error('Please select at least one appliance');
      }
        
      // Make API call
      const response = await fetchApplianceReport.post({
        employeeId: userDetails?.employee_id,
        filters: {
          appliances: customFilters.appliances.map(appliance => ({
            applianceId: appliance.id,
            applianceName: appliance.name.split('(')[0].trim(),
            subParts: appliance.subParts
          })),
          dateRange: {
            fromDate: customFilters.fromDate,
            toDate: customFilters.toDate
          }
        }
      });

      // Generate and download the file based on format
      if (exportFormat === 'pdf') {
        const doc = new jsPDF() as JsPDFWithAutoTable;
        
        // Header
        doc.setFontSize(16);
        doc.text('Appliance Inspection Report', 14, 20);
        
        // Date Range
        doc.setFontSize(11);
        doc.text(`Date Range: ${formatDate(customFilters.fromDate)} to ${formatDate(customFilters.toDate)}`, 14, 30);
        
        // Summary
        const summary = response.data.reportDetails.summary;
        doc.autoTable({
          startY: 40,
          head: [['Total Inspections', 'Completed', 'Pending', 'Completion Rate']],
          body: [[
            summary.totalInspections,
            summary.completedInspections,
            summary.pendingInspections,
            summary.overallCompletionRate
          ]],
        });

        let yPos = (doc as any).lastAutoTable?.finalY || 70;
        // Appliance Details
        response.data.reportDetails.appliances.forEach((appliance: any) => {
          doc.setFontSize(12);
          doc.text(`Appliance: ${appliance.applianceName} (${appliance.applianceId})`, 14, yPos + 10);
          doc.text(`Location: ${appliance.location}`, 14, yPos + 20);
          
          doc.autoTable({
            startY: yPos + 30,
            head: [['Schedule ID', 'Type', 'Status', 'Due Date', 'Inspection Date', 'Remarks', 'Inspected By']],
            body: appliance.schedules.map((schedule: any) => [
              schedule.schedule_id,
              schedule.type === 'maintenance_task' ? 'Maintenance' : 'Inspection',
              schedule.status,
              formatDate(schedule.due_date),
              schedule.questionResponses?.inspection_date ? formatDate(schedule.questionResponses.inspection_date) : 'Pending',
              schedule.remarks || 'No remarks',
              `${schedule.assignedEmployee.first_name} ${schedule.assignedEmployee.last_name}`
            ]),
          });
          
          // If there are responses, add them in a separate table
          appliance.schedules.forEach((schedule: any) => {
            if (schedule.questionResponses?.responses) {
              const responses = schedule.questionResponses.responses;
              doc.setFontSize(10);
              doc.text(`Responses for Schedule: ${schedule.schedule_id}`, 14, (doc as any).lastAutoTable.finalY + 10);
              
              doc.autoTable({
                startY: (doc as any).lastAutoTable.finalY + 15,
                head: [['Question', 'Response']],
                body: Object.entries(responses).map(([key, value]) => [
                  `Question ${key}`,
                  value === 'yes' ? 'Yes' : 'No'
                ]),
              });
            }
          });
          
          yPos = (doc as any).lastAutoTable.finalY + 20;
        });

        // Save PDF
        const timestamp = new Date().toISOString().split('T')[0];
        doc.save(`appliance_report_${timestamp}.pdf`);

      } else {
        // Generate CSV
        const rows = [
          ['Appliance Inspection Report'],
          [`Date Range: ${formatDate(customFilters.fromDate)} to ${formatDate(customFilters.toDate)}`],
          [],
          ['Summary'],
          ['Total Inspections', 'Completed', 'Pending', 'Completion Rate'],
          [
            response.data.reportDetails.summary.totalInspections,
            response.data.reportDetails.summary.completedInspections,
            response.data.reportDetails.summary.pendingInspections,
            response.data.reportDetails.summary.overallCompletionRate
          ],
          [],
          ['Appliance Details'],
          ['Appliance ID', 'Appliance Name', 'Location', 'Sub-Part', 'Schedule ID', 'Status', 'Inspection Date', 'Remarks', 'Inspected By']
        ];

        // Add appliance data
        response.data.reportDetails.appliances.forEach((appliance: { subParts: any[]; applianceId: any; applianceName: any; location: any; }) => {
          appliance.subParts.forEach(subPart => {
            subPart.inspectionHistory.forEach((history: { scheduleId: any; status: any; inspectionDate: string; remarks: any; inspectedBy: any; }) => {
              rows.push([
                appliance.applianceId,
                appliance.applianceName,
                appliance.location,
                subPart.name,
                history.scheduleId,
                history.status,
                history.inspectionDate ? formatDate(history.inspectionDate) : 'Pending',
                history.remarks || 'No remarks',
                history.inspectedBy || 'N/A'
              ]);
            });
          });
        });

        // Generate and download Excel file
        const wb = XLSXUtils.book_new();
        const ws = XLSXUtils.aoa_to_sheet(rows);
        XLSXUtils.book_append_sheet(wb, ws, 'Appliance Report');
        
        const timestamp = new Date().toISOString().split('T')[0];
        writeFile(wb, `appliance_report_${timestamp}.xlsx`);
      }

    } catch (error) {
      console.error('Error generating report:', error);
      setError(error instanceof Error ? error.message : 'Failed to generate report');
    } finally {
      setIsLoading(false);
    }
  };

  // Modified handler for team member report
  const handleTeamMemberReport = async (exportFormat: 'pdf' | 'csv') => {
    try {
      setIsLoading(true);
      setError(null);
      // Validate inputs
      if (!customFilters.fromDate || !customFilters.toDate) {
        throw new Error('Please select date range');
      }

      if (!customFilters.teamMembers[0].selectedTeamMember) {
        throw new Error('Please select at least one team member');
      }

      // Make API call
      const response = await fetchTeamMemberInspection.post({
        employeeId: userDetails?.employee_id,
        filters: {
          teamMembers: customFilters.teamMembers.map(member => ({
            memberId: member.selectedTeamMember,
            appliances: member.selectedAppliances
          })),
          dateRange: {
            fromDate: customFilters.fromDate,
            toDate: customFilters.toDate
          }
        },
        exportFormat
      });

      console.log('API Response:', response); // Debug log

      // Set the data and export format
      setReportData(response);
      setPendingExport(exportFormat);

    } catch (error) {
      console.error('Error fetching report data:', error);
      setError(error instanceof Error ? error.message : 'Failed to fetch report data');
    } finally {
      setIsLoading(false);
    }
  };

  // Modified useEffect for file generation
  useEffect(() => {
    if (!reportData || !pendingExport) return;

    const generateFile = async () => {
      try {
        if (pendingExport === 'pdf') {
          const doc = new jsPDF() as JsPDFWithAutoTable;
          
          // Header
          doc.setFontSize(16);
          doc.text('Team Member Inspection Report', 14, 20);
          
          // Date Range
          doc.setFontSize(11);
          doc.text(`Date Range: ${formatDate(reportData.data.reportDetails.dateRange.from)} to ${formatDate(reportData.data.reportDetails.dateRange.to)}`, 14, 30);
          
          // Summary Table
          doc.autoTable({
            startY: 40,
            head: [['Total Inspections', 'Completed', 'Pending', 'Completion Rate']],
            body: [[
              reportData.data.reportDetails.summary.totalInspections,
              reportData.data.reportDetails.summary.completedInspections,
              reportData.data.reportDetails.summary.pendingInspections,
              reportData.data.reportDetails.summary.overallCompletionRate
            ]],
          });

          let yPos = (doc as any).lastAutoTable?.finalY || 70;
            
          // Team Members Details with Enhanced Inspection History
          reportData.data.reportDetails.teamMembers.forEach((member: any) => {
            doc.setFontSize(12);
            doc.text(`Team Member: ${member.memberName}`, 14, yPos + 10);
            
            doc.autoTable({
              startY: yPos + 15,
              head: [
                ['Schedule ID', 'Type', 'Status', 'Scheduled Date', 'Due Date', 
                 'Completion Date', 'Inspection Date', 'Appliance', 'Remarks', 
                 'Follow Up', 'Completed By', 'Monitored By']
              ],
              body: member.inspectionHistory.map((inspection: any) => [
                inspection.scheduleId,
                inspection.type,
                inspection.status,
                formatDate(inspection.scheduledDate),
                formatDate(inspection.dueDate),
                inspection.completionDate ? formatDate(inspection.completionDate) : 'Pending',
                inspection.inspectionDate ? formatDate(inspection.inspectionDate) : 'Pending',
                `${inspection.appliance.name} (${inspection.appliance.id})`,
                inspection.remarks || 'No remarks',
                inspection.followUpRequested ? 'Yes' : 'No',
                inspection.inspectionDetails?.completedBy || 'N/A',
                inspection.inspectionDetails?.monitoredBy || 'N/A'
              ]),
              styles: { fontSize: 8 }, // Smaller font size to fit all columns
              columnStyles: {
                0: { cellWidth: 25 }, // Schedule ID
                7: { cellWidth: 30 }, // Appliance
                8: { cellWidth: 25 }, // Remarks
              }
            });
            
            yPos = (doc as any).lastAutoTable?.finalY || yPos + 50;
          });

          const timestamp = new Date().toISOString().split('T')[0];
          doc.save(`team_member_report_${timestamp}.pdf`);

        } else {
          // Generate CSV with Enhanced Details
          const rows: any[] = [
            ['Team Member Inspection Report'],
            [`Date Range: ${formatDate(reportData.data.reportDetails.dateRange.from)} to ${formatDate(reportData.data.reportDetails.dateRange.to)}`],
            [],
            ['Overall Summary'],
            ['Total Inspections', 'Completed', 'Pending', 'Completion Rate'],
            [
              reportData.data.reportDetails.summary.totalInspections,
              reportData.data.reportDetails.summary.completedInspections,
              reportData.data.reportDetails.summary.pendingInspections,
              reportData.data.reportDetails.summary.overallCompletionRate
            ],
            []
          ];

          // Add team member details with enhanced inspection history
          reportData.data.reportDetails.teamMembers.forEach((member: any) => {
            rows.push([`Team Member: ${member.memberName}`]);
            rows.push([
              'Schedule ID',
              'Type',
              'Status',
              'Scheduled Date',
              'Due Date',
              'Completion Date',
              'Inspection Date',
              'Appliance',
              'Remarks',
              'Follow Up Requested',
              'Completed By',
              'Monitored By',
              'Project ID',
              'Department ID'
            ]);
            
            member.inspectionHistory.forEach((inspection: any) => {
              rows.push([
                inspection.scheduleId,
                inspection.type,
                inspection.status,
                formatDate(inspection.scheduledDate),
                formatDate(inspection.dueDate),
                inspection.completionDate ? formatDate(inspection.completionDate) : 'Pending',
                inspection.inspectionDate ? formatDate(inspection.inspectionDate) : 'Pending',
                `${inspection.appliance.name} (${inspection.appliance.id})`,
                inspection.remarks || 'No remarks',
                inspection.followUpRequested ? 'Yes' : 'No',
                inspection.inspectionDetails?.completedBy || 'N/A',
                inspection.inspectionDetails?.monitoredBy || 'N/A',
                inspection.inspectionDetails?.projectId || 'N/A',
                inspection.inspectionDetails?.departmentId || 'N/A'
              ]);
            });
            
            rows.push([]); // Empty row for spacing
          });

          // Create and save workbook
          const wb = XLSXUtils.book_new();
          const ws = XLSXUtils.aoa_to_sheet(rows);
          XLSXUtils.book_append_sheet(wb, ws, 'Inspection Report');
          
          const timestamp = new Date().toISOString().split('T')[0];
          writeFile(wb, `team_member_report_${timestamp}.xlsx`);
        }

        console.log(`${pendingExport.toUpperCase()} generated successfully`);
        
      } catch (error) {
        console.error('Error generating file:', error);
        setError(error instanceof Error ? error.message : 'Failed to generate file');
      } finally {
        // Reset states after file generation
        setReportData(null);
        setPendingExport(null);
      }
    };

    generateFile();
  }, [reportData, pendingExport]);

  // Update renderTeamMemberReport
  const renderTeamMemberReport = () => {
    return (
      <div className="bg-white p-6 rounded-lg shadow-sm">
        <div className="grid grid-cols-2 gap-6">
          <div className="space-y-4">
            {customFilters.teamMembers.map((member, index) => (
              <div key={member.id} className="border p-4 rounded-lg">
                <div className="flex justify-between items-center mb-3">
                  <h3 className="font-semibold">Team Member {index + 1}</h3>
                  {index > 0 && (
                    <button
                      onClick={() => removeTeamMember(member.id)}
                      className="text-red-500 hover:text-red-700"
                    >
                      Remove
                    </button>
                  )}
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium mb-1">Select Team Member</label>
                  <select
                    value={member.selectedTeamMember}
                    onChange={(e) => handleTeamMemberSelection(member.id, e.target.value)}
                    className="w-full p-2 border rounded-md"
                  >
                    <option value="">Select Team Member</option>
                    {teamMembersData.map(tm => (
                      <option key={tm.value} value={tm.value}>
                        {tm.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="space-y-2 mt-4">
                  <label className="block text-sm font-medium">Select Appliances</label>
                  <Multiselect
                    options={applianceData.map(app => ({
                      name: app.label,
                      id: app.value
                    }))}
                    selectedValues={member.selectedAppliances.map(appId => ({
                      name: applianceData.find(app => app.value === appId)?.label || '',
                      id: appId
                    }))}
                    onSelect={(selectedList) => handleApplianceMultiSelect(member.id, selectedList)}
                    onRemove={(selectedList) => handleApplianceMultiSelect(member.id, selectedList)}
                    displayValue="name"
                    showCheckbox={true}
                    placeholder="Select Appliances"
                    style={{
                      chips: {
                        background: '#4F46E5'
                      },
                      searchBox: {
                        border: '1px solid #E5E7EB',
                        borderRadius: '0.375rem',
                        padding: '0.5rem'
                      }
                    }}
                  />
                </div>
              </div>
            ))}

            <button
              onClick={addNewTeamMember}
              className="w-full py-2 border-2 border-dashed border-gray-300 rounded-lg hover:border-blue-500 hover:text-blue-500 transition-colors"
            >
              Add More Member
            </button>
          </div>

          {/* Right Column - Date Range & Generate */}
          <div className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium mb-1">From Date</label>
                <input
                  type="date"
                  value={customFilters.fromDate}
                  onChange={(e) => setCustomFilters(prev => ({
                    ...prev,
                    fromDate: e.target.value
                  }))}
                  className="w-full p-2 border rounded-md"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">To Date</label>
                <input
                  type="date"
                  value={customFilters.toDate}
                  onChange={(e) => setCustomFilters(prev => ({
                    ...prev,
                    toDate: e.target.value
                  }))}
                  className="w-full p-2 border rounded-md"
                />
              </div>
            </div>

            <div className="flex justify-end space-x-3">
              <button
                onClick={() => handleTeamMemberReport('pdf')}
                className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700"
                disabled={isLoading}
              >
                Generate PDF
              </button>
              <button
                onClick={() => handleTeamMemberReport('csv')}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                disabled={isLoading}
              >
                Generate CSV
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Add frequency change handler
  const handleFrequencyChange = useCallback((frequency: string) => {
    const filtered = filterDataByFrequency(data, frequency);
    setFilteredData(filtered);
    setView(prev => ({
      ...prev,
      reportFrequency: frequency
    }));
  }, [data]);

  return (
    <div className="container mx-auto p-6 bg-gray-50 min-h-screen">
      <div className="flex flex-col gap-6">
        {/* Header Section */}
        <div className="flex justify-between items-start">
          <div className="space-y-4 w-1/3">
            <Filter
              label={REPORT_TYPES.INSPECTION}
              options={["Inspections", "Maintenance", "Follow up", "Issues", "Alert"]}
              onFilterChange={handleInspectionReports}
              value={view.inspectionReport}
              className="bg-white shadow-sm rounded-lg"
            />

            <Filter
              label={REPORT_TYPES.CUSTOM}
              options={["Appliance Reports", "Team Member Reports"]}
              onFilterChange={handleCustomReports}
              value={view.customDateReport}
              className="bg-white shadow-sm rounded-lg"
            />
          </div>

          {/* Only show these buttons if NOT in custom reports */}
          {!isCustomReport(view) && data.length > 0 && (
            <div className="flex justify-end space-x-3 mb-4">
              <button
                onClick={() => handleExport('pdf')}
                className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700"
              >
                Export as PDF
              </button>
              <button
                onClick={() => handleExport('csv')}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
              >
                Export as CSV
              </button>
            </div>
          )}
        </div>

        {/* Error Message */}
        {error && (
          <div className="bg-red-50 text-red-600 p-3 rounded-lg">
            {error}
          </div>
        )}

        {/* Content Section */}
        <div className="bg-white rounded-lg shadow-sm">
          {isLoading ? (
            <div className="flex justify-center p-8">
              <CircularProgress />
            </div>
          ) : view.customDateReport === "Appliance Reports" ? (
            // Render custom appliance report UI when Appliance Reports is selected
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <div className="grid grid-cols-2 gap-6">
                {/* Left Column - Appliance Selection */}
                <div className="space-y-4">
                  {customFilters.appliances.map((appliance, index) => (
                    <div key={appliance.id} className="border p-4 rounded-lg">
                      <div className="flex justify-between items-center mb-3">
                        <h3 className="font-semibold">Appliance {index + 1}</h3>
                        {index > 0 && (
                          <button
                            onClick={() => removeAppliance(appliance.id)}
                            className="text-red-500 hover:text-red-700"
                          >
                            Remove
                          </button>
                        )}
                      </div>
                      
                      <select
                        value={appliance.name}
                        onChange={(e) => handleApplianceChange(appliance.id, e.target.value)}
                        className="w-full p-2 border rounded-md mb-3"
                      >
                        <option value="">Select Appliance</option>
                        {applianceData.map(app => (
                          <option key={app.value} value={`${app.label}`}>
                            {app.label}
                          </option>
                        ))}
                      </select>

                      <div className="space-y-2">
                        <label className="block text-sm font-medium">Sub-Parts</label>
                        <div className="grid grid-cols-2 gap-2">
                          {getSubPartsForAppliance(appliance.name).map(subPart => (
                            <label key={subPart} className="flex items-center space-x-2">
                              <input
                                type="checkbox"
                                checked={appliance.subParts.includes(subPart)}
                                onChange={(e) => handleSubPartChange(appliance.id, subPart, e.target.checked)}
                                className="rounded"
                              />
                              <span>{subPart}</span>
                            </label>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}

                  <button
                    onClick={addNewAppliance}
                    className="w-full py-2 border-2 border-dashed border-gray-300 rounded-lg hover:border-blue-500 hover:text-blue-500 transition-colors"
                  >
                    Add More Appliance
                  </button>
                </div>

                {/* Right Column - Date Range & Generate */}
                <div className="space-y-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium mb-1">From Date</label>
                      <input
                        type="date"
                        value={customFilters.fromDate}
                        onChange={(e) => setCustomFilters(prev => ({
                          ...prev,
                          fromDate: e.target.value
                        }))}
                        className="w-full p-2 border rounded-md"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium mb-1">To Date</label>
                      <input
                        type="date"
                        value={customFilters.toDate}
                        onChange={(e) => setCustomFilters(prev => ({
                          ...prev,
                          toDate: e.target.value
                        }))}
                        className="w-full p-2 border rounded-md"
                      />
                    </div>
                  </div>

                  
                </div>
              </div>
              <div className="flex justify-end space-x-3 pt-6">
                    <button
                      onClick={() => handleApplianceExport('pdf')}
                      className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700"
                    >
                      Generate PDF
                    </button>
                    <button
                      onClick={() => handleApplianceExport('csv')}
                      className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                    >
                      Generate CSV
                    </button>
                  </div>
            </div>
          ) : view.customDateReport === "Team Member Reports" ? renderTeamMemberReport() : (
            <ReusableTable 
              columns={columns} 
              data={filteredData.length > 0 ? filteredData : data}
              frequencyOptions={FREQUENCY_OPTIONS}
              onFrequencyChange={handleFrequencyChange}
              onFilterChange={handleTableFilterChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Reports;