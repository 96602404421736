import React, { useState, useEffect } from 'react';
import { deleteTag, fetchAllTags } from '../api/endpoints/APIEndpoints';
import BackButton from '../components/common/BackButton';
import { Tag } from '../types/tasks';
import { printBarcode } from '../utils/common';
import Banner from '../components/common/Banner';
import ConfirmationPopup from '../components/app/ConfirmationPopup';
import Barcode from '../components/app/Barcode';
import { FaPrint } from 'react-icons/fa';
import Modal from '../components/common/Modal'; // Assuming you have a Modal component
import { IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { shallowEqual, useSelector } from 'react-redux';
import { selectUserData } from '../store/store/authSlice';

const Tags: React.FC = () => {
    const [tagData, setTagData] = useState<Tag[]>([]);
    const [tagToDelete, setTagToDelete] = useState<Tag | null>(null);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
    const [selectedTagId, setSelectedTagId] = useState<string>('');
    const [selectedTag, setSelectedTag] = useState<Tag | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [filteredTags, setFilteredTags] = useState<any[]>([]);
    const userData = useSelector(selectUserData, shallowEqual)

    useEffect(() => {
        fetchAllTags.post({employeeId: userData?.employee_id})
            .then((res) => {
                console.log("All tags List", res);
                setTagData(res);
            })
            .catch((error) => console.log("ERROR!!", error));
    }, []);

    useEffect(() => {
        if (!tagData) return;
        
        const filtered = tagData.filter((tag: any) => {
            const searchableFields = [
                tag.tag_id,
                tag.appliance?.appliance_name,
                tag.appliance?.appliance_id,
                tag.appliance?.location,
                tag.appliance?.appliance_description,
                tag.status,
                // Add any other fields you want to search through
            ];

            return searchableFields.some(field => 
                field?.toString().toLowerCase().includes(searchTerm.toLowerCase())
            );
        });

        setFilteredTags(filtered);
    }, [searchTerm, tagData]);

    const openConfirmation = (tag: Tag) => {
        setTagToDelete(tag);
        setIsConfirmationOpen(true);
    };

    const closeConfirmation = () => {
        setIsConfirmationOpen(false);
        setTagToDelete(null);
    };

    const confirmDelete = () => {
        if (tagToDelete) {
            handleDelete(tagToDelete);
        }
        closeConfirmation();
    };

    const handleDelete = (tag: Tag) => {
        deleteTag.delete({
            tagId: tag.tag_id
        }).then(res => {
            console.log("tag delete successfully", res);
            let dataAfterDelete = tagData?.filter((data) => data.tag_id !== tag.tag_id);
            setTagData(dataAfterDelete);
        })
            .catch((error) => console.log("ERROR!!", error));
    };

    const handlePrint = (tag: Tag) => {
        setSelectedTagId(tag.tag_id);
        setIsPrintModalOpen(true);
    };

    const closePrintModal = () => {
        setIsPrintModalOpen(false);
        setSelectedTagId('');
    };

    const handleTagClick = (tag: Tag) => {
        setSelectedTag(tag);
        setIsModalOpen(true);
    };

    return (
        <div className="w-full p-5 h-[90vh] overflow-y-auto">
            {/* Search bar and scan button */}
            <div className="flex flex-wrap items-center mb-5">
                <input
                    type="text"
                    placeholder="Search by tag ID, appliance name, location..."
                    className="w-full md:w-1/2 p-2 border rounded mr-2 mb-2 md:mb-0"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <button className="w-full md:w-auto bg-green-500 text-white p-2 rounded">
                    Scan
                </button>
            </div>

            <div className='w-1/3'>
                <Banner title='Total Tags' count={tagData?.length} />
            </div>

            {/* Table title */}
            <div className="mb-5">
                <h2 className="text-xl font-bold text-left">Tags</h2>
            </div>

            {/* Table */}
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white text-sm">
                    <thead>
                        <tr className="border-b text-left font-bold">
                            <th className="p-2">Tag Id</th>
                            <th className="p-2">Name</th>
                            <th className="p-2">Status</th>
                            <th className="p-2">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredTags?.map((tag) => (
                            <tr key={tag.tag_id} className="border-b text-left">
                                <td className="text-blue-600 p-2 cursor-pointer hover:underline" onClick={() => handleTagClick(tag)}>{tag.tag_id}</td>
                                <td className="p-2">{tag.tag_name}</td>
                                <td className="p-2">{tag?.appliance?.appliance_status || "--"}</td>
                                <td className="p-2 flex gap-2">
                                    <Tooltip title="Delete">
                                        <IconButton onClick={() => openConfirmation(tag)} size="small">
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Print">
                                        <IconButton onClick={() => handlePrint(tag)} size="small">
                                            <FaPrint className="w-4 h-4" />
                                        </IconButton>
                                    </Tooltip>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <ConfirmationPopup
                isOpen={isConfirmationOpen}
                onConfirm={confirmDelete}
                onCancel={closeConfirmation}
            />

            <Modal show={isPrintModalOpen} onClose={closePrintModal}>
                <div className="flex flex-col items-center justify-center">
                    <div className='flex'>
                        <img src="./company_logo.png" alt="Company Logo" width="140" height="140" />
                        <div
                            id="printableBarcode"
                            className="flex items-center justify-center w-full h-full print:absolute print:inset-0"
                        >
                            <Barcode value={selectedTagId} />
                        </div>
                    </div>
                    <button
                        onClick={() => {
                            printBarcode();
                            closePrintModal();
                        }}
                        className="p-2 rounded-md bg-green-500 flex mt-4 p-2 text-white"
                    >
                        <FaPrint className="w-6 h-6" /> &nbsp; Print
                    </button>
                </div>
            </Modal>

            <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
                {selectedTag && (
                    <div className="p-4">
                        <h2 className="text-xl font-bold mb-4">Tag Details</h2>
                        <p><strong>Tag ID:</strong> {selectedTag.tag_id}</p>
                        <p><strong>Appliance ID:</strong> {selectedTag.appliance.appliance_id}</p>
                        <p><strong>Appliance Name:</strong> {selectedTag.appliance.appliance_name}</p>
                        <p><strong>Appliance Status:</strong> {selectedTag.appliance.appliance_status}</p>
                        {/* Add more details as needed */}
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default Tags;
