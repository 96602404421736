import React from 'react';

interface Option {
    value: string;
    label: string;
}

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
    label: string;
    options: Option[];
    error?: string;
    value: string;
}


export const Select: React.FC<SelectProps> = ({ label, options, error, value, onChange, ...props }) => {
    return (
        <div className="mb-4">
          <label className="block text-sm mb-2 text-left text-gray-800 mb-2 block">{label}</label>
          <select
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={value} // Set the selected value
            onChange={onChange} // Handle change events
            {...props}
          >
            <option value="">Select an option</option>
            {options?.map((option: any) => (
              <option key={option?.value} value={option?.value}>
                {option?.label}
              </option>
            ))}
          </select>
          {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
        </div>
      );
};
