import React, { ReactNode } from 'react'; // We'll create this file for the flip animation styles

interface FlipCardProps {
  frontContent: ReactNode;
  backContent: ReactNode;
  className?: string;
}

const FlipCard: React.FC<FlipCardProps> = ({ frontContent, backContent, className = '' }) => {
  return (
    <div className={`flip-card ${className}`}>
      <div className="flip-card-inner">
        <div className="flip-card-front">
          {frontContent}
        </div>
        <div className="flip-card-back">
          {backContent}
        </div>
      </div>
    </div>
  );
};

export default FlipCard;
