import React, { useState } from 'react';
import { reassignResponsibility } from '../../api/endpoints/APIEndpoints';
import { shallowEqual, useSelector } from 'react-redux';
import { selectUserData } from '../../store/store/authSlice';

interface TeamMember {
  id: string;
  name: string;
}

interface ResponsibilityReassignmentFormProps {
  teamMembers: TeamMember[];
  onSubmit: (fromMember: string, toMember: string) => void;
}

const ResponsibilityReassignmentForm: React.FC<ResponsibilityReassignmentFormProps> = ({ teamMembers, onSubmit }) => {
  const [fromMember, setFromMember] = useState<string>('');
  const [toMember, setToMember] = useState<string>('');
  const userDetails:any = useSelector(selectUserData,shallowEqual);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    reassignResponsibility.post({
      fromEmployeeId: fromMember,
      toEmployeeId: toMember,
      teamId: userDetails?.teams[0]?.team_id
    }).then((res) => console.log("responsibility reassigned", res))
      .catch((error) => console.log("ERROR!!", error));
  };

  return (
    <form onSubmit={handleSubmit} className="bg-gray-200 p-4 max-w-md mx-auto rounded-lg shadow-md">
      <div className="mb-4">
        <label htmlFor="fromMember" className="block text-sm font-medium text-gray-700 mb-2">
          Select member to reassign from:
        </label>
        <select
          id="fromMember"
          value={fromMember}
          onChange={(e) => setFromMember(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="">Choose a member</option>
          {teamMembers.map((member: any) => (
            <option key={member.employeeId} value={member.employeeId}>
              {`${member.firstName} ${member.lastName}`} ({member.role.roleName})
            </option>
          ))}
        </select>
      </div>

      <div className="mb-4">
        <label htmlFor="toMember" className="block text-sm font-medium text-gray-700 mb-2">
          Select member to reassign to:
        </label>
        <select
          id="toMember"
          value={toMember}
          onChange={(e) => setToMember(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="">Choose a member</option>
          {teamMembers.map((member: any) => (
            <option key={member.employeeId} value={member.employeeId}>
              {`${member.firstName} ${member.lastName}`} ({member.role.roleName})
            </option>
          ))}
        </select>
      </div>

      <button
        type="submit"
        className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      >
        Reassign Responsibility
      </button>
    </form>
  );
};

export default ResponsibilityReassignmentForm;