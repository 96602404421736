import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Input } from '../components/common/Input';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { changePassword } from '../api/endpoints/APIEndpoints';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

interface ChangePasswordFormData {
    email: string;
    tenantId: string;
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
}

export const ChangePassword: React.FC = () => {
    const { control, handleSubmit, watch, formState: { errors } } = useForm<ChangePasswordFormData>({
        defaultValues: {
            email: '',
            tenantId: '',
            currentPassword: '',
            newPassword: '',
            confirmPassword: ''
        },
    });

    const [passwordVisible, setPasswordVisible] = useState({
        current: false,
        new: false,
        confirm: false
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    const togglePasswordVisibility = (field: 'current' | 'new' | 'confirm') => {
        setPasswordVisible(prev => ({
            ...prev,
            [field]: !prev[field]
        }));
    };

    const onSubmit = async (data: ChangePasswordFormData) => {
        if (data.newPassword !== data.confirmPassword) {
            setError("New passwords don't match");
            return;
        }

        setIsLoading(true);
        setError(null);

        try {
            await changePassword.post({
                email: data.email,
                tenant_id: data.tenantId,
                currentPassword: data.currentPassword,
                newPassword: data.newPassword
            });
            
            toast.success('Password changed successfully');
            navigate('/login');
        } catch (error) {
            console.error("Password change error:", error);
            setError("Failed to change password. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="font-[sans-serif] relative" style={{ backgroundImage: "linear-gradient(135deg, #B3D9FF 0%, #B3E6E0 100%)" }}>
            <div className='pt-2'>
                <img src="./company_logo.png" alt="Company Logo" width="140" />
            </div>
            <div className="px-8 py-12">
                <div className='rounded-md absolute top-8 right-8 w-1/2 sm:block hidden'>
                    <img src="./inspection-vector.png" alt="error" style={{ opacity: "0.5" }} />
                </div>

                <div className='flex'>
                    <div className="bg-white rounded-xl sm:px-6 px-4 py-8 max-w-md w-full h-max shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] max-lg:mx-auto">
                        <div className="mb-8 text-left">
                            <h3 className="text-3xl font-extrabold text-gray-800">Change Password</h3>
                            <p className="text-gray-600 mt-2">Enter your current password and new password below</p>
                        </div>

                        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: 'Email is required',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: 'Invalid email address'
                                    }
                                }}
                                render={({ field }) => (
                                    <Input
                                        label="Email"
                                        type="email"
                                        placeholder="Enter your email"
                                        error={errors.email?.message}
                                        {...field}
                                    />
                                )}
                            />

                            <Controller
                                name="tenantId"
                                control={control}
                                rules={{
                                    required: 'Tenant ID is required',
                                    pattern: {
                                        value: /^[a-zA-Z0-9]+$/,
                                        message: 'Tenant ID must be alphanumeric'
                                    }
                                }}
                                render={({ field }) => (
                                    <Input
                                        label="Tenant ID"
                                        type="text"
                                        placeholder="Enter tenant ID"
                                        error={errors.tenantId?.message}
                                        {...field}
                                    />
                                )}
                            />

                            <Controller
                                name="currentPassword"
                                control={control}
                                rules={{ required: 'Current password is required' }}
                                render={({ field }) => (
                                    <div className="relative">
                                        <Input
                                            label="Current Password"
                                            type={passwordVisible.current ? "text" : "password"}
                                            error={errors.currentPassword?.message}
                                            placeholder="Enter current password"
                                            {...field}
                                        />
                                        <div
                                            className="absolute inset-y-0 right-0 top-[45%] pr-3 flex items-center cursor-pointer"
                                            onClick={() => togglePasswordVisibility('current')}
                                        >
                                            {passwordVisible.current ? <FaEye /> : <FaEyeSlash />}
                                        </div>
                                    </div>
                                )}
                            />

                            <Controller
                                name="newPassword"
                                control={control}
                                rules={{ 
                                    required: 'New password is required',
                                    minLength: {
                                        value: 8,
                                        message: 'Password must be at least 8 characters'
                                    }
                                }}
                                render={({ field }) => (
                                    <div className="relative">
                                        <Input
                                            label="New Password"
                                            type={passwordVisible.new ? "text" : "password"}
                                            error={errors.newPassword?.message}
                                            placeholder="Enter new password"
                                            {...field}
                                        />
                                        <div
                                            className="absolute inset-y-0 right-0 top-[45%] pr-3 flex items-center cursor-pointer"
                                            onClick={() => togglePasswordVisibility('new')}
                                        >
                                            {passwordVisible.new ? <FaEye /> : <FaEyeSlash />}
                                        </div>
                                    </div>
                                )}
                            />

                            <Controller
                                name="confirmPassword"
                                control={control}
                                rules={{ 
                                    required: 'Please confirm your password',
                                    validate: (value) => value === watch('newPassword') || 'Passwords do not match'
                                }}
                                render={({ field }) => (
                                    <div className="relative">
                                        <Input
                                            label="Confirm New Password"
                                            type={passwordVisible.confirm ? "text" : "password"}
                                            error={errors.confirmPassword?.message}
                                            placeholder="Confirm new password"
                                            {...field}
                                        />
                                        <div
                                            className="absolute inset-y-0 right-0 top-[45%] pr-3 flex items-center cursor-pointer"
                                            onClick={() => togglePasswordVisibility('confirm')}
                                        >
                                            {passwordVisible.confirm ? <FaEye /> : <FaEyeSlash />}
                                        </div>
                                    </div>
                                )}
                            />

                            {error && (
                                <div className="text-red-500 text-sm mt-2 mb-4">
                                    {error}
                                </div>
                            )}

                            <button
                                type="submit"
                                className="w-full text-white py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 mt-8 flex items-center justify-center"
                                disabled={isLoading}
                                style={{ background: "linear-gradient(135deg, rgba(30, 144, 255, 0.8) 0%, rgba(0, 0, 128, 0.8) 100%)" }}
                            >
                                {isLoading ? (
                                    <div className="flex items-center">
                                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        Changing Password...
                                    </div>
                                ) : 'Change Password'}
                            </button>

                            <p className="text-sm mt-8 text-center text-gray-800">
                                Remember your password?{' '}
                                <a 
                                    className="text-blue-600 font-semibold hover:underline ml-1 whitespace-nowrap cursor-pointer" 
                                    onClick={() => navigate("/login")}
                                >
                                    Back to Login
                                </a>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};
