import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import SideMenu from './SideMenu';
import Sidebar from './SideBar';

const MainLayout: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="">
      <Header toggleMenu={toggleMenu} />
      {/* {menuOpen && (
        <>
          <div
            className="fixed inset-0 bg-black opacity-50"
            onClick={toggleMenu}
          />
          <SideMenu toggleMenu={toggleMenu} />
        </>
      )} */}
      <div className='flex'>
        <Sidebar />
        <div className="main-content w-full">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
