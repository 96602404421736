import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { selectAllTeamTaskStatus, selectEmployeeTaskStatus } from '../../store/store/mainSlice';
import { convertPercentageToFiveScale } from '../../utils/common';

interface Employee {
    name: string;
    rating: number;
}

interface EmployeeRatingProps {
    employees: Employee[];
}

const StarRating: React.FC<{ rating: number }> = ({ rating }) => {
    return (
        <div className="flex">
            {[1, 2, 3, 4, 5].map((star) => (
                <svg
                    key={star}
                    className={`w-5 h-5 ${star <= rating ? 'text-yellow-400' : 'text-gray-300'
                        }`}
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
            ))}
        </div>
    );
};

const EmployeeRating: React.FC<EmployeeRatingProps> = ({ employees }) => {
    const allTeamTaskStatus = useSelector(selectEmployeeTaskStatus, shallowEqual);

    const calculateRating = (completedTasks: number, totalTasks: number) => {
        const rating = Math.round((completedTasks / totalTasks) * 100);
        return convertPercentageToFiveScale(rating);
    }

    console.log('allTeamTaskStatus', allTeamTaskStatus)
    return (
        <div className="bg-gray-100 p-6 rounded-lg shadow-md max-w-md mx-auto">
            <ul className="space-y-3">
                {allTeamTaskStatus?.map((employee: any, index: number) => (
                    <li
                        key={employee.id}
                        className={`flex justify-between items-center p-3 bg-white rounded`}
                    >
                        <div className="flex items-center space-x-3">
                            <span className="font-medium uppercase">{employee.name}</span>
                        </div>
                        <StarRating rating={calculateRating(employee.taskStatus?.completed, employee.taskStatus?.scheduled)} />
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default EmployeeRating;