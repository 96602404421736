import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';

interface BackButtonProps {
  label?: string;
  className?: string;
  handleClick?: () => void;
}

const BackButton: React.FC<BackButtonProps> = ({ label = 'Back', className = '', handleClick }) => {
  
  return (
    <div
      onClick={handleClick}
      className={`flex items-center cursor-pointer py-4 text-black ${className}`}
    >
      <AiOutlineArrowLeft className="mr-2" />
      <span>BACK</span>
    </div>
  );
};

export default BackButton;
