import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

interface InspectionStats {
  scheduledCount: number;
  completedCount: number;
  completedLateCount: number;
}

interface InspectionBarChartProps {
  data: InspectionStats;
  inspectionChartData: any;
}

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          padding: '10px',
          borderRadius: '4px',
        }}
      >
        <Typography variant="body2">{label}</Typography>
        {payload.map((pld: any) => (
          <Typography key={pld.dataKey} variant="body2" sx={{ color: pld.fill }}>
            {`${pld.name}: ${pld.value}`}
          </Typography>
        ))}
      </Box>
    );
  }
  return null;
};

const InspectionBarChart: React.FC<InspectionBarChartProps> = ({ data, inspectionChartData }) => {
  const totalInspections = data.scheduledCount;
  const percentageCompleted = ((data.completedCount / totalInspections) * 100).toFixed(1);

  const chartData = [
    { day: '24-11', Scheduled: 20, Completed: 15, CompletedLate: 2 },
    { day: 'Tue', Scheduled: 25, Completed: 20, CompletedLate: 3 },
    { day: 'Wed', Scheduled: 30, Completed: 25, CompletedLate: 4 },
    { day: 'Thu', Scheduled: 22, Completed: 18, CompletedLate: 2 },
    { day: 'Fri', Scheduled: 28, Completed: 24, CompletedLate: 3 },
    { day: 'Sat', Scheduled: 15, Completed: 12, CompletedLate: 1 },
    { day: 'Sun', Scheduled: 10, Completed: 8, CompletedLate: 1 },
  ];

  return (
    <Card sx={{ maxWidth: 800, margin: 'auto', mt: 4, height: 420}}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <p>Total Inspections</p>
          <Box>
            <Typography variant="h4" component="div" fontWeight="bold">
              {totalInspections}
            </Typography>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <ArrowUpwardIcon color="success" fontSize="small" />
              <Typography variant="body2" color="success.main">
                {percentageCompleted}% completed
              </Typography>
            </Box>
          </Box>
        </Box>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={inspectionChartData}>
            <XAxis
              dataKey="day"
              axisLine={{ stroke: '#E0E0E0' }}
              tickLine={{ stroke: '#E0E0E0' }}
              tick={{ fill: '#9E9E9E', fontSize: 12 }}
            />
            <YAxis
              axisLine={{ stroke: '#E0E0E0' }}
              tickLine={{ stroke: '#E0E0E0' }}
              tick={{ fill: '#9E9E9E', fontSize: 12 }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Bar
              dataKey="Scheduled"
              fill="#448AFF"
              animationBegin={0}
              animationDuration={2000}
            />
            <Bar
              dataKey="Completed"
              fill="#90EE90"
              animationBegin={500}
              animationDuration={2000}
            />
            <Bar
              dataKey="CompletedLate"
              fill="#FFD300"
              animationBegin={1000}
              animationDuration={2000}
            />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default InspectionBarChart;