import React, { useEffect, useState } from 'react';
import DisplayCard from '../common/DisplayCard';
import { fetchAllAlerts, fetchAllAppliances, fetchAllTasksWithLevel, fetchAllIssues, fetchDepartmentStats, getAllCompanies, getAllDepartments, fetchCompanySpecificStats } from '../../api/endpoints/APIEndpoints';
import { Appliance } from '../../types/tasks';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { selectUserData } from '../../store/store/authSlice';
import FlippingCards from '../common/FlippingCards';
import RightPanel from './RightPanel';
import { Leaf, Hexagon, Plus, ClipboardCheckIcon, WrenchIcon, UserIcon } from 'lucide-react';
import InspectionBarChart from './InspectionBarChart';
import InspectionPieChart from './InspectionPieChart';
import IssueReportGraph from './IssueReportGraph';
import ApplianceBarGraph from './ApplianceBarGraph';
import { selectAllTasksWithLevel, selectLevel4AllDepartments, setAllAlerts, setAllCompanies, setAllDepartments, setAllIssues, setAllTasksWithLevel, setLevel4AllDepartments } from '../../store/store/mainSlice';
import { filterAndFormatWeeklyData } from '../../utils/common';
import { Settings, FileText, Building, Users, Bell, AlertTriangle } from 'lucide-react';
import InspectionCard from './InspectionCard';
import AddEntityModal from './AddEntityModal';
import EmployeeTaskStatusDashboard from './EmployeeTaskStatusDashboard';
import { SelectChangeEvent, Tooltip } from '@mui/material';
import {
    ClipboardCheck,
    Box,
} from 'lucide-react';
import { Select, MenuItem } from '@mui/material';
import Loader from '../common/Loader';

interface TaskSummary {
    label: string;
    count: number;
    icon: React.ReactNode;
}

interface EmployeePerformance {
    id: string;
    name: string;
    completedTasks: number;
    icon: React.ReactNode;
}

const Features: React.FC = () => {
    const [applianceData, setApplianceData] = useState<Appliance[]>([]);
    const [completedInspection, setCompletedInspection] = useState<any>([]);
    const [dueInspection, setDueInspection] = useState<any>([]);
    const [completedMaintenance, setCompletedMaintenance] = useState<any>([]);
    const [dueMaintenance, setDueMaintenance] = useState<any>([]);
    const userData = useSelector(selectUserData, shallowEqual);
    const [alerts, setAlerts] = useState<any>([]);
    const [isssues, setIssues] = useState<any>([]);
    const dispatch = useDispatch()
    const allTasksWithLevel = useSelector(selectAllTasksWithLevel, shallowEqual);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const level4AllDepartments = useSelector(selectLevel4AllDepartments, shallowEqual);
    const [taskSummaries, setTaskSummaries] = useState<TaskSummary[]>([]);
    const [topPerformers, setTopPerformers] = useState<EmployeePerformance[]>([]);
    const [companies, setCompanies] = useState<Array<{ company_id: string, company_name: string }>>([]);
    const [selectedCompany, setSelectedCompany] = useState<string>('');
    const [companyStats, setCompanyStats] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(true); 

    useEffect(() => {
        setIsLoading(true);
        
        const apiCalls = [
            fetchAllAppliances.post({employeeId: userData?.employee_id})
                .then((res) => {
                    console.log("All appliance List", res);
                    setApplianceData(res);
                }),
            
            fetchAllTasksWithLevel.post({ employee_id: userData?.employee_id })
                .then((res) => {
                    dispatch(setAllTasksWithLevel(res.tasks));
                    console.log("res dpe", res);
                    let completedInspection = res.tasks.filter((data: any) => data.status === "satisfactory" && data.type === "inspection_task");
                    let dueInspection = res.tasks.filter((data: any) => (data.status === "Pending" || data.status === "unsatisfactory") && data.type === "inspection_task");
                    let completedMaintenance = res.tasks.filter((data: any) => data.status === "satisfactory" && data.type === "maintenance_task");
                    let dueMaintenance = res.tasks.filter((data: any) => (data.status === "Pending" || data.status === "unsatisfactory") && data.type === "maintenance_task");
                    setCompletedInspection(completedInspection);
                    setDueInspection(dueInspection);
                    setCompletedMaintenance(completedMaintenance);
                    setDueMaintenance(dueMaintenance);
                }),

            fetchAllAlerts.post({employee_id: userData?.employee_id})
                .then((res) => {
                    setAlerts(res)
                    dispatch(setAllAlerts(res))
                }),

            fetchAllIssues.post({ employee_id: userData?.employee_id })
                .then((res) => {
                    setIssues(res.issues);
                    dispatch(setAllIssues(res.issues));
                }),

            getAllDepartments.get()
                .then((res) => dispatch(setAllDepartments(res))),

            getAllCompanies.get()
                .then((res) => {
                    setCompanies(res);
                    if (res.length > 0) {
                        setSelectedCompany(res[0].company_id);
                    }
                })
        ];

        Promise.all(apiCalls)
            .catch((error) => console.log("ERROR!!", error))
            .finally(() => setIsLoading(false));

    }, []);

    useEffect(() => {
        if (selectedCompany && userData?.role?.level === 4) {
            fetchCompanySpecificStats.post({ company_id: selectedCompany, employee_id: userData?.employee_id })
                .then((stats: any) => {
                    setCompanyStats(stats);
                    dispatch(setLevel4AllDepartments(stats)); // Update the Redux store
                })
                .catch((error: any) => console.log("ERROR fetching company stats:", error));
        }
    }, [selectedCompany, userData?.employee_id, dispatch]);

    useEffect(() => {
        if (level4AllDepartments?.detailedStats) {
            const inspectionData = level4AllDepartments.detailedStats.find((stat: { frontContent: { labels: string } }) => stat.frontContent.labels === "Inspection");
            const maintenanceData = level4AllDepartments.detailedStats.find((stat: { frontContent: { labels: string } }) => stat.frontContent.labels === "Maintenance");

            const newTaskSummaries: TaskSummary[] = [
                {
                    label: userData?.role?.level === 4 ? 'Inspections Scheduled Today' : 'Inspections Done Today',
                    count: userData?.role?.level === 4
                        ? inspectionData?.frontContent.scheduled.thisMonth ?? 0
                        : inspectionData?.frontContent.completed.thisMonth ?? 0,
                    icon: <ClipboardCheckIcon size={24} />
                },
                {
                    label: userData?.role?.level === 4 ? 'Maintenance Scheduled Today' : 'Maintenance Done Today',
                    count: userData?.role?.level === 4
                        ? maintenanceData?.frontContent.scheduled.thisMonth ?? 0
                        : maintenanceData?.frontContent.completed.thisMonth ?? 0,
                    icon: <WrenchIcon size={24} />
                }
            ];

            setTaskSummaries(newTaskSummaries);
        }
    }, [level4AllDepartments, userData]);

    // Add this function to process top performers
    const processTopPerformers = (tasks: any[]): any => {
        const currentDate = new Date();
        const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        
        // Group all tasks by employee
        const employeeInspections = tasks.reduce((acc: { [key: string]: any }, task) => {
          const employeeId = task.assigned_to;
          const employeeName = `${task.assignedEmployee?.first_name || ''} ${task.assignedEmployee?.last_name || ''}`.trim();
          const taskDate = new Date(task.inspection_date || task.scheduled_date);
          
          // Only process tasks for current month
          if (taskDate >= firstDayOfMonth) {
            if (!acc[employeeId]) {
              acc[employeeId] = {
                id: employeeId,
                name: employeeName,
                completedTasks: 0,
                inProgressTasks: 0,
                totalTasks: 0,
                completionRate: '0%',
                icon: <span>👤</span>
              };
            }
            
            // Categorize tasks based on status
            if (task.status === 'satisfactory') {
              acc[employeeId].completedTasks += 1;
            } else if (task.status === 'Pending' || task.status === 'unsatisfactory') {
              acc[employeeId].inProgressTasks += 1;
            }
            
            // Calculate total tasks (completed + in progress)
            acc[employeeId].totalTasks = acc[employeeId].completedTasks + acc[employeeId].inProgressTasks;
            
            // Calculate completion rate based on completed vs total
            acc[employeeId].completionRate = acc[employeeId].totalTasks > 0
              ? `${Math.round((acc[employeeId].completedTasks / acc[employeeId].totalTasks) * 100)}%`
              : '0%';
          }
          
          return acc;
        }, {});
      
        // Convert to array and sort by completion rate and total tasks
        return Object.values(employeeInspections)
          .filter(employee => employee.totalTasks > 0) // Only include employees with tasks
          .sort((a, b) => {
            // First sort by completion rate
            const rateA = parseInt(a.completionRate);
            const rateB = parseInt(b.completionRate);
            if (rateB !== rateA) {
              return rateB - rateA;
            }
            // If completion rates are equal, sort by total tasks
            return b.totalTasks - a.totalTasks;
          })
          .slice(0, 3); // Get top 3 performers
      };

    const topPerformersData = processTopPerformers(allTasksWithLevel || []);

    console.log("allTasksWithLevel", allTasksWithLevel)

    const inspectionOutcomeData = processInspectionOutcomes(allTasksWithLevel || []);

    const calculateTotals = (weekData: any): any => {
        return weekData.reduce((acc: { scheduledCount: any; completedCount: any; completedLateCount: any; }, day: { Scheduled: any; Completed: any; CompletedLate: any; }) => {
            acc.scheduledCount += day.Scheduled;
            acc.completedCount += day.Completed;
            acc.completedLateCount += day.CompletedLate;
            return acc;
        }, {
            scheduledCount: 0,
            completedCount: 0,
            completedLateCount: 0
        });
    };

    const inspectionChartData = filterAndFormatWeeklyData(allTasksWithLevel)
    const inspectionData = calculateTotals(inspectionChartData)

    console.log("inspection chart data", inspectionChartData)

    const handleCompanyChange = (event: SelectChangeEvent) => {
        setSelectedCompany(event.target.value as string);
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            <div className='grid' style={{ gridTemplateColumns: "2fr 1fr" }}>
                <div className="w-full p-5 h-screen overflow-y-scroll pb-8">
                    {
                        userData?.role?.level === 4 && <>
                            <div className="w-full bg-white p-4 mb-4 rounded-lg shadow">
                                <div className="flex justify-between items-center mb-4">
                                    <Select
                                        value={selectedCompany}
                                        onChange={handleCompanyChange}
                                        displayEmpty
                                        className="min-w-[200px]"
                                    >
                                        {companies.map((company) => (
                                            <MenuItem key={company.company_id} value={company.company_id}>
                                                {company.company_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <button
                                        onClick={() => setIsModalOpen(true)}
                                        className="flex items-center text-blue-600 hover:text-blue-800"
                                    >
                                        Add new entity
                                        <Plus className="ml-1" size={20} />
                                    </button>
                                </div>
                                {companyStats && (
                                    <div className="grid grid-cols-6 gap-4">
                                        <StatItem
                                            icon={<ClipboardCheck size={24} />}
                                            value={companyStats.totalInspections}
                                            tooltip="Total Inspections"
                                        />
                                        <StatItem
                                            icon={<FileText size={24} />}
                                            value={companyStats.totalChecklists}
                                            tooltip="Total Checklists"
                                        />
                                        <StatItem
                                            icon={<Box size={24} />}
                                            value={companyStats.totalAppliances}
                                            tooltip="Total Appliances"
                                        />
                                        <StatItem
                                            icon={<Users size={24} />}
                                            value={companyStats.totalEmployees}
                                            tooltip="Total Employees"
                                        />
                                        <StatItem
                                            icon={<Bell size={24} />}
                                            value={companyStats.totalAlerts}
                                            tooltip="Total Alerts"
                                        />
                                        <StatItem
                                            icon={<AlertTriangle size={24} />}
                                            value={companyStats.totalIssues}
                                            tooltip="Total Issues"
                                        />
                                    </div>
                                )}
                            </div>
                            <div className='grid grid-cols-2 gap-4'>
                                {companyStats?.detailedStats?.map((stat: any, index: number) => (
                                    <InspectionCard key={index} data={[stat]} />
                                ))}
                            </div>
                        </>
                    }

                    {userData?.role?.level !== 4 &&
                        <>
                            <div className='grid grid-cols-3 gap-4 text-left'>
                                <DisplayCard title={'Inspection Completed'} quantity={completedInspection?.length} lastMonthQuantityApprox={10} thisMonthQuantityApprox={10} />
                                <DisplayCard title={'Inspection Due'} quantity={dueInspection?.length} lastMonthQuantityApprox={10} thisMonthQuantityApprox={10} />
                                <DisplayCard title={'Appliances'} quantity={applianceData?.length} lastMonthQuantityApprox={10} thisMonthQuantityApprox={10} />
                            </div>
                            <div className='grid grid-cols-3 gap-4 text-left pt-4 pb-4'>
                                <DisplayCard title={'Maintenance Completed'} quantity={completedMaintenance?.length} lastMonthQuantityApprox={10} thisMonthQuantityApprox={10} />
                                <DisplayCard title={'Maintenance Due'} quantity={dueMaintenance?.length} lastMonthQuantityApprox={10} thisMonthQuantityApprox={10} />
                                <DisplayCard title={'No of Alerts'} quantity={alerts?.alerts?.length || 0} lastMonthQuantityApprox={10} thisMonthQuantityApprox={10} />
                            </div>
                        </>
                    }

                    <EmployeeTaskStatusDashboard />
                    {/* <InspectionCharts /> */}
                    <div className='grid grid-cols-2 gap-4'>
                        <div>
                            <InspectionBarChart data={inspectionData} inspectionChartData={inspectionChartData} />
                            <InspectionPieChart data={inspectionOutcomeData} />
                        </div>
                        <div>
                            <IssueReportGraph issues={isssues}/>
                            <ApplianceBarGraph data={{ appliances: applianceData }} />
                        </div>
                    </div>
                </div>
                <div className='h-screen overflow-y-scroll pb-8'>
                    <RightPanel
                        taskSummaries={taskSummaries}
                        topPerformers={topPerformersData}
                        userLevel={userData?.role?.level}
                    />
                    <div className='pl-8'>
                        <FlippingCards data={alerts?.alerts} type={"alert"} />
                    </div>
                    <div className='pl-8 pt-4'><FlippingCards data={isssues} type="issues" /></div>
                </div>
            </div >
            <AddEntityModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </>
    );
};

interface StatItemProps {
    icon: React.ReactNode;
    value: number;
    tooltip: string;
}

const StatItem: React.FC<StatItemProps> = ({ icon, value, tooltip }) => (
    <Tooltip title={tooltip} arrow>
        <div className="flex items-center space-x-2 cursor-pointer">
            <div className="text-gray-600">{icon}</div>
            <span className="text-xl font-semibold">{value}</span>
        </div>
    </Tooltip>
);

// Add this function to process the inspection outcomes
const processInspectionOutcomes = (tasks: any[]) => {
  return {
    satisfactory: tasks.filter(task => 
      task.status === 'satisfactory' && 
      !task.follow_up_request
    ).length,
    
    unsatisfactory: tasks.filter(task => 
      task.status === 'unsatisfactory' && 
      !task.follow_up_request
    ).length,
    
    followUpRequested: tasks.filter(task => 
      task.follow_up_request === true
    ).length
  };
};

export default Features;
