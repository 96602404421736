import React, { useEffect, useState } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { Input } from '../components/common/Input';
import { Select } from '../components/common/Select';
import { getAllDepartments, getAllProjects, getAllRoles, registerUser } from '../api/endpoints/APIEndpoints';
import { Autocomplete, TextField } from '@mui/material';
import { shallowEqual, useSelector } from 'react-redux';
import { selectUserData } from '../store/store/authSlice';

interface FormData {
    requestorId: string;
    supervisorId: string;
    firstName: string;
    lastName: string;
    email: string;
    company: string;
    role: string;
    department: string[];
    project: string[];
    password: string;
    confirmPassword: string;
}

export const Register: React.FC = () => {
    const { control, handleSubmit, formState: { errors } } = useForm<FormData>({
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            company: '',
            role: '',
            department: [],
            project: [],
        },
    });

    const [departments, setDepartments] = useState([]);
    const [roles, setRoles] = useState([]);
    const [projects, setProjects] = useState([]);
    const [error, setError] = useState<string | null>(null);
    const password = useWatch({ control, name: 'password' });
    const [registrationRequest, setRegistrationRequest] = useState(false)
    const userData = useSelector(selectUserData, shallowEqual);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [departmentsResponse, rolesResponse, projectsResponse] = await Promise.all([
                    getAllDepartments.get(),
                    getAllRoles.get(),
                    getAllProjects.post({employee_id: userData?.employee_id}),
                ]);

                const formattedDepartments = departmentsResponse.map((department: any) => ({
                    value: department.department_id,
                    label: department.department_name,
                }));

                const formattedRoles = rolesResponse.map((role: any) => ({
                    value: role.role_id,
                    label: role.role_name,
                }));

                const formattedProjects = projectsResponse.map((project: any) => ({
                    value: project.project_id,
                    label: project.project_name,
                }));

                setDepartments(formattedDepartments);
                setRoles(formattedRoles);
                setProjects(formattedProjects);

            } catch (err) {
                console.error('ERROR!', err);
                setError('An error occurred while fetching data.');
            }
        };
        fetchData()
    }, [])

    const onSubmit = (data: FormData) => {
        console.log("123", data);
        registerUser.post({
            "requestingEmployeeId": data?.requestorId,
            "supervisorId": data?.supervisorId || data?.requestorId,
            "firstName": data?.firstName,
            "lastName": data?.lastName,
            "password": data?.password,
            "email": data?.email,
            "companyId": data?.company,
            "roleId": Number(data?.role),
            "departmentIds": data?.department.map((dp: any) => dp.value),
            "projectIds": data?.project.map((pr: any) => pr.value)
        })
            .then((res) => {
                setRegistrationRequest(true)
                console.log("User created - ", res)
            })
            .catch((error) => {
                console.log("ERROR!!", error)
                setRegistrationRequest(false)
            })
    };

    return (
        <>
            <div className="font-[sans-serif] relative h-screen" style={{ backgroundImage: "linear-gradient(135deg, #B3D9FF 0%, #B3E6E0 100%)" }}>
                <div className='pt-2' >
                    <img src="./company_logo.png" alt="error" width="140" />
                </div>
                <div className="px-8 flex flex-row-reverse mt-[-40px] " >
                    <div className='rounded-md absolute top-8 left-8 w-1/2 sm:block hidden'>
                        <img src="./inspection-vector.png" alt="error" style={{ opacity: "0.5" }} />
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)} className="p-6 bg-white w-1/2 md:col-span-2 rounded-md">
                        {
                            registrationRequest
                                ? <div className="text-center">
                                    <p className="text-lg font-medium text-gray-700 mb-4">
                                        Your request has been forwarded to the selected approver Id.
                                        Once approved, you will receive an email at your registered address.
                                    </p>
                                    <p className="text-right text-gray-500 mt-8">
                                        Regards,<br />
                                        Team Spectent
                                    </p>
                                </div>
                                : <>
                                    <div className="mb-6">
                                        <h3 className="text-gray-800 text-2xl text-left font-bold">Create an account</h3>
                                    </div>
                                    <div className='grid md:grid-cols-2 gap-4'>
                                        <Controller
                                            name="requestorId"
                                            control={control}
                                            rules={{
                                                required: 'requestorId is required',
                                            }}
                                            render={({ field }) => (
                                                <Input
                                                    label="Requestor Id"
                                                    error={errors.requestorId?.message}
                                                    {...field}
                                                />
                                            )}
                                        />
                                        <Controller
                                            name="supervisorId"
                                            control={control}
                                            rules={{
                                                required: 'SupervisorId is required',
                                            }}
                                            render={({ field }) => (
                                                <Input
                                                    label="Supervisor Id"
                                                    error={errors.supervisorId?.message}
                                                    {...field}
                                                />
                                            )}
                                        />
                                        <Controller
                                            name="firstName"
                                            control={control}
                                            rules={{
                                                required: 'First name is required',
                                                pattern: {
                                                    value: /^[A-Za-z]+$/,
                                                    message: 'Only alphabets are allowed'
                                                }
                                            }}
                                            render={({ field }) => (
                                                <Input
                                                    label="First Name"
                                                    error={errors.firstName?.message}
                                                    {...field}
                                                />
                                            )}
                                        />
                                        <Controller
                                            name="lastName"
                                            control={control}
                                            rules={{
                                                required: 'Last name is required',
                                                pattern: {
                                                    value: /^[A-Za-z]+$/,
                                                    message: 'Only alphabets are allowed'
                                                }
                                            }}
                                            render={({ field }) => (
                                                <Input
                                                    label="Last Name"
                                                    error={errors.lastName?.message}
                                                    {...field}
                                                />
                                            )}
                                        />
                                        <Controller
                                            name="email"
                                            control={control}
                                            rules={{
                                                required: 'Email is required',
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: 'Invalid email address'
                                                }
                                            }}
                                            render={({ field }) => (
                                                <Input
                                                    label="Email"
                                                    type="email"
                                                    error={errors.email?.message}
                                                    {...field}
                                                />
                                            )}
                                        />

                                        <Controller
                                            name="password"
                                            control={control}
                                            rules={{ required: 'Password is required' }}
                                            render={({ field }) => (
                                                <Input
                                                    label="Password"
                                                    type="password"
                                                    error={errors.password?.message}
                                                    {...field}
                                                />
                                            )}
                                        />

                                        <Controller
                                            name="confirmPassword"
                                            control={control}
                                            rules={{
                                                required: 'Confirm Password is required',
                                                validate: (value) => value === password || 'Passwords do not match'
                                            }}
                                            render={({ field }) => (
                                                <Input
                                                    label="Confirm Password"
                                                    type="password"
                                                    error={errors.confirmPassword?.message}
                                                    {...field}
                                                />
                                            )}
                                        />

                                        <Controller
                                            name="company"
                                            control={control}
                                            rules={{ required: 'company' }}
                                            render={({ field }) => (
                                                <Input
                                                    label="Company"
                                                    error={errors.company?.message}
                                                    {...field}
                                                />
                                            )}
                                        />

                                        <Controller
                                            name="role"
                                            control={control}
                                            rules={{ required: 'Role is required' }}
                                            render={({ field }) => (
                                                <Select
                                                    label="Role"
                                                    options={roles}
                                                    error={errors.role?.message}
                                                    {...field}
                                                />
                                            )}
                                        />

                                        <Controller
                                            name="department"
                                            control={control}
                                            rules={{ required: 'At least one department is required' }}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <Autocomplete
                                                    multiple
                                                    options={departments}
                                                    value={value}
                                                    onChange={(event, newValue) => {
                                                        onChange(newValue);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Department"
                                                            error={!!error}
                                                            helperText={error?.message}
                                                        />
                                                    )}
                                                />
                                            )}
                                        />
                                        <Controller
                                            name="project"
                                            control={control}
                                            rules={{ required: 'At least one project is required' }}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <Autocomplete
                                                    multiple
                                                    options={projects}
                                                    value={value}
                                                    onChange={(event, newValue) => {
                                                        onChange(newValue);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Project"
                                                            error={!!error}
                                                            helperText={error?.message}
                                                        />
                                                    )}
                                                />
                                            )}
                                        />
                                    </div>
                                    <button
                                        type="submit"
                                        className="w-full text-white mt-4 py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                                        style={{ background: "linear-gradient(135deg, rgba(30, 144, 255, 0.8) 0%, rgba(0, 0, 128, 0.8) 100%)" }}
                                    >
                                        Register
                                    </button>
                                </>
                        }

                    </form>

                </div>
            </div>
        </>
    );
};