import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { selectAllEmployees, selectAllTeams } from '../../store/store/mainSlice';
import { shallowEqual, useSelector } from 'react-redux';
import { addEmployeeToTeam } from '../../api/endpoints/APIEndpoints';
import { selectUserData } from '../../store/store/authSlice';

interface AddMemberModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface Team {
  team_id: number;
  team_name: string;
}

interface Employee {
  employee_id: number;
  first_name: string;
  last_name: string;
}

const AddMemberModal: React.FC<AddMemberModalProps> = ({ isOpen, onClose }) => {
  const [teams, setTeams] = useState<Team[]>([]);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const allEmployees = useSelector(selectAllEmployees,shallowEqual);
  const allTeams = useSelector(selectAllTeams,shallowEqual);
  const userDetails:any = useSelector(selectUserData,shallowEqual);

  useEffect(() => {
    setTeams(allTeams);
    setEmployees(allEmployees);
  }, [allEmployees, allTeams]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log('Submitted:', { selectedTeam, selectedEmployee });
    addEmployeeToTeam.post({
        employeeId: selectedEmployee,
        teamId: selectedTeam,
        requestor_id: userDetails?.employee_id,
        approver_id: userDetails?.supervisor_id || userDetails?.employee_id
      }).then((res) => console.log("employee added to team", res))
        .catch((error) => console.log("ERROR!!", error));
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 max-w-md w-full text-left">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">Add Member to Team</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="team" className="block text-sm font-medium text-gray-700 mb-2">
              Select Team
            </label>
            <select
              id="team"
              value={selectedTeam}
              onChange={(e) => setSelectedTeam(e.target.value)}
              className="w-full p-2 border rounded-lg"
              required
            >
              <option value="">Select a team</option>
              {teams.map((team) => (
                <option key={team.team_id} value={team.team_id}>
                  {team.team_name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-6">
            <label htmlFor="employee" className="block text-sm font-medium text-gray-700 mb-2">
              Select Employee
            </label>
            <select
              id="employee"
              value={selectedEmployee}
              onChange={(e) => setSelectedEmployee(e.target.value)}
              className="w-full p-2 border rounded-lg"
              required
            >
              <option value="">Select an employee</option>
              {employees.map((employee) => (
                <option key={employee.employee_id} value={employee.employee_id}>
                  {employee.first_name} {employee.last_name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              Add Member
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddMemberModal;
